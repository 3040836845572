import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squds.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {ModalType, SortOrder, StatsCentreSort, StatsCentreView} from "data/enums";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {
	IStatsCentreStore,
	ITableHeadAndValue,
} from "data/stores/stats_centre/stats_centre.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import React from "react";
import type {
	IPlayerWithStats,
	IStatsPlayersStore,
} from "data/stores/stats_players/stats_players.store";

const SHOW_PLAYERS = 15;

export interface IStatsCentreController extends ViewController {
	readonly i18n: ILocalizationStore;
	get players(): IPlayerWithStats[];
	get isShowLoadMore(): boolean;
	get sort(): StatsCentreSort | string;
	get sortDirection(): SortOrder;
	get currentRound(): number;
	get viewType(): StatsCentreView;
	get statsColumns(): ITableHeadAndValue[];
	getPlayerSquad: (player: IPlayerWithStats) => ISquad | undefined;
	getPlayerPrice: (player: IPlayerWithStats) => string;
	getNextFixture: (player: IPlayerWithStats) => string;
	onClickLoadMore: () => void;
	getSortClasses: (sort: StatsCentreSort | string, sortOrder: SortOrder) => string;
	updateSort: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	openPlayerProfile: (player: IPlayer) => void;
}

@injectable()
export class StatsCentreController implements IStatsCentreController {
	@observable _limit: number = SHOW_PLAYERS;

	get players(): IPlayerWithStats[] {
		return this._statsCentreStore.filteredPlayers.slice(0, this._limit);
	}

	get isShowLoadMore(): boolean {
		return this._limit < this._statsCentreStore.filteredPlayers.length;
	}

	get sort() {
		return this.viewType === StatsCentreView.Stats
			? this._statsCentreStore.filters.sort
			: this._statsCentreStore.filters.matchSort;
	}

	get sortDirection() {
		return this._statsCentreStore.filters.sortDirection;
	}

	get currentRound() {
		return this._roundsStore.currentRound?.id ?? 0;
	}

	get viewType() {
		return this._statsCentreStore.viewType;
	}

	get statsColumns() {
		return this._statsCentreStore.statsColumns;
	}
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.StatsCentreStore) private _statsCentreStore: IStatsCentreStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.StatsPlayersStore) private _statsPlayersStore: IStatsPlayersStore
	) {
		makeAutoObservable(this);
	}

	init() {
		void Promise.all([
			this._squadsStore.fetchSquads(),
			this._statsPlayersStore.fetchPlayersStats(),
		]).catch(this.onError);
	}

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	getPlayerSquad = (player: IPlayerWithStats) => {
		return this._squadsStore.getSquadById(player.squadId);
	};

	getPlayerPrice = (player: IPlayerWithStats) => {
		return this._playersStore.getPlayerDisplayPrice(player.cost);
	};

	getNextFixture = (player: IPlayerWithStats) => {
		const nextTournament = this._roundsStore.getAllScheduledTournaments.find(
			(tournament) => tournament.id === player?.stats?.nextFixture
		);
		if (nextTournament) {
			const playerSquad =
				nextTournament.homeSquadId !== player?.squadId
					? nextTournament.homeSquadId
					: nextTournament.awaySquadId;
			const squad = this._squadsStore.getSquadById(playerSquad);
			return squad ? `v ${squad.abbreviation}` : "-";
		}
		return "-";
	};

	getSortClasses = (sort: StatsCentreSort | string, sortOrder: SortOrder) => {
		const classes: string[] = [sortOrder];
		if (sort === this.sort) {
			classes.push("show");
		}
		if (sortOrder === this.sortDirection) {
			classes.push("active");
		}
		return classes.join(" ");
	};

	@action
	updateSort = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const {sort} = e.currentTarget.dataset;
		this._statsCentreStore.updateSort(sort as StatsCentreSort);
	};

	@action
	onClickLoadMore = () => {
		this._limit = this._limit + SHOW_PLAYERS;
	};

	@action
	openPlayerProfile = (player: IPlayer) => {
		if (!player) {
			return;
		}
		this._modalsStore.showModal(ModalType.PLAYER_PROFILE, {player});
	};
}
