import {ViewController} from "data/types/structure";
import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {getTimeRemaining} from "data/utils/helpers/date";

export interface ILockoutTimerController extends ViewController {
	readonly i18n: ILocalizationStore;
	get teamStartRound(): IRound | undefined;
	get selectedRound(): IRound | undefined;
	get timeRemaining(): ReturnType<typeof getTimeRemaining>;
}

@injectable()
export class LockoutTimerController implements ILockoutTimerController {
	@observable private _interval?: ReturnType<typeof setInterval>;
	@observable private _timeRemaining: ReturnType<typeof getTimeRemaining> = null;

	get teamStartRound() {
		const startTeamRound = this._teamStore.team.startRoundId;
		return this._roundsStore.getRoundById(startTeamRound);
	}

	get selectedRound() {
		return (
			this._roundsStore.getRoundById(this._teamBuilderStore.selectedRoundId) ||
			this._roundsStore.currentRound
		);
	}

	get timeRemaining() {
		return this._timeRemaining;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	init(): void {
		this._interval = setInterval(() => {
			if (this.selectedRound) {
				this._timeRemaining = getTimeRemaining(this.selectedRound.startDate);
			}
		}, 1000);
	}

	dispose(): void {
		if (this._interval) {
			clearInterval(this._interval);
		}
	}
}
