import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILeague, ILeaguesStore, ILeagueUsers} from "data/stores/leagues/leagues.store";
import type {ChangeEvent, ReactNode} from "react";
import type {SelectChangeEvent} from "@mui/material";
import type {IUpdateLeaguePayload} from "data/providers/api/leagues.api.provider";
import type {IUserStore} from "data/stores/user/user.store";
import type {IApiResponse} from "data/services/http";
import type {AxiosError} from "axios";
import {identity, isEqual, values} from "lodash";
import {LeaguePrivacy, LeagueStatus, RequestState, RoundStatus} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {useNavigate} from "react-router-dom";

interface IProps {
	leagueId: number;
	navigate: ReturnType<typeof useNavigate>;
}

interface IForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	startId: HTMLInputElement;
	privacy: HTMLInputElement;
}

interface IFormValue {
	leagueName: string;
	startId: number;
	privacy: LeaguePrivacy;
}

export interface ILeagueSettingsController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get userID(): number;
	get league(): ILeague | null;
	get rounds(): IRound[];
	get isLoading(): boolean;
	get isLeaguePresenceRequestLoading(): boolean;
	get isLeagueUsersRequestLoading(): boolean;
	get isUpdateButtonDisabled(): boolean;
	get isLeagueNameFieldDisabled(): boolean;
	get isLeagueStarted(): boolean;
	get isFormDisabled(): boolean;
	get formValue(): IFormValue;
	get leagueUsers(): ILeagueUsers | null;
	get isUserRemoveModalShow(): boolean;

	handleUpdateLeague: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;
	startRoundChange: (event: SelectChangeEvent<unknown>, _: ReactNode) => void;
	leaveLeague: () => void;
	joinLeague: () => void;
	loadMoreUsers: () => void;
	removeUser: () => void;
	openRemoveUserModal: (userId: number) => void;
	canselRemoveUserModal: () => void;
}

@injectable()
export class LeagueSettingsController implements ILeagueSettingsController {
	@observable private _leagueId?: number;
	@observable private _leagueDisposer?: ReturnType<typeof reaction>;
	@observable private _userIdForRemove?: number;
	private _navigate!: IProps["navigate"];

	@observable private _requestsStates = {
		general: RequestState.PENDING,
		leaguePresence: RequestState.IDLE,
		leagueUsers: RequestState.IDLE,
		removeUser: RequestState.IDLE,
	};

	@observable private _formValue: IFormValue = {
		leagueName: "",
		startId: 0,
		privacy: LeaguePrivacy.PRIVATE,
	};

	private get leagueID() {
		return this._leagueId!;
	}

	get userID() {
		return this._userStore.user!.id;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get leagueUsers() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueUsersByLeagueId(this._leagueId);
	}

	get isUserRemoveModalShow() {
		return Boolean(this._userIdForRemove);
	}

	@computed get rounds() {
		return this._roundsStore.list.filter((round) => {
			const isScheduled = isEqual(round.status, RoundStatus.Scheduled);
			const isLeagueStartRound = isEqual(this.league?.startId, round.id);

			return isScheduled || isLeagueStartRound;
		});
	}

	get formValue() {
		return this._formValue;
	}

	get isLoading() {
		return isEqual(this._requestsStates.general, RequestState.PENDING);
	}

	get isLeaguePresenceRequestLoading() {
		return isEqual(this._requestsStates.leaguePresence, RequestState.PENDING);
	}

	get isLeagueUsersRequestLoading() {
		return isEqual(this._requestsStates.leagueUsers, RequestState.PENDING);
	}

	get isFormDisabled() {
		return this.isLoading || this.isLeagueStarted;
	}

	get isLeagueNameFieldDisabled() {
		return this.isLoading;
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	get isUpdateButtonDisabled() {
		if (this.isLeagueStarted) return !this.isFormValid;
		return this.isFormDisabled || !this.isFormValid;
	}

	@computed private get isFormValid() {
		return values(this._formValue).every(identity);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action private createSuccessHandlerOf = (type: keyof typeof this._requestsStates) => () => {
		this._requestsStates[type] = RequestState.SUCCESS;
	};

	@action private createErrorHandlerOf =
		(type: keyof typeof this._requestsStates) => (error: AxiosError<IApiResponse>) => {
			this._requestsStates[type] = RequestState.ERROR;
			console.error(extractErrorMessage(error));
		};

	@action private setPendingState = (type: keyof typeof this._requestsStates) => {
		this._requestsStates[type] = RequestState.PENDING;
	};

	handleUpdateLeague = () => {
		this.setPendingState("general");

		const {leagueName, startId, privacy} = this._formValue;

		const payload: IUpdateLeaguePayload = {
			name: leagueName,
			leagueId: this.leagueID,
		};

		if (!this.isLeagueStarted) {
			payload.privacy = privacy;
			payload.startId = startId;
		}

		this._leaguesStore
			.updateLeague(payload)
			.then(this.createSuccessHandlerOf("general"))
			.catch(this.createErrorHandlerOf("general"));
	};

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		const {leagueName, privacy} = event.currentTarget;

		this._formValue.leagueName = leagueName.value;
		this._formValue.privacy = privacy.value as LeaguePrivacy;
	};

	@action startRoundChange = (event: SelectChangeEvent<unknown>, _: ReactNode) => {
		this._formValue.startId = Number(event.target.value);
	};

	@action leaveLeague = () => {
		this.setPendingState("leaguePresence");

		this._leaguesStore
			.leaveLeague({leagueId: this.leagueID})
			.then(() => this._leaguesStore.fetchLeagueUsers({leagueId: this.leagueID}))
			.then(this.createSuccessHandlerOf("leaguePresence"))
			.catch(this.createErrorHandlerOf("leaguePresence"))
			.finally(() => {
				this._navigate(`/leagues`);
			});
	};

	@action joinLeague = () => {
		const code = this.league?.code;

		if (!code) return;

		this.setPendingState("leaguePresence");

		this._leaguesStore
			.joinToLeague({code})
			.then(() => this._leaguesStore.fetchLeagueUsers({leagueId: this.leagueID}))
			.then(this.createSuccessHandlerOf("leaguePresence"))
			.catch(this.createErrorHandlerOf("leaguePresence"));
	};

	@action loadMoreUsers = () => {
		this.setPendingState("leagueUsers");

		this._leaguesStore
			.fetchMoreLeagueUsers({leagueId: this.leagueID})
			.then(this.createSuccessHandlerOf("leagueUsers"))
			.catch(this.createErrorHandlerOf("leagueUsers"));
	};

	@action removeUser = () => {
		if (this._userIdForRemove) {
			this.setPendingState("removeUser");

			this._leaguesStore
				.removeUserFromLeague({userId: this._userIdForRemove, leagueId: this.leagueID})
				.then(this.createSuccessHandlerOf("removeUser"))
				.catch(this.createErrorHandlerOf("removeUser"))
				.finally(this.canselRemoveUserModal);
		}
	};

	@action openRemoveUserModal = (userId: number) => {
		this._userIdForRemove = userId;
	};

	@action canselRemoveUserModal = () => {
		this._userIdForRemove = undefined;
	};

	@action init({leagueId, navigate}: IProps) {
		this._navigate = navigate;
		this._leagueId = leagueId;

		Promise.all([
			this._roundsStore.fetchRounds(),
			this._leaguesStore.fetchLeagueUsers({leagueId}),
		])
			.then(this.createSuccessHandlerOf("general"))
			.catch(this.createErrorHandlerOf("general"));

		this._leagueDisposer = reaction(
			() => this.league,
			() => {
				if (!this.league) return;

				const {startId, name, privacy} = this.league;

				this._formValue.startId = startId;
				this._formValue.leagueName = name;
				this._formValue.privacy = privacy;
			},
			{fireImmediately: true}
		);
	}

	dispose() {
		this._leagueDisposer?.();
	}
}
