import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type IWebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";

interface IController {
	searchParams: URLSearchParams;
}

export interface IWebViewGateController extends ViewController<IController> {
	get checked(): boolean;
}

@injectable()
export class WebViewGateController implements IWebViewGateController {
	@observable private _checked = false;
	constructor(@inject(Bindings.WebViewGateStore) private _webViewGateStore: IWebViewGateStore) {
		makeAutoObservable(this);
	}

	get checked() {
		return this._checked;
	}

	init(params: IController) {
		const webview = params.searchParams.get("webview") as string;

		if (webview === "true") {
			document.body.classList.add("webview");
			this._webViewGateStore.setIsFromApp(true);
		}
		this._checked = true;
	}
}
