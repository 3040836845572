import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {ChangeEvent} from "react";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {ModalType} from "data/enums";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IForm extends HTMLFormElement {
	email: HTMLInputElement;
}

export interface IBackdoorController extends ViewController {
	get emailField(): string | null;

	handleChangeBackdoorForm: (event: ChangeEvent<IForm>) => void;
	handleSendBackdoorForm: () => void;
}

@injectable()
export class BackdoorController implements IBackdoorController {
	@observable private _email: string | null = null;

	get emailField() {
		return this._email;
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action handleChangeBackdoorForm = (event: ChangeEvent<IForm>) => {
		const {email} = event.currentTarget;

		this._email = email.value;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(error),
		});
	};

	@action handleSendBackdoorForm = () => {
		if (this._email) {
			this._userStore
				.loginBackdoor({
					email: this._email,
				})
				.catch(this.onError);
		}
	};
}
