import Keycloak from "keycloak-js";
import {SSO_CLIENT_ID, SSO_CLIENT_APP_ID, SSO_CLIENT_REALM, SSO_URL} from "data/constants";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const isWebView = Boolean(urlParams.get("webview"));

export const keycloak = new Keycloak({
	url: SSO_URL,
	realm: SSO_CLIENT_REALM,
	clientId: isWebView ? SSO_CLIENT_APP_ID : SSO_CLIENT_ID,
});
