import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {AxiosResponse} from "axios";
import type {ILeague} from "data/stores/leagues/leagues.store";
import {RoundStatus} from "data/enums";

export interface IDashboard {
	roundId: number;
	roundNumber: number;
	roundStatus: RoundStatus;
	team: {
		avgPoints: number | null;
		roundPoints: number | null;
		totalPoints: number | null;
		overallRank: number | null;
	};
	leagues: ILeague[];
}

type IDashboardResponse = IApiResponse<IDashboard>;

export interface IDashboardProvider {
	fetchDashboard: () => Promise<AxiosResponse<IDashboardResponse>>;
}

@injectable()
export class DashboardProvider implements IDashboardProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}
	fetchDashboard = () => this._http.get<IDashboardResponse>("fantasy/dashboard");
}
