import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {BoosterTypes, ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {IPlayersStore} from "data/stores/players/players.store";

export interface IModalBoostersController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	get isLoading(): boolean;
	get isBoosterSelected(): boolean;

	close: () => void;
	onClickBoosterButton: (type: BoosterTypes) => void;
	checkIsBoosterSelected: (type: BoosterTypes) => boolean;
	checkIsBoosterUsed: (type: BoosterTypes) => boolean;
	checkIsBoosterLocked: (type: BoosterTypes) => boolean;
}

@injectable()
export class ModalBoostersController implements IModalBoostersController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.BOOSTERS;
	}

	get isLoading(): boolean {
		return this._teamStore.isLoading;
	}

	get isBoosterSelected(): boolean {
		const actualRoundId = this._roundsStore.currentRound?.id;
		return Boolean(
			this._teamStore.team.boosters.find((booster) => booster.roundId === actualRoundId)
		);
	}

	dispose(): void {
		return;
	}

	public close = () => {
		this._modalsStore.hideModal();
	};

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._teamBuilderStore.boosterForChoice = null;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	public onClickBoosterButton = (type: BoosterTypes) => {
		const booster = this._teamStore.team.boosters.find((booster) => booster.type === type);

		if (booster) {
			this.removeBooster(booster.id);
		} else {
			void this.setBooster(type);
		}
	};

	private setBooster = async (type: BoosterTypes) => {
		if (type === BoosterTypes.TRIPLE_CAPTAIN) {
			await this._teamStore
				.setBooster({
					type,
					playerId: this._teamStore.team.captainId,
				})
				.then(() => {
					this._modalsStore.showModal(ModalType.BOOSTERS_CONFIRM);
				})
				.catch(this.onError);

			this._teamBuilderStore.boosterForChoice = BoosterTypes.TRIPLE_CAPTAIN;
			this._teamBuilderStore.selectedPlayerForBoost = this._teamStore.team.captainId;
		} else {
			this._teamBuilderStore.boosterForChoice = type;
			this.close();
		}
	};

	private removeBooster = (boosterId: number) => {
		this._teamStore
			.removeBooster({
				boosterId,
			})
			.catch(this.onError);
	};

	public checkIsBoosterSelected = (type: BoosterTypes) => {
		const activeRound = this._roundsStore.currentRound;

		return Boolean(
			this._teamStore.team.boosters.find(
				(booster) => booster.type === type && activeRound?.id === booster.roundId
			)
		);
	};

	public checkIsBoosterUsed = (type: BoosterTypes) => {
		const activeRound = this._roundsStore.currentRound;
		return Boolean(
			this._teamStore.team.boosters.find(
				(booster) =>
					booster.type === type &&
					activeRound?.id !== booster.roundId &&
					activeRound?.roundStage ===
						this._roundsStore.getRoundStageByRoundNumber(booster.roundNumber)
			)
		);
	};

	public checkIsBoosterLocked = (type: BoosterTypes) => {
		const captain = this._playersStore.getPlayerById(this._teamStore.team.captainId);

		if (type === BoosterTypes.TRIPLE_CAPTAIN && Boolean(captain?.isLocked)) {
			return true;
		}

		const activeRound = this._roundsStore.currentRound;
		const booster = this._teamStore.team.boosters.find(
			(booster) =>
				booster.type === type &&
				activeRound?.id === booster.roundId &&
				activeRound.roundStage ===
					this._roundsStore.getRoundStageByRoundNumber(booster.roundNumber)
		);

		if (!booster) {
			return false;
		}

		const player = this._playersStore.getPlayerById(booster.playerId);

		return Boolean(player?.isLocked);
	};
}
