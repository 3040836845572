import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {RoundsStore, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squds.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secrete_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type IMenuItemsController,
	MenuItemsController,
} from "views/components/menu_items/menu_items.controller";
import {
	type IPlayersPoolController,
	PlayersPoolController,
} from "views/components/players_pool/players_pool.controller";
import {type ITeamController, TeamController} from "views/pages/team/team.controller";
import {
	type ITeamBuilderStore,
	TeamBuilderStore,
} from "data/stores/team_builder/team_builder.store";
import {type ITeamStore, TeamStore} from "data/stores/team/team.store";
import {type ITeamApiProvider, TeamApiProvider} from "data/providers/api/team.api.provider";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalRegisterController,
	ModalRegisterController,
} from "views/components/modals/modal_register/modal_register.controller";
import {
	type IModalSuccessController,
	ModalSuccessController,
} from "views/components/modals/modal_success/modal_success.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	type IJoinLeaguesController,
	JoinLeaguesController,
} from "views/pages/join_leagues/join_leagues.controller";
import {
	type ICreateLeagueController,
	CreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/pages/league_invite/league_invite.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {
	type IBackdoorController,
	BackdoorController,
} from "views/pages/backdoor/backdoor.controller";

import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";

import {
	type IPlayerCardController,
	PlayerCardController,
} from "views/components/players_pool/player_pool_card/player_pool_card.controller";
import {type ILineupController, LineupController} from "views/components/lineup/lineup.controller";
import {
	type ILineupPlayerController,
	LineupPlayerController,
} from "views/components/lineup_player/lineup_player.controller";
import {type ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {
	type IGameBarController,
	GameBarController,
} from "views/components/game_bar/game_bar.controller";
import {
	type ILockoutTimerController,
	LockoutTimerController,
} from "views/components/lockout_timer/lockout_timer.controller";
import {
	type IModalSaveTeamController,
	ModalSaveTeamController,
} from "views/components/modals/modal_save_team/modal_save_team.controller";
import {
	type IModalAuthorizationController,
	ModalAuthorizationController,
} from "views/components/modals/modal_authorization/modal_authorization.controller";
import {type IDashboardStore, DashboardStore} from "data/stores/dashboard/dashboard.store";
import {type IDashboardProvider, DashboardProvider} from "data/providers/api/dashboard.provider";
import {
	type IMobileLandingController,
	MobileLandingController,
} from "views/pages/landing/mobileLanding/mobileLanding.controller";
import {
	type IModalPlayerController,
	ModalPlayerController,
} from "views/components/modals/modal_player/modal_player.controller";
import {
	IModalTutorialController,
	ModalTutorialController,
} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {
	IModalTutorialPositionController,
	ModalTutorialPositionController,
} from "views/components/modals/modal_tutorial_position/modal_tutorial_position.controller";
import {ILeaderboardStore, LeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import {
	ILeaderboardApiProvider,
	LeaderboardApiProvider,
} from "data/providers/api/leaderboard.api.provider";
import {
	ILeaderboardController,
	LeaderboardController,
} from "views/pages/leaderboard/leaderboard.controller";
import {
	IModalReviewTeamController,
	ModalReviewTeamController,
} from "views/components/modals/modal_review_team/modal_review_team.controller";

import {
	type IModalBoostersController,
	ModalBoostersController,
} from "views/components/modals/modal_boosters/modal_boosters.controller";
import {
	type IBoostersControlPanelController,
	BoostersControlPanelController,
} from "views/components/boosters_control_panel/boosters_control_panel.controller";
import {
	type IModalBoosterConfirmedController,
	ModalBoosterConfirmedController,
} from "views/components/modals/modal_booster_confirmed/modal_booster_confirmed.controller";
import {
	type ILanguageSelectorController,
	LanguageSelectorController,
} from "views/components/language_selector/language_selector.controller";
import {
	type ITradesControlPanelController,
	TradesControlPanelController,
} from "views/components/trades_control_panel/trades_control_panel.controller";
import {
	DesktopLandingController,
	type IDesktopLandingController,
} from "views/pages/landing/desktopLanding/desktopLanding.controller";
import {
	type ITransfersApiProvider,
	TransfersApiProvider,
} from "data/providers/api/transfers.api.provider";
import {type ITransfersStore, TransfersStore} from "data/stores/transfers/transfers.store";
import {
	IUserTableController,
	UserTableController,
} from "views/components/rankings/user_table/user_table.controller";
import {
	IModalTransfersController,
	ModalTransfersController,
} from "views/components/modals/modal_transfers/modal_transfers.controller";
import {
	type ILeagueTableController,
	LeagueTableController,
} from "views/pages/league_table/league_table.controller";
import {
	IModalApplyNotSuitableBoosterToCaptainController,
	ModalApplyNotSuitableBoosterToCaptainController,
} from "views/components/modals/modal_apply_not_suitable_booster_to_captain/modal_apply_not_suitable_booster_to_captain.controller";
import {AccountController, type IAccountController} from "views/pages/account/account.controller";
import {
	IModalClearTeamConfirmationController,
	ModalClearTeamConfirmationController,
} from "views/components/modals/modal_clear_team_confirmation/modal_clear_team_confirmation.controller";
import {
	IWebViewGateController,
	WebViewGateController,
} from "views/components/web_view_gate/web_view_gate.controller";
import {IWebViewGateStore, WebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";
import {HeaderController, IHeaderController} from "views/components/header/header.controller";
import {
	type ILeaguesCTAController,
	LeaguesCTAController,
} from "views/components/leaguesCTA/leagues_cta.controller";
import {
	FieldImageController,
	type IFieldImageController,
} from "views/components/field_image/field_image.controller";
import {
	type IModalTransferBoosterController,
	ModalTransferBoosterController,
} from "views/components/modals/modal_transfer_booster/modal_transfer_booster.controller";
import {
	type IStatsPlayersStore,
	StatsPlayersStore,
} from "data/stores/stats_players/stats_players.store";
import {
	type IRankingsItemController,
	RankingsItemController,
} from "views/components/rankings_item/rankings_item.controller";
import {
	type IPointsMessageController,
	PointsMessageController,
} from "views/components/points_message/points_message.controller";
import {
	type IStatsCentreController,
	StatsCentreController,
} from "views/pages/stats_centre/stats_centre.controller";
import {
	type IStatsCentreStore,
	StatsCentreStore,
} from "data/stores/stats_centre/stats_centre.store";
import {
	type IStatsCentreFiltersController,
	StatsCentreFiltersController,
} from "views/components/stats_centre_filters/stats_centre_filters.controller";
import {type IGameBarStore, GameBarStore} from "data/stores/game_bar/game_bar.store";
import {type IGameBarProvider, GameBarProvider} from "data/providers/api/game_bar.provider";
import {
	type IModalModalConfirmedController,
	ModalModalConfirmedController,
} from "views/components/modals/modal_confirmed/modal_confirmed.controller";
import {
	type IRemovePlayersController,
	RemovePlayersController,
} from "views/components/remove_players/remove_players.controller";
import {
	type IModalPoolsCompleteController,
	ModalPoolsCompleteController,
} from "views/components/modals/modal_pools_complete/modal_pools_complete.controller";
import {type IMatchesController, MatchesController} from "views/pages/matches/matches.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ITeamApiProvider>(Bindings.TeamApiProvider).to(TeamApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<IDashboardProvider>(Bindings.DashboardProvider).to(DashboardProvider);
	bind<ILeaderboardApiProvider>(Bindings.LeaderboardApiProvider).to(LeaderboardApiProvider);
	bind<ITransfersApiProvider>(Bindings.TransfersApiProvider).to(TransfersApiProvider);
	bind<IGameBarProvider>(Bindings.GameBarProvider).to(GameBarProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<IWebViewGateStore>(Bindings.WebViewGateStore).to(WebViewGateStore).inSingletonScope();
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ITeamBuilderStore>(Bindings.TeamBuilderStore).to(TeamBuilderStore).inSingletonScope();
	bind<ITeamStore>(Bindings.TeamStore).to(TeamStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();

	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IDashboardStore>(Bindings.DashboardStore).to(DashboardStore).inSingletonScope();
	bind<ILeaderboardStore>(Bindings.LeaderboardStore).to(LeaderboardStore).inSingletonScope();
	bind<ITransfersStore>(Bindings.TransfersStore).to(TransfersStore).inSingletonScope();
	bind<IStatsPlayersStore>(Bindings.StatsPlayersStore).to(StatsPlayersStore).inSingletonScope();
	bind<IStatsCentreStore>(Bindings.StatsCentreStore).to(StatsCentreStore).inSingletonScope();
	bind<IGameBarStore>(Bindings.GameBarStore).to(GameBarStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMenuItemsController>(Bindings.MenuItemsController).to(MenuItemsController);
	bind<IPlayersPoolController>(Bindings.PlayersPoolController).to(PlayersPoolController);
	bind<ITeamController>(Bindings.TeamController).to(TeamController);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalRegisterController>(Bindings.ModalRegisterController).to(ModalRegisterController);
	bind<IModalSuccessController>(Bindings.ModalSuccessController).to(ModalSuccessController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IBackdoorController>(Bindings.BackdoorController).to(BackdoorController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<IPlayerCardController>(Bindings.PlayerCardController).to(PlayerCardController);
	bind<ILineupController>(Bindings.LineupController).to(LineupController);
	bind<ILineupPlayerController>(Bindings.LineupPlayerController).to(LineupPlayerController);
	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<IGameBarController>(Bindings.GameBarController).to(GameBarController);
	bind<ILockoutTimerController>(Bindings.LockoutTimerController).to(LockoutTimerController);
	bind<IModalSaveTeamController>(Bindings.ModalSaveTeamController).to(ModalSaveTeamController);
	bind<IModalAuthorizationController>(Bindings.ModalAuthorizationController).to(
		ModalAuthorizationController
	);
	bind<IMobileLandingController>(Bindings.MobileLandingController).to(MobileLandingController);
	bind<IModalPlayerController>(Bindings.ModalPlayerController).to(ModalPlayerController);
	bind<IModalTutorialController>(Bindings.ModalTutorialController).to(ModalTutorialController);
	bind<IModalTutorialPositionController>(Bindings.ModalTutorialPositionController).to(
		ModalTutorialPositionController
	);
	bind<ILeaderboardController>(Bindings.LeaderboardController).to(LeaderboardController);
	bind<IModalBoostersController>(Bindings.ModalBoostersController).to(ModalBoostersController);
	bind<IModalReviewTeamController>(Bindings.ModalReviewTeamController).to(
		ModalReviewTeamController
	);
	bind<IBoostersControlPanelController>(Bindings.BoostersControlPanelController).to(
		BoostersControlPanelController
	);
	bind<IModalBoosterConfirmedController>(Bindings.ModalBoosterConfirmedController).to(
		ModalBoosterConfirmedController
	);
	bind<ILanguageSelectorController>(Bindings.LanguageSelectorController).to(
		LanguageSelectorController
	);
	bind<ITradesControlPanelController>(Bindings.TradesControlPanelController).to(
		TradesControlPanelController
	);
	bind<IDesktopLandingController>(Bindings.DesktopLandingController).to(DesktopLandingController);
	bind<IUserTableController>(Bindings.UserTableController).to(UserTableController);
	bind<IModalTransfersController>(Bindings.ModalTransfersController).to(ModalTransfersController);
	bind<ILeagueTableController>(Bindings.LeagueTableController).to(LeagueTableController);
	bind<IModalApplyNotSuitableBoosterToCaptainController>(
		Bindings.ModalApplyNotSuitableBoosterToCaptainController
	).to(ModalApplyNotSuitableBoosterToCaptainController);
	bind<IAccountController>(Bindings.AccountController).to(AccountController);
	bind<IModalClearTeamConfirmationController>(Bindings.ModalClearTeamConfirmationController).to(
		ModalClearTeamConfirmationController
	);
	bind<IWebViewGateController>(Bindings.WebViewGateController).to(WebViewGateController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<ILeaguesCTAController>(Bindings.LeaguesCTAController).to(LeaguesCTAController);
	bind<IFieldImageController>(Bindings.FieldImageController).to(FieldImageController);
	bind<IModalTransferBoosterController>(Bindings.ModalTransferBoosterController).to(
		ModalTransferBoosterController
	);
	bind<IRankingsItemController>(Bindings.RankingsItemController).to(RankingsItemController);
	bind<IPointsMessageController>(Bindings.PointsMessageController).to(PointsMessageController);
	bind<IStatsCentreController>(Bindings.StatsCentreController).to(StatsCentreController);
	bind<IStatsCentreFiltersController>(Bindings.StatsCentreFiltersController).to(
		StatsCentreFiltersController
	);
	bind<IModalModalConfirmedController>(Bindings.ModalModalConfirmedController).to(
		ModalModalConfirmedController
	);
	bind<IRemovePlayersController>(Bindings.RemovePlayersController).to(RemovePlayersController);
	bind<IModalPoolsCompleteController>(Bindings.ModalPoolsCompleteController).to(
		ModalPoolsCompleteController
	);
	bind<IMatchesController>(Bindings.MatchesController).to(MatchesController);
});
