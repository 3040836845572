import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IGameBarProvider, IGameBar} from "data/providers/api/game_bar.provider";

export interface IGameBarStore {
	get gameBar(): IGameBar | undefined;

	fetchGameBar(roundId?: number): Promise<void>;
}

@injectable()
export class GameBarStore implements IGameBarStore {
	@observable private _gameBar: IGameBar | undefined;

	get gameBar() {
		return this._gameBar;
	}

	constructor(@inject(Bindings.GameBarProvider) private _gameBarProvider: IGameBarProvider) {
		makeAutoObservable(this);
	}

	@action async fetchGameBar(roundId: number) {
		const {data} = await this._gameBarProvider.fetchGameBar({
			roundId,
		});
		this._gameBar = data.success;
	}
}
