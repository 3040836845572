export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum TournamentStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league_invite",
}

export enum Locale {
	EN_US = "en-US",
	EN = "en",
	FR = "fr",
}

export enum Language {
	EN = "en",
	FR = "fr",
}

export enum ModalType {
	ERROR,
	SUCCESS,
	CONFIRM,
	PLAYER_PROFILE,
	BOOSTERS,
	REGISTRATION,
	USER_REMOVE_FROM_LEAGUE,
	SAVING_YOUR_TEAM,
	AUTHORIZATION,
	TUTORIAL,
	TUTORIAL_POSITION,
	REVIEW_TEAM,
	BOOSTERS_CONFIRM,
	APPLY_NOT_SUITABLE_BOOSTER_TO_CAPTAIN,
	TRANSFERS,
	CLEAR_TEAM_CONFIRMATION,
	TRANSFER_BOOSTER,
	POOLS_COMPLETE,
}

export enum PlayerPosition {
	Prop = "prop",
	Hooker = "hooker",
	Lock = "lock",
	LooseForward = "loose_forward",
	FlyHalf = "fly_half",
	ScrumHalf = "scrum_half",
	Center = "center",
	OutsideBack = "outside_back",
}

export enum PlayerStatus {
	Uncertain = "uncertain",
	Starting = "starting",
	Bench = "bench",
	NotSelected = "not-selected",
	Injured = "injured",
	Eliminated = "eliminated",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
	COUNTRY = "country",
}

export enum LeagueStatus {
	COMPLETE = "complete",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum UserStats {
	Price = "Price",
	NextFixture = "Next Fixture",
	RoundPoints = "Round Points",
	TotalPoints = "Total Points",
	PercentSelected = "% Selected",
}

export enum UserStatuses {
	All = "All",
	Uncertain = "uncertain",
	Starting = "starting",
	Bench = "bench",
	NotSelected = "not-selected",
	Injured = "injured",
	Eliminated = "eliminated",
}

export enum LandingCardType {
	default,
}

export enum BoosterTypes {
	TRIPLE_CAPTAIN = "triple_captain",
	SUPER_KICKER = "super_kicker",
	DEFENSIVE_KING = "defensive_king",
}

export enum StatFilter {
	TotalPoints = "overallPoints",
	LastRoundPoints = "roundPoints",
	AveragePoints = "averagePoints",
}

export enum StatsCentreView {
	Stats = "stats",
	Overview = "value",
}

export enum StatsCentreSort {
	Name = "name",
	TotalPoints = "totalPoints",
	RoundPoints = "roundPoints",
	LastRoundPoints = "lastRoundPoints",
	Price = "cost",
	Selected = "selected",
	NextFixture = "nextFixture",
	AveragePoints = "avgPoints",
	DefaultOverview = "tries",
}

export enum RoundStage {
	Group = "group",
	Knockout = "knockout",
}
