import {action, computed, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPlayersStore} from "data/stores/players/players.store";
import {PlayerStatus, SortOrder, StatsCentreSort, StatsCentreView, UserStatuses} from "data/enums";
import {SelectChangeEvent} from "@mui/material";
import {parseSingleValueFromArray} from "data/utils/helpers";
import {ChangeEvent} from "react";
import {chain, first, forEach, get, sortBy} from "lodash";
import {SPECIAL_SYMBOLS} from "data/constants";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {
	IPlayerWithStats,
	IStatsPlayersStore,
} from "data/stores/stats_players/stats_players.store";

export interface IStatsCentreFilters {
	search: string;
	position: string[];
	squad: string;
	status: UserStatuses;
	sort: StatsCentreSort;
	matchSort: string;
	sortDirection: SortOrder;
}

export interface ITableHeadAndValue {
	key: string;
	stat: string;
}

const defaultFilters: IStatsCentreFilters = {
	search: "",
	position: ["null"],
	squad: "null",
	status: UserStatuses.All,
	sort: StatsCentreSort.TotalPoints,
	matchSort: "",
	sortDirection: SortOrder.DESC,
};

export interface IStatsCentreStore {
	get filters(): IStatsCentreFilters;

	get isFiltersVisible(): boolean;

	get isFiltersChanged(): boolean;

	get viewType(): StatsCentreView;

	set viewType(type: StatsCentreView);

	get filteredPlayers(): IPlayerWithStats[];

	get statsColumns(): ITableHeadAndValue[];

	updateFilter: (e: SelectChangeEvent | SelectChangeEvent<string[]>) => void;
	updateSearch: (e: ChangeEvent<HTMLInputElement>) => void;
	updateSort: (sort: StatsCentreSort | string) => void;
	resetFilters: () => void;
	resetSearch: () => void;
	toggleFilters: () => void;
	openFilters: () => void;
	closeFilters: () => void;
}

@injectable()
export class StatsCentreStore implements IStatsCentreStore {
	@observable private _isFiltersVisible = true;
	@observable private _filters: IStatsCentreFilters = {
		...defaultFilters,
	};
	@observable private _viewType = StatsCentreView.Stats;

	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.StatsPlayersStore) private _statsPlayersStore: IStatsPlayersStore
	) {
		makeAutoObservable(this);
	}

	get isFiltersVisible() {
		return this._isFiltersVisible;
	}

	get filters() {
		return this._filters;
	}

	get viewType() {
		return this._viewType;
	}

	set viewType(type: StatsCentreView) {
		this._viewType = type;
	}

	private _statsColumns = [
		{key: "scoring.metric.sixty_plus", stat: "gamesPlayed"},
		{key: "scoring.metric.tries", stat: "tries"},
		{key: "scoring.metric.assists", stat: "assists"},
		{key: "scoring.metric.conversions", stat: "conversions"},
		{key: "scoring.metric.penalties", stat: "penalties"},
		{key: "scoring.metric.drop_goal", stat: "dropGoals"},
		{key: "scoring.metric.tackles", stat: "tackles"},
		{key: "scoring.metric.linebreak", stat: "linebreaks"},
		{key: "scoring.metric.linebreak_assist", stat: "linebreakAssists"},
		{key: "scoring.metric.turnover_forced", stat: "turnovers"},
		{key: "scoring.metric.interception", stat: "interceptions"},
		{key: "scoring.metric.defenders_beaten", stat: "defendersBeaten"},
		{key: "scoring.metric.lineout_won", stat: "lineoutsWon"},
		{key: "scoring.metric.scrums_won", stat: "scrumsWon"},
		{key: "scoring.metric.carries", stat: "metresGained"},
		{key: "scoring.metric.penalty_conceded", stat: "penaltiesConceded"},
		{key: "scoring.metric.error", stat: "errors"},
		{key: "scoring.metric.yellow_card", stat: "yellowCards"},
		{key: "scoring.metric.red_card", stat: "redCards"},
	];

	@computed
	get isFiltersChanged() {
		return JSON.stringify(this._filters) !== JSON.stringify(defaultFilters);
	}

	get filteredPlayers() {
		const {search, squad, position, status} = this.filters;
		const players = this._playersStore.list;
		const stats = this._statsPlayersStore.allPlayersStats;

		const filtered = chain(players)
			.map((player) => ({
				...player,
				matchStats: stats[player.id],
			}))
			.filter((player: IPlayerWithStats) => {
				return (
					this.isInName(search, player) &&
					this.isInSquad(squad, player) &&
					this.isInPosition(position, player) &&
					this.isInStatus(status, player)
				);
			})
			.value();

		return this._filters.matchSort
			? this.sortByMatchStats(filtered)
			: this.sortByMainStats(filtered);
	}

	get statsColumns() {
		return this._statsColumns;
	}

	private sortByMatchStats(filtered: IPlayerWithStats[]): IPlayerWithStats[] {
		const isAscOrderStat = this._filters.sortDirection === SortOrder.ASC;
		const sorted = sortBy(filtered, (player: IPlayerWithStats) => {
			// show players with null at the end
			return get(player.matchStats, this._filters.matchSort, -9000);
		});

		return isAscOrderStat ? sorted : sorted.reverse();
	}

	private calculateSort(order: SortOrder, a: number, b: number) {
		const isAscOrderStat = order === SortOrder.ASC;
		return isAscOrderStat ? a - b : b - a;
	}

	private sortByMainStats(filtered: IPlayerWithStats[]): IPlayerWithStats[] {
		const currentRound = this._roundsStore.currentRound?.id ?? 0;
		return chain(filtered)
			.sort((a: IPlayerWithStats, b: IPlayerWithStats) => {
				const statSorts = [
					StatsCentreSort.NextFixture,
					StatsCentreSort.LastRoundPoints,
					StatsCentreSort.TotalPoints,
					StatsCentreSort.AveragePoints,
				];

				if (this._filters.sort === StatsCentreSort.RoundPoints) {
					const aPoints = get(a.stats?.scores, currentRound, 0);
					const bPoints = get(b.stats?.scores, currentRound, 0);
					return this.calculateSort(this._filters.sortDirection, aPoints, bPoints);
				}

				if (this._filters.sort === StatsCentreSort.Selected) {
					const aPercent = get(a.selected, currentRound, 0);
					const bPercent = get(b.selected, currentRound, 0);
					return this.calculateSort(this._filters.sortDirection, aPercent, bPercent);
				}

				if (this._filters.sort === StatsCentreSort.Price) {
					return this.calculateSort(this._filters.sortDirection, a.cost, b.cost);
				}

				if (statSorts.includes(this._filters.sort)) {
					const aStat = get(a.stats, this._filters.sort, 0);
					const bStat = get(b.stats, this._filters.sort, 0);
					return this.calculateSort(this._filters.sortDirection, aStat, bStat);
				}

				return 0;
			})
			.value();
	}

	isInSquad(squad: string, player: IPlayerWithStats) {
		if (squad === "null") {
			return true;
		}
		return player.squadId === parseInt(squad, 0);
	}

	isInName(searchValue: string, player: IPlayerWithStats): boolean {
		if (!searchValue.length) {
			return true;
		}
		let fullName = `${player.firstName} ${player.lastName}`.toLowerCase();
		let enteredName = searchValue.toLowerCase();

		forEach(SPECIAL_SYMBOLS, (item, key) => {
			fullName = fullName.replace(key, item);
			enteredName = enteredName.replace(key, item);
		});

		return fullName.includes(enteredName);
	}

	isInPosition(position: string[], player: IPlayerWithStats) {
		if (position.includes("null")) {
			return true;
		}
		const playerPosition = first(player.position);
		return playerPosition ? position.includes(playerPosition) : false;
	}

	isInStatus(status: UserStatuses, player: IPlayerWithStats) {
		if (status === UserStatuses.All) {
			return true;
		}
		return player.status === (status as unknown as PlayerStatus);
	}

	@action
	resetFilters = () => {
		this._filters = {
			...defaultFilters,
		};
	};

	@action
	resetSearch = () => {
		this._filters.search = "";
	};

	@action
	updateFilter = (e: SelectChangeEvent | SelectChangeEvent<string[]>) => {
		const {value, name} = e.target;
		const newValue = parseSingleValueFromArray(value, "null");

		this._filters = {
			...this._filters,
			[name]: newValue,
		};
	};

	@action
	updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const {value, name} = e.target;

		this._filters = {
			...this._filters,
			[name]: value,
		};
	};

	@action
	updateSort = (sort: StatsCentreSort | string) => {
		const kindOfSort =
			this.viewType === StatsCentreView.Stats ? this._filters.sort : this._filters.matchSort;
		const isAscOrder = kindOfSort === sort && this._filters.sortDirection === SortOrder.DESC;
		const sortDirection = isAscOrder ? SortOrder.ASC : SortOrder.DESC;

		if (this.viewType === StatsCentreView.Stats) {
			this._filters = {
				...this._filters,
				sort: sort as StatsCentreSort,
				matchSort: "",
				sortDirection,
			};
		} else {
			this._filters = {
				...this._filters,
				matchSort: sort,
				sortDirection,
			};
		}
	};

	@action
	public toggleFilters(): void {
		this._isFiltersVisible = !this._isFiltersVisible;
	}

	@action
	public openFilters(): void {
		this._isFiltersVisible = true;
	}

	@action
	public closeFilters(): void {
		this._isFiltersVisible = false;
	}
}
