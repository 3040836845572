import React from "react";
import {observer} from "mobx-react";
import {Modal, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {PureButton, ButtonBase} from "views/components/buttons/buttons.component";
import {ModalContent} from "views/components/modals/common";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeIcon.svg";
import logo from "assets/img/logoBadge.svg";
import type {IModalModalConfirmedController} from "views/components/modals/modal_confirmed/modal_confirmed.controller";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
`;

const Title = styled.h6`
	color: #19194b;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
`;

const Description = styled.div`
	color: #19194b;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

const ButtonBaseStyled = styled(ButtonBase)`
	max-width: 300px;
	margin: 20px auto 0 auto;
`;

export const ModalModalConfirmed: React.FC = observer(() => {
	const {i18n, close, isOpen, modalContent} = useViewController<IModalModalConfirmedController>(
		Bindings.ModalModalConfirmedController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<PureButton onClick={close}>
						<CloseIcon />
					</PureButton>
				</CloseBlock>
				<Icon>
					<img src={logo} alt={"World Cup France 2023"} />
				</Icon>

				<Title>{i18n.t(modalContent?.title ?? "modal.success.title", "-")}</Title>
				<Description>
					{i18n.t(modalContent?.message ?? "modal.success.text", "-")}
				</Description>
				<Stack sx={{width: "100%", flexDirection: {xs: "column", sm: "row"}}} gap={2.5}>
					<ButtonBaseStyled onClick={close} className="primary">
						{i18n.t("boosters.confirmed.view_team", "VIEW TEAM")}
					</ButtonBaseStyled>
				</Stack>
			</ModalContent>
		</Modal>
	);
});
