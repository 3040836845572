import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IDashboardProvider, IDashboard} from "data/providers/api/dashboard.provider";

export interface IDashboardStore {
	get dashboard(): IDashboard | undefined;

	fetchDashboard(): Promise<void>;
}

@injectable()
export class DashboardStore implements IDashboardStore {
	@observable private _dashboard: IDashboard | undefined;

	get dashboard() {
		return this._dashboard;
	}

	constructor(
		@inject(Bindings.DashboardProvider) private _dashboardProvider: IDashboardProvider
	) {
		makeAutoObservable(this);
	}

	@action async fetchDashboard() {
		const {data} = await this._dashboardProvider.fetchDashboard();
		this._dashboard = data.success;
	}
}
