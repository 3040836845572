import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import menuActiveIcon from "assets/img/icons/menuActiveIcon.svg";

const GameNavWrapper = styled.div`
	width: 100%;
	height: 65px;
	margin-left: 20px;

	@media screen and (max-width: 900px) {
		margin: 0;
	}
`;

const GameNavigation = styled.nav`
	display: flex;
	align-items: center;
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	gap: 12px;
	height: 100%;

	li {
		padding: 0;
		list-style: none;
	}

	li:first-of-type {
		margin-left: -10px;
	}

	a,
	button {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 25px;
		background: transparent;
		border: none;
		font-size: 16px;
		font-style: normal;
		font-weight: 900;
		line-height: normal;
		text-transform: uppercase;
		color: #19194b;

		&::after {
			position: absolute;
			bottom: -10px;
			left: 50%;
			content: "";
			display: block;
			width: calc(100% - 19px);
			height: 10px;
			transform: translateX(-50%);
			transition: background-color 0.25s linear;
		}

		&:hover,
		&.active {
			&::after {
				background: url(${menuActiveIcon}) no-repeat center;
				width: 62px;
			}
		}

		@media screen and (max-width: 900px) {
			&:before {
				content: "";
				position: absolute;
				left: -19px;
				width: 1px;
				height: 22px;
				background: #e8e8ed;
			}
		}

		@media screen and (max-width: 640px) {
			font-size: 12px;
		}
	}

	.last {
		margin-left: auto;
	}
`;

/**
 * Example
 *
 * <SubNavigationMenu>
 * 		<Fragment>
 * 			<li>
 * 				<NavLink to="/team">Manage Team</NavLink>
 * 			</li>
 * 			<li>
 * 				<NavLink to="/leaderboard">Leaderboard</NavLink>
 * 			</li>
 * 		    <li className="last">
 * 				<PureButton>Tutorial</PureButton>
 * 			</li>
 * 		</Fragment>
 * 	</SubNavigationMenu>
 */

interface IProps {
	children: React.ReactNode;
}

export const SubNavigationMenu: React.FC<IProps> = observer(({children}) => {
	return (
		<GameNavWrapper>
			<GameNavigation>{children}</GameNavigation>
		</GameNavWrapper>
	);
});
