export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	AuthController: Symbol("AuthController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	MenuItemsController: Symbol("MenuItemsController"),
	PlayersPoolController: Symbol("PlayersPoolController"),
	TeamController: Symbol("TeamController"),
	TeamBuilderStore: Symbol("TeamBuilderStore"),
	TeamStore: Symbol("TeamStore"),
	TeamApiProvider: Symbol("TeamStore"),
	ModalsStore: Symbol("IModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalRegisterController: Symbol("ModalRegisterController"),
	ModalSuccessController: Symbol("ModalSuccessController"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	LeaguesStore: Symbol("LeaguesStore"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	BackdoorController: Symbol("BackdoorController"),
	LocalizationController: Symbol("LocalizationController"),
	PlayerCardController: Symbol("PlayerCardController"),
	LineupController: Symbol("LineupController"),
	LineupPlayerController: Symbol("LineupPlayerController"),
	LandingController: Symbol("LandingController"),
	GameBarController: Symbol("GameBarController"),
	LockoutTimerController: Symbol("LockoutTimerController"),
	ModalSaveTeamController: Symbol("ModalSaveTeamController"),
	ModalAuthorizationController: Symbol("ModalAuthorizationController"),
	DashboardStore: Symbol("DashboardStore"),
	DashboardProvider: Symbol("DashboardProvider"),
	GameBarProvider: Symbol("GameBarProvider"),
	MobileLandingController: Symbol("MobileLandingController"),
	ModalPlayerController: Symbol("ModalPlayerController"),
	ModalTutorialController: Symbol("ModalTutorialController"),
	ModalTutorialPositionController: Symbol("ModalTutorialPositionController"),
	LeaderboardController: Symbol("LeaderboardController"),
	LeaderboardApiProvider: Symbol("LeaderboardApiProvider"),
	LeaderboardStore: Symbol("LeaderboardStore"),
	ModalBoostersController: Symbol("ModalBoostersController"),
	ModalReviewTeamController: Symbol("ModalReviewTeamController"),
	BoostersControlPanelController: Symbol("BoostersControlPanelController"),
	ModalBoosterConfirmedController: Symbol("ModalBoosterConfirmedController"),
	LanguageSelectorController: Symbol("LanguageSelectorController"),
	DesktopLandingController: Symbol("DesktopLandingController"),
	TradesControlPanelController: Symbol("TradesControlPanelController"),
	TransfersApiProvider: Symbol("TransfersApiProvider"),
	TransfersStore: Symbol("TransfersStore"),
	UserTableController: Symbol("UserTableController"),
	ModalTransfersController: Symbol("ModalTransfersController"),
	LeagueTableController: Symbol("LeagueTableController"),
	ModalApplyNotSuitableBoosterToCaptainController: Symbol(
		"ModalApplyNotSuitableBoosterToCaptainController"
	),
	AccountController: Symbol("AccountController"),
	ModalClearTeamConfirmationController: Symbol("ModalClearTeamConfirmationController"),
	WebViewGateStore: Symbol("WebViewGateStore"),
	WebViewGateController: Symbol("WebViewGateController"),
	HeaderController: Symbol("HeaderController"),
	LeaguesCTAController: Symbol("LeaguesCTAController"),
	FieldImageController: Symbol("FieldImageController"),
	ModalTransferBoosterController: Symbol("ModalTransferBoosterController"),
	StatsPlayersStore: Symbol("StatsPlayersStore"),
	RankingsItemController: Symbol("RankingsItemController"),
	PointsMessageController: Symbol("PointsMessageController"),
	StatsCentreController: Symbol("StatsCentreController"),
	StatsCentreStore: Symbol("StatsCentreStore"),
	StatsCentreFiltersController: Symbol("StatsCentreFiltersController"),
	GameBarStore: Symbol("GameBarStore"),
	ModalModalConfirmedController: Symbol("ModalModalConfirmedController"),
	RemovePlayersController: Symbol("RemovePlayersController"),
	ModalPoolsCompleteController: Symbol("ModalPoolsCompleteController"),
	MatchesController: Symbol("MatchesController"),
};
