import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {BoosterTypes, ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import Keycloak from "keycloak-js";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";

export interface IModalBoosterConfirmedController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	get boostedPlayer(): IPlayer | undefined;
	get boosterForChoice(): BoosterTypes | null;

	close: () => void;
}

@injectable()
export class ModalBoosterConfirmedController implements IModalBoosterConfirmedController {
	@observable _keycloak: Keycloak | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.BOOSTERS_CONFIRM;
	}

	get boostedPlayer(): IPlayer | undefined {
		if (this._teamBuilderStore.selectedPlayerForBoost) {
			return this._playersStore.getPlayerById(this._teamBuilderStore.selectedPlayerForBoost);
		}
		return undefined;
	}

	get boosterForChoice(): BoosterTypes | null {
		return this._teamBuilderStore.boosterForChoice;
	}

	dispose(): void {
		return;
	}

	private clearBoosterInfo = () => {
		this._teamBuilderStore.boosterForChoice = null;
		this._teamBuilderStore.selectedPlayerForBoost = null;
	};

	public close = () => {
		this._modalsStore.hideModal();
		this.clearBoosterInfo();
	};
}
