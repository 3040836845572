import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {RequestState, StatFilter} from "data/enums";
import {isEmpty, isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {ILeaderboardStore, IRanking} from "data/stores/leaderboard/leaderboard.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import {SelectChangeEvent} from "@mui/material";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {ISquadsStore} from "data/stores/squads/squds.store";

interface IProps {
	leagueId: number;
	isMobile: boolean;
}

export interface ILeagueTableController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get rankings(): IRanking[];
	get isLoading(): boolean;
	get noRankingsAvailable(): boolean;
	get selectedRound(): string;
	get completeOrActiveRounds(): IRound[];
	get selectedStat(): StatFilter;
	get selectedStatHeader(): string;
	get nextPage(): boolean;
	get canViewTeam(): boolean;
	get numColumns(): number;
	get openTeamID(): number | null;

	changeRound: (e: SelectChangeEvent) => void;
	changeStat: (e: SelectChangeEvent) => void;
	setOpenTeamID: (id: number | null) => void;
	loadMoreLeaderboard: (leagueId: number) => void;
}

@injectable()
export class LeagueTableController implements ILeagueTableController {
	@observable private _fetchRankingsDisposer?: ReturnType<typeof reaction>;
	@observable private _requestState = RequestState.PENDING;
	@observable private _isMobile: boolean = false;
	@observable private _openTeamID: number | null = null;

	get rankings() {
		return this._leaderboardStore.leaderboard.rankings;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get noRankingsAvailable() {
		return isEmpty(this.rankings) && isEqual(this._requestState, RequestState.SUCCESS);
	}

	get selectedRound() {
		return this._leaderboardStore.selectedRound;
	}

	get completeOrActiveRounds() {
		return this._roundsStore.completeOrActiveRounds;
	}

	get selectedStat() {
		return this._leaderboardStore.selectedStat;
	}

	get selectedStatHeader() {
		return {
			[StatFilter.TotalPoints]: this.i18n.t("leaderboard.stat.total_points", "Total Pts"),
			[StatFilter.AveragePoints]: this.i18n.t("leaderboard.stat.average", "average pts"),
			[StatFilter.LastRoundPoints]: this.i18n.t(
				"leaderboard.stat.last_round",
				"last rnd pts"
			),
		}[this.selectedStat];
	}

	get nextPage() {
		return this._leaderboardStore.leaderboard.nextPage;
	}

	get canViewTeam() {
		return this._leaderboardStore.canViewTeam;
	}

	get numColumns() {
		if (this._isMobile && this.canViewTeam) {
			return 4;
		}
		if (this._isMobile) {
			return 3;
		}
		if (this.canViewTeam) {
			return 6;
		}
		return 5;
	}

	get openTeamID() {
		return this._openTeamID;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardStore) private _leaderboardStore: ILeaderboardStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	@action changeRound = (e: SelectChangeEvent) => {
		const {value} = e.target;
		this._leaderboardStore.setSelectedRound(value);
	};

	@action changeStat = (e: SelectChangeEvent) => {
		const {value} = e.target;
		this._leaderboardStore.setSelectedStat(value as StatFilter);
	};

	@action setOpenTeamID = (id: number | null) => {
		this._openTeamID = id;
	};

	@action onSuccessRankingsRequest = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		console.error(extractErrorMessage(error));
	};

	@action init({isMobile, leagueId}: IProps) {
		this._isMobile = isMobile;

		void Promise.all([
			this._squadsStore.fetchSquads(),
			this._playersStore.fetchPlayers(),
			this._roundsStore.fetchRounds(),
		]).catch(this.onError);

		this._fetchRankingsDisposer = reaction(
			() => this.selectedRound,
			() => {
				this._requestState = RequestState.PENDING;

				this.setOpenTeamID(null);

				const params =
					this.selectedRound !== "all"
						? {
								leagueId,
								round: +this.selectedRound,
						  }
						: {
								leagueId,
						  };

				void this._leaderboardStore
					.fetchRankings(params)
					.then(this.onSuccessRankingsRequest)
					.catch(this.onError);
			},
			{fireImmediately: true}
		);
	}

	@action loadMoreLeaderboard = (leagueId: number) => {
		const params =
			this.selectedRound !== "all"
				? {
						leagueId,
						round: +this.selectedRound,
				  }
				: {
						leagueId,
				  };
		this._leaderboardStore.loadMoreLeagueLeaderboard(params);
	};

	dispose() {
		this._fetchRankingsDisposer?.();
	}
}
