import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, computed, runInAction, action} from "mobx";
import {Bindings} from "data/constants/bindings";
import {BoosterTypes, PlayerPosition, TournamentStatus} from "data/enums";
import {
	type ITeamApiProvider,
	type IBoosterPayload,
	type IBoosterRemovePayload,
	type IHistoryTeamByUserPayload,
} from "data/providers/api/team.api.provider";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {chain, indexOf, set, first, isEmpty} from "lodash";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import {ISelectOption} from "data/types/types";
import {checkRoundState, playerPositionsSelect} from "data/utils/helpers";
import {hasEmptySpot} from "data/utils/lineup";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

export type ILineup = Record<PlayerPosition, number[]>;

export interface IBooster {
	id: number;
	playerId: number;
	roundId: number;
	roundNumber: number;
	type: BoosterTypes;
}

export interface ITeam {
	id: number;
	startRoundId: number | null;
	name: string;
	captainId: number;
	lineup: ILineup;
	salaryCap: number;
	value: number;
	boosters: IBooster[];
	isComplete: boolean;
}

export interface ITeamHistory {
	roundId: number;
	lineup: ILineup;
	captainId: number | null;
	value: number;
	salaryCap: number;
	boosters: IBooster[];
}

export interface ITeamStore {
	get team(): ITeam;
	set team(team: ITeam);
	get lineup(): ILineup;
	get lineupIDs(): number[];
	get lineupPlayers(): IPlayer[];
	get positionsArray(): ISelectOption[];
	get playerPriceSum(): number;
	get budget(): number;
	get isBudgetNegative(): boolean;
	get salaryCap(): number;
	get isTeamChanged(): boolean;
	get isLoading(): boolean;
	get isComplete(): boolean;
	get isTeamScoring(): boolean;
	get isLineupEmpty(): boolean;
	get isBlockingNav(): boolean;
	get isShowHistoryTeam(): boolean;
	get teamByUser(): ITeamHistory | ITeam;

	canAddPlayerToTeam: (player: IPlayer) => boolean;
	getIsPlayerInTeam: (player: IPlayer) => boolean;
	getIsPlayerCapitan: (player: IPlayer) => boolean;
	hasEmptySpot: (lineup: ILineup, position: PlayerPosition[]) => boolean;
	getIsPlayerCountryAvailable: (player: IPlayer) => boolean;
	getPlayerByLineupKeyAndPosition: (
		lineupKey: number,
		position: PlayerPosition,
		isTransferMode: boolean,
		transferLineup: ILineup
	) => number | undefined;
	getPlayerByLineupKeyAndPositionByUserTeam: (
		lineupKey: number,
		position: PlayerPosition
	) => number | undefined;
	getTeamByUserPlayerByLineupKeyAndPosition: (
		lineupKey: number,
		position: PlayerPosition
	) => number | undefined;
	removePlayerFromPool: (player: IPlayer) => void;
	addPlayerInLineup: (player: IPlayer) => void;
	setCaptain: (playerId: number) => void;
	clearTeam: () => void;
	fetchTeam: () => Promise<void>;
	fetchHistoricalTeam: (roundId: number) => Promise<void>;
	fetchHistoricalTeamByUser: (payload: IHistoryTeamByUserPayload) => Promise<void>;
	fetchOtherUserTeam: (userId: number) => Promise<void>;
	updateTeam: () => Promise<void>;
	updateCaptain: (newId: number) => Promise<void>;
	autofillTeam: () => Promise<void>;
	setBooster: (payload: IBoosterPayload) => Promise<void>;
	removeBooster: (payload: IBoosterRemovePayload) => Promise<void>;
	getIsPlayerDisabledInCurrentRound: (player: IPlayer) => boolean;
}

@injectable()
export class TeamStore implements ITeamStore {
	private _defaultLineup: ILineup = {
		[PlayerPosition.Prop]: [0, 0],
		[PlayerPosition.Hooker]: [0],
		[PlayerPosition.Lock]: [0, 0],
		[PlayerPosition.LooseForward]: [0, 0, 0],
		[PlayerPosition.ScrumHalf]: [0],
		[PlayerPosition.FlyHalf]: [0],
		[PlayerPosition.Center]: [0, 0],
		[PlayerPosition.OutsideBack]: [0, 0, 0],
	};
	@observable private _isLoading: boolean = false;
	@observable private _salaryCap: number = 0;
	@observable private _isPreseason: boolean = true;
	@observable private _team: ITeam = {
		id: 0,
		name: "",
		startRoundId: null,
		lineup: this._defaultLineup,
		captainId: 0,
		salaryCap: 0,
		value: 0,
		boosters: [],
		isComplete: false,
	};

	@observable private _isTeamChanged: boolean = false;

	@observable private _saveTimeout: ReturnType<typeof setTimeout> | null = null;
	@observable private _isActiveParkTheBus: boolean = true;
	@observable private _isShowHistoryTeam: boolean = false;

	@observable private _firstAddedShouldBecomeACaptain = false;

	@observable private _teamByUser: ITeamHistory | ITeam = {
		roundId: 0,
		lineup: this._defaultLineup,
		captainId: null,
		salaryCap: 0,
		value: 0,
		boosters: [],
	};

	constructor(
		@inject(Bindings.TeamApiProvider) private _teamApiProvider: ITeamApiProvider,
		@inject(Bindings.LocalizationStore) public _i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get team() {
		return this._team;
	}

	set team(team: ITeam) {
		this._team = team;
	}

	get lineupIDs(): number[] {
		return chain(this.lineup).values().flatten().value();
	}

	get lineupPlayers(): IPlayer[] {
		const lineupIds = this.lineupIDs;
		return lineupIds
			.map((id) => this._playersStore.getPlayerById(id))
			.filter((player) => player !== undefined) as IPlayer[];
	}

	get lineup() {
		return this._team.lineup || this._defaultLineup;
	}

	get isLineupEmpty() {
		return isEmpty(this.lineupPlayers);
	}

	get positionsArray(): ISelectOption[] {
		return playerPositionsSelect;
	}

	@computed
	get playerPriceSum() {
		return this._playersStore.getLineupPrice(this.lineupIDs);
	}

	@computed
	get budget() {
		return this._salaryCap - this.playerPriceSum;
	}

	get isBudgetNegative() {
		return this.budget < 0;
	}

	get salaryCap() {
		return this._salaryCap;
	}

	get isTeamChanged() {
		return this._isTeamChanged;
	}

	get isLoading() {
		return this._isLoading;
	}

	get isComplete() {
		return this.team.isComplete;
	}

	get isTeamScoring() {
		if (!this.team.startRoundId || !this._roundsStore.scoreRound) {
			return false;
		}
		return this._roundsStore.scoreRound.id >= this.team.startRoundId;
	}

	@computed
	get isTeamFullFilled(): boolean {
		return !chain(this.team.lineup).values().flatten().value().includes(0);
	}

	get isBlockingNav() {
		const isChanged = this.isTeamChanged;

		if (!this.team.id && (!this.team.captainId || !this.isTeamFullFilled)) {
			return !this.isEmpty;
		}

		return isChanged;
	}

	get isShowHistoryTeam() {
		return this._isShowHistoryTeam;
	}

	private get isEmpty() {
		return (
			chain(this.team.lineup)
				.values()
				.flatten()
				.filter((id) => id !== 0)
				.value().length === 0
		);
	}

	get teamByUser() {
		return this._teamByUser;
	}

	hasEmptySpot(lineup: ILineup, position: PlayerPosition[]) {
		return hasEmptySpot(lineup, position);
	}

	getIsPlayerInTeam(player: IPlayer) {
		return this.team.lineup[first(player.position) as PlayerPosition].includes(player.id);
	}

	getIsPlayerCapitan(player: IPlayer) {
		return this.team.captainId === player.id;
	}

	/*
	 * An entrant’s team can contain a maximum of TWO players from any one nation. However, this will increase during the Knockout Stage.
	 * Quarter Finals: Four Players
	 * Semi-Finals: Five Players
	 * Final & Bronze Final: Six Players
	 * */
	getIsPlayerCountryAvailable = (newPlayer: IPlayer) => {
		const playersInTeam = this.lineupPlayers;
		const activeRound = this._roundsStore.currentRound;
		let maxCountriesSelect = 3;

		if (activeRound?.number && checkRoundState.isQuarterFinals(activeRound.number)) {
			maxCountriesSelect = 4;
		}
		if (activeRound?.number && checkRoundState.isSemiFinals(activeRound.number)) {
			maxCountriesSelect = 5;
		}
		if (activeRound?.number && checkRoundState.isFinalAndBronzeFinal(activeRound.number)) {
			maxCountriesSelect = 6;
		}

		const foundPlayersFromTheSameCountry = playersInTeam.filter(
			(player) => player.squadId === newPlayer.squadId
		);
		return foundPlayersFromTheSameCountry.length !== maxCountriesSelect;
	};

	getIsPlayerDisabledInCurrentRound = (newPlayer: IPlayer) => {
		const currentRound = this._roundsStore.currentRound;

		return Boolean(
			currentRound?.tournaments.find(
				(tournament) =>
					tournament.status !== TournamentStatus.Scheduled &&
					(newPlayer.squadId === tournament.awaySquadId ||
						newPlayer.squadId === tournament.homeSquadId)
			)
		);
	};

	canAddPlayerToTeam = (player: IPlayer) => {
		const lineup = this.lineup;

		if (
			this.getIsPlayerInTeam(player) ||
			!this.getIsPlayerCountryAvailable(player) ||
			this.getIsPlayerDisabledInCurrentRound(player)
		) {
			return false;
		}

		const {position} = player;

		return this.hasEmptySpot(lineup, position);
	};

	getPlayerByLineupKeyAndPosition(
		lineupKey: number,
		position: PlayerPosition,
		isTransferMode: boolean,
		transferLineup: ILineup
	) {
		const lineup = isTransferMode ? transferLineup : this.team.lineup;
		return lineup[position]?.find((teamId, key) => key === lineupKey);
	}

	getPlayerByLineupKeyAndPositionByUserTeam(lineupKey: number, position: PlayerPosition) {
		return this.teamByUser.lineup[position]?.find((teamId, key) => key === lineupKey);
	}

	getTeamByUserPlayerByLineupKeyAndPosition(lineupKey: number, position: PlayerPosition) {
		return this._teamByUser.lineup[position]?.find((teamId, key) => key === lineupKey);
	}

	removePlayerFromPool(player: IPlayer) {
		const {position} = player;
		const positionPlayer = first(position) as PlayerPosition;

		if (this._team.captainId === player.id) {
			this._team.captainId = 0;
		}

		const actualLineup: ILineup = this.lineup;
		const line = [...actualLineup[positionPlayer]];

		const newLineup: ILineup = {
			...actualLineup,
			[positionPlayer]: set(line, indexOf(line, player.id), 0),
		};

		runInAction(() => {
			this._team.lineup = newLineup;
		});
	}

	addPlayerInLineup(player: IPlayer) {
		const {position} = player;
		const positionPlayer = first(position) as PlayerPosition;

		if (!this.lineupPlayers.length || !this._team.captainId) {
			this.setCaptain(player.id);
		}

		const actualLineup: ILineup = this.lineup;
		const line = [...actualLineup[positionPlayer]];

		const newLineup: ILineup = {
			...actualLineup,
			[positionPlayer]: set(line, indexOf(line, 0), player.id),
		};

		runInAction(() => {
			this._team.lineup = newLineup;
			this._isTeamChanged = true;
		});
	}

	setCaptain(playerId: number) {
		this._team.captainId = playerId;
		this._isTeamChanged = true;
	}

	async updateTeam() {
		const response = await this._teamApiProvider.updateTeam({
			captainId: this._team.captainId,
			lineup: this.lineup,
		});

		runInAction(() => {
			this._team = response.data.success.team;
			this._isTeamChanged = false;
		});
	}

	async updateCaptain(newId: number) {
		const response = await this._teamApiProvider.updateCaptain({
			newId,
		});

		runInAction(() => {
			this._team.captainId = response.data.success.team.captainId;
		});
	}

	@action
	clearTeam() {
		runInAction(() => {
			this._team.lineup = this._defaultLineup;
			this._team.captainId = 0;
			this._salaryCap = this._team.salaryCap;
			this._isTeamChanged = false;
		});
	}

	@action
	async fetchTeam() {
		const response = await this._teamApiProvider.team();

		runInAction(() => {
			this._team = response.data.success.team;
			this._salaryCap = response.data.success.team.salaryCap;
			this._isShowHistoryTeam = false;
		});
	}

	@action
	async fetchHistoricalTeam(roundId: number) {
		const response = await this._teamApiProvider.historyTeam({roundId});

		runInAction(() => {
			this._team = {
				...response.data.success.teamHistory,
				boosters: this._team.boosters,
				isComplete: this._team.isComplete,
				startRoundId: this._team.startRoundId,
			};
			this._isShowHistoryTeam = true;
		});
	}

	@action
	async fetchHistoricalTeamByUser(payload: IHistoryTeamByUserPayload) {
		const response = await this._teamApiProvider.historyTeamByUser(payload);

		runInAction(() => {
			this._teamByUser = response.data.success.teamHistory;
		});
	}

	@action
	async fetchOtherUserTeam(userId: number) {
		const response = await this._teamApiProvider.fetchOtherUserTeam(userId);

		runInAction(() => {
			this._teamByUser = response.data.success.team;
		});
	}

	@action
	async autofillTeam() {
		const response = await this._teamApiProvider.autopick({
			captainId: this._team.captainId,
			lineup: this.lineup,
		});

		runInAction(() => {
			this._team = response.data.success.team;
			this._isTeamChanged = true;
		});
	}

	@action
	async setBooster(payload: IBoosterPayload) {
		this._isLoading = true;
		await this._teamApiProvider
			.set_booster(payload)
			.then((response) => {
				runInAction(() => {
					this._team = response.data.success.team;
				});
			})
			.finally(() => {
				this._isLoading = false;
			});
	}

	@action
	async removeBooster(payload: IBoosterRemovePayload) {
		this._isLoading = true;
		await this._teamApiProvider
			.remove_booster(payload)
			.then(() => {
				runInAction(() => {
					this._team.boosters = this._team.boosters.filter(
						(booster) => booster.id !== payload.boosterId
					);
				});
			})
			.finally(() => {
				this._isLoading = false;
			});
	}
}
