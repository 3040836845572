import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import Keycloak from "keycloak-js";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Locale, ModalType} from "data/enums";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse, getKcToken} from "data/utils/helpers";
import {get} from "lodash";
import {SUPPORTED_LOCALES} from "data/constants";
import type {IHttpClientService} from "data/services/http";

interface IParams {
	keycloak: Keycloak;
}

export interface ISessionController extends ViewController<IParams> {
	get isSessionChecked(): boolean;
	loginUser(keycloak: Keycloak): Promise<void>;
	checkUser: () => void;
}

@injectable()
export class SessionController implements ISessionController {
	@observable _isSessionChecked = false;
	_keycloak: Keycloak | null = null;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.ApiHTTPClient) private _apiHTTPClient: IHttpClientService
	) {
		makeAutoObservable(this);
	}

	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}

	@action init(param: IParams) {
		this._keycloak = param.keycloak;
	}

	public checkUser = () => {
		void this.checkLanguage();
		void this.updateUserInfo();
	};

	private setSessionChecked = () => {
		runInAction(() => {
			this._isSessionChecked = true;
			if (this._userStore.user && window.exponea) {
				window.exponea.identify(
					{
						email_id: this._userStore.user.email,
						keycloak_user_id: this._userStore.user.wrId,
					},
					{
						last_login_fnt: Math.floor(new Date().getTime() / 1000),
					}
				);
			}
		});
	};

	private onError = (err: IAxiosApiErrorGeneral) => {
		if (get(err, "response.status", 0) === 409) {
			this._modalsStore.showModal(ModalType.REGISTRATION);
		} else {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: getErrorMessageFromAxiosResponse(err),
			});
		}
	};

	@action
	async checkLanguage() {
		const locale = get(this._keycloak?.idTokenParsed, "locale") as Locale;
		if (locale !== this._i18nStore.locale && SUPPORTED_LOCALES.includes(locale)) {
			await this._i18nStore
				.switchLocale({
					locale,
				})
				.then(() => this._apiHTTPClient.setLocale(this._i18nStore.lang));
		}
	}

	@action
	async updateUserInfo() {
		await this._userStore
			.requestUser()
			.catch(() => {
				if (this._keycloak?.authenticated) {
					void this.loginUser();
				}

				const kc_token = getKcToken();
				if (kc_token) {
					void this.loginUserByToken(kc_token);
				}
			})
			.then(this.setSessionChecked);
	}

	@action
	async loginUser() {
		await this._userStore
			.login({token: this._keycloak?.token || ""})
			.catch(this.onError)
			.then(this.setSessionChecked);
	}

	@action
	async loginUserByToken(token: string) {
		await this._userStore.login({token}).catch(this.onError).then(this.setSessionChecked);
	}
}
