import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {IDashboardStore} from "data/stores/dashboard/dashboard.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IDashboard} from "data/providers/api/dashboard.provider";
import {ILeague} from "data/stores/leagues/leagues.store";
import {get} from "lodash";
import type {ITeamStore} from "data/stores/team/team.store";
import {useNavigate} from "react-router";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IMobileLandingController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get teamStartRound(): IRound | undefined;
	get isAuthorized(): boolean;
	get user(): IUser | undefined;
	get dashboard(): IDashboard | undefined;
	get last3Leagues(): ILeague[] | undefined;
	get isLoaded(): boolean;
}

@injectable()
export class MobileLandingController implements IMobileLandingController {
	private _navigate!: IProps["navigate"];
	@observable private _isLoaded = false;

	get teamStartRound() {
		const startTeamRound = this.dashboard?.roundId || null;
		return this._roundsStore.getRoundById(startTeamRound);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get user(): IUser | undefined {
		return this._userStore.user;
	}

	get dashboard() {
		return this._dashboardStore.dashboard;
	}

	get last3Leagues() {
		const leagues = get(this.dashboard, "leagues", []) as ILeague[];
		return leagues.filter((league, key) => key <= 2);
	}

	get isLoaded() {
		return this._isLoaded;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.DashboardStore) private readonly _dashboardStore: IDashboardStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	private checkTeam = () => {
		if (!this._teamStore.isComplete) {
			this._navigate("/my-team");
		}
	};

	async init({navigate}: IProps) {
		this._navigate = navigate;

		await Promise.all([
			this._dashboardStore.fetchDashboard(),
			this._roundsStore.fetchRounds(),
			this._teamStore.fetchTeam().then(this.checkTeam),
		]).catch(this.onError);

		this._isLoaded = true;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(error),
		});
	};
}
