import React from "react";
import {observer} from "mobx-react";
import {Modal, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/Error";
import {Exist} from "views/components/exist/exist.component";
import {ModalContent, CloseButton} from "views/components/modals/common";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeIcon.svg";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);
	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<CloseButton onClick={close}>
						<CloseIcon />
					</CloseButton>
				</CloseBlock>
				<Icon>
					<ErrorIcon />
				</Icon>

				<Typography>
					{i18n.t(modalContent?.title ?? "modal.error.title", "Error")}
				</Typography>
				<Exist when={Boolean(modalContent?.message)}>
					<Typography>{modalContent?.message}</Typography>
				</Exist>

				{/*<Button fullWidth variant="contained" onClick={close}>Close</Button>*/}
			</ModalContent>
		</Modal>
	);
});
