import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {AxiosResponse} from "axios";
import type {ITransfer} from "data/stores/transfers/transfers.store";
import type {ITeam} from "data/stores/team/team.store";

type ITradesTeamResponse = IApiResponse<{team: ITeam}>;

interface ITransfersPayload {
	tradePairs: ITransfer[];
}

export interface ITransfersApiProvider {
	make: (params: ITransfersPayload) => Promise<AxiosResponse<ITradesTeamResponse>>;
}

@injectable()
export class TransfersApiProvider implements ITransfersApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	make = (params: ITransfersPayload) =>
		this._http.post<ITradesTeamResponse>(`fantasy/trade/make`, params);
}
