import {Language, Locale} from "data/enums";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || "";
export const SSO_CLIENT_APP_ID = process.env.REACT_APP_SSO_APP_CLIENT_ID || "";
export const SSO_CLIENT_REALM = process.env.REACT_APP_SSO_CLIENT_REALM || "";
export const SSO_URL = process.env.REACT_APP_SSO_URL || "";
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || "";

export const SPECIAL_SYMBOLS = {
	ä: "a",
	Ä: "A",
	ö: "o",
	Ö: "O",
	ü: "u",
	Ü: "U",
	á: "a",
	í: "i",
	é: "e",
	å: "a",
	ß: "b",
};

export const positionCategories = {
	frontRow: {
		key: "team_builder.position_сategories.front_row",
		value: "Front Row",
	},
	hooker: {
		key: "team.position.hooker",
		value: "Hooker",
	},
	secondRow: {
		key: "team_builder.position_сategories.second_row",
		value: "Second Row",
	},
	flanker: {
		key: "team_builder.position_сategories.flanker",
		value: "Flanker",
	},
	numberEight: {
		key: "team_builder.position_сategories.number_eight",
		value: "#8",
	},
	flyHalf: {
		key: "team_builder.position_сategories.fly_half",
		value: "Fly Half",
	},
	scrumHalf: {
		key: "team_builder.position_сategories.scrum_half",
		value: "Scrum Half",
	},
	winger: {
		key: "team_builder.position_сategories.winger",
		value: "Winger",
	},
	center: {
		key: "team_builder.center.winger",
		value: "Center",
	},
	fullBack: {
		key: "team_builder.full_back.winger",
		value: "Full Back",
	},
};

export const MILLION = 1000000;

export const MS_IN_SECOND = 1000;
export const SEC_IN_MINUTE = 60;
export const MIN_IN_HOUR = 60;
export const HRS_IN_DAY = 24;
export const SEC_IN_HOUR = MIN_IN_HOUR * SEC_IN_MINUTE;
export const SEC_IN_DAY = HRS_IN_DAY * SEC_IN_HOUR;

export const TUTORIAL_KEY = "fantasy_tutorial";

export const LANGUAGES: Record<Language, string> = {
	[Language.EN]: "navigation.language.en",
	[Language.FR]: "navigation.language.fr",
};

export const SUPPORTED_LOCALES = [Locale.EN_US, Locale.EN, Locale.FR];

export const HEADER_HEIGHT = 60;

export const SHOW_OVERVIEW_TAB = JSON.parse(
	process.env.REACT_APP_SHOW_OVERVIEW_TAB || "false"
) as boolean;
export const SHOW_PLAYER_AVATARS = JSON.parse(
	process.env.REACT_APP_SHOW_PLAYER_AVATARS || "false"
) as boolean;
