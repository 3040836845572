import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboard} from "data/stores/leaderboard/leaderboard.store";

export interface ILeaderboardPayload {
	round?: number;
	page?: number;
	limit?: number;
}

export interface ILeaderboardLeaguePayload extends ILeaderboardPayload {
	leagueId: number;
}

type ILeaderboardResponse = IApiResponse<ILeaderboard>;

export interface ILeaderboardApiProvider {
	fetchRankings: (params: ILeaderboardPayload) => Promise<AxiosResponse<ILeaderboardResponse>>;
	fetchLeagueRankings: (
		params: ILeaderboardLeaguePayload
	) => Promise<AxiosResponse<ILeaderboardResponse>>;
}

@injectable()
export class LeaderboardApiProvider implements ILeaderboardApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	fetchRankings = (params: ILeaderboardPayload) =>
		this._http.get<ILeaderboardResponse>("ranking", params);

	fetchLeagueRankings = (params: ILeaderboardLeaguePayload) =>
		this._http.get<ILeaderboardResponse>(`ranking/league/${params.leagueId}`, params);
}
