import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, computed} from "mobx";
import {type IChecksums, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {ISquadsStore} from "data/stores/squads/squds.store";
import {get} from "lodash";

/**
 * Constant for determine update frequency.
 */
const ONE_MINUTE = 1000 * 60;
const LIVE_SCORING_FETCH_TIMING_FOR_TEAM_PAGE = ONE_MINUTE;
const LIVE_SCORING_FETCH_TIMING = ONE_MINUTE * 30;

type IChecksumAction = Record<keyof IChecksums, () => Promise<void>>;

interface IParams {
	isTeamPage?: boolean;
}

export interface ILiveScoreController extends ViewController<IParams> {
	subscribeLiveScoring: (location?: string) => void;
	unsubscribeLiveScoring: () => void;
}

@injectable()
export class LiveScoreController implements ILiveScoreController {
	protected readonly _actions: IChecksumAction;
	@observable protected _interval?: ReturnType<typeof setInterval>;
	@observable protected _isSubscribed: boolean = false;
	@observable protected _isTeamPage: boolean = false;

	get updatedChecksum(): IChecksums {
		return this._checksumStore.changedChecksums;
	}
	constructor(
		@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
		this._actions = this.generateActions();
	}

	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) {
			return;
		}

		this._isSubscribed = true;

		void this._checksumStore.fetchChecksums().finally(() => this.callActions());

		const time = this._isTeamPage
			? LIVE_SCORING_FETCH_TIMING_FOR_TEAM_PAGE
			: LIVE_SCORING_FETCH_TIMING;

		this._interval = setInterval(() => {
			void this._checksumStore.fetchChecksums().finally(() => this.callActions());
		}, time);
	}

	@computed private get changes() {
		return Object.keys(this._checksumStore.changedChecksums);
	}

	/**
	 * Stop checking changes
	 * called on dispose
	 * or you can call it when you want to stop listen checksums, for example on the end of the game match/round/etc.
	 */
	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	private callActions = () => {
		this.changes.forEach((key) => {
			const action = get(this._actions, key) as () => Promise<void> | undefined;

			if (action && typeof action === "function") {
				void action();
			}
		});
	};

	/**
	 * Provide object of files you want to update
	 * for example: rounds
	 */
	private generateActions(): IChecksumAction {
		return {
			rounds: () => this._roundsStore.fetchRounds(),
			players: () => this._playersStore.fetchPlayers(),
		};
	}

	dispose(): void {
		this.unsubscribeLiveScoring();
	}

	init({isTeamPage}: IParams): void {
		this._isTeamPage = Boolean(isTeamPage);
		this.subscribeLiveScoring();
	}
}
