import styled from "@emotion/styled";
import MainBg from "assets/img/MainBg.png";

export const GlobalWrapper = styled.div`
	background-size: contain;
	background: url(${MainBg}) no-repeat center #f2f2f2;
	background-size: cover;
`;

export const Wrapper = styled.div`
	width: 100%;
	font-family: "Webb Ellis Cup", sans-serif;
	position: relative;
`;
