import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {RoundStage, RoundStatus, TournamentStatus} from "data/enums";
import {first, last, forEach, isEmpty} from "lodash";

export interface ITournament {
	id: number;
	venueName: string;
	venueId: number;
	date: string;
	status: TournamentStatus;
	homeSquadId: number;
	awaySquadId: number;
	homeSquadName: string;
	awaySquadName: string;
	homeSquadAbbr: string;
	awaySquadAbbr: string;
	homeScore: null | string;
	awayScore: null | string;
}

export interface IRound {
	id: number;
	number: number;
	status: RoundStatus;
	startDate: string;
	endDate: string;
	tournaments: ITournament[];
	roundNumber?: number;
	roundStage?: RoundStage;
}

export interface IRoundsStore {
	get getIsLoading(): boolean;

	get list(): IRound[];

	get scheduleRounds(): IRound[];

	get completeOrActiveRounds(): IRound[];

	get completeAndCurrentRounds(): IRound[];

	get currentRound(): IRound | undefined;

	get completedRounds(): IRound[];

	get scoreRound(): IRound | null;

	get lastCompleteRound(): IRound | undefined;

	get getAllTournaments(): ITournament[];

	get getAllScheduledTournaments(): ITournament[];

	get getAllCompletedTournaments(): ITournament[];

	get getAllCompletedAndPlayingTournaments(): ITournament[];

	get isPreseason(): boolean;

	get hasNoCompletedRound(): boolean;

	get isMatchLive(): boolean;

	get nextScheduledTournament(): ITournament | undefined;

	get activeRound(): IRound | undefined;

	get isCurrentRoundScoring(): boolean;

	fetchRounds(): Promise<void>;

	getRoundById(id: number | null): IRound | undefined;

	getRoundStageByRoundNumber(roundNumber: number): RoundStage;
}

@injectable()
export class RoundsStore implements IRoundsStore {
	@observable private _isLoading: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IRound[] = [];

	get list() {
		return this._list.map((round, key) => {
			const roundNumber = ++key;
			return {
				roundNumber,
				roundStage: this.getRoundStageByRoundNumber(roundNumber),
				...round,
			};
		});
	}

	get scheduleRounds() {
		return this.list.filter((e) => e.status === RoundStatus.Scheduled);
	}

	get completedRounds() {
		return this.list.filter((e) => e.status === RoundStatus.Complete);
	}

	get completeOrActiveRounds() {
		return this.list.filter((e) => e.status !== RoundStatus.Scheduled);
	}

	get completeAndCurrentRounds() {
		const rounds = this.completedRounds;
		const currentRound = this.currentRound;

		if (currentRound && !rounds.find((round) => round.id === currentRound.id)) {
			rounds.push(currentRound);
		}
		return rounds.map((round, key) => ({
			...round,
			roundNumber: ++key,
		}));
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get currentRound() {
		return this.activeRound || first(this.scheduleRounds) || last(this.list);
	}

	get scoreRound() {
		return this.activeRound || last(this.completedRounds) || null;
	}

	get lastCompleteRound() {
		return last(this.completedRounds);
	}

	get getAllTournaments() {
		let tournaments: ITournament[] = [];
		forEach(this._list, (round) => {
			tournaments = tournaments.concat(round.tournaments);
		});
		return tournaments;
	}

	get getAllScheduledTournaments() {
		return this.getAllTournaments.filter(
			(tournament) => tournament.status === TournamentStatus.Scheduled
		);
	}

	get getAllCompletedTournaments() {
		return this.getAllTournaments.filter(
			(tournament) => tournament.status === TournamentStatus.Complete
		);
	}

	get getAllCompletedAndPlayingTournaments() {
		return this.getAllTournaments.filter(
			(tournament) =>
				tournament.status === TournamentStatus.Complete ||
				tournament.status === TournamentStatus.Playing
		);
	}

	get isPreseason() {
		return !this.list.find(({status}) =>
			[RoundStatus.Complete, RoundStatus.Playing].includes(status)
		);
	}

	get hasNoCompletedRound() {
		return isEmpty(this.completedRounds);
	}

	get activeRound() {
		return this.list.find((e) => e.status === RoundStatus.Playing);
	}

	get isMatchLive() {
		return Boolean(
			this.activeRound?.tournaments.find(({status}) => status === TournamentStatus.Playing)
		);
	}

	get nextScheduledTournament() {
		return first(this.getAllScheduledTournaments);
	}

	get isCurrentRoundScoring() {
		return Boolean(
			this.currentRound?.tournaments.find(({status}) => status !== TournamentStatus.Scheduled)
		);
	}

	@action
	async fetchRounds() {
		const {data} = await this._jsonProvider.rounds();

		runInAction(() => {
			this._list = data;
		});
	}

	getRoundStageByRoundNumber(roundNumber: number) {
		return roundNumber <= 5 ? RoundStage.Group : RoundStage.Knockout;
	}

	getRoundById(id: number | null) {
		if (!id) {
			return undefined;
		}
		return this.list.find((round) => round.id === id);
	}
}
