import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeagues, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {RequestState} from "data/enums";
import {isEmpty, isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import {useNavigate} from "react-router-dom";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IMyLeaguesController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get leagues(): ILeagues;
	get isLoading(): boolean;

	showRankUp: (league: ILeague) => boolean;
	showRankDown: (league: ILeague) => boolean;
	showRankNoChange: (league: ILeague) => boolean;

	loadMoreMyLeagues: () => void;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	@observable _requestState = RequestState.PENDING;
	private _navigate!: IProps["navigate"];

	get leagues() {
		return this._leaguesStore.myLeagues;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	loadMoreMyLeagues = () => {
		this._leaguesStore.fetchMoreMyLeagues().then(this.onSuccess).catch(this.onError);
	};

	showRankUp = (league: ILeague) => {
		if (!league.prevRank || !league.rank) {
			return false;
		}
		return league.prevRank > league.rank;
	};

	showRankDown = (league: ILeague) => {
		if (!league.prevRank || !league.rank) {
			return false;
		}
		return league.rank > league.prevRank;
	};

	showRankNoChange = (league: ILeague) => {
		if (!league.prevRank || !league.rank) {
			return false;
		}
		return league.rank === league.prevRank;
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
		if (isEmpty(this.leagues.leagues)) {
			this._navigate("/leagues/join");
		}
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		console.error(extractErrorMessage(error));
	};

	@action init(params: IProps) {
		this._navigate = params.navigate;
		this._leaguesStore.fetchMyLeagues().then(this.onSuccess).catch(this.onError);
	}
}
