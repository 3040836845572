import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {observable} from "mobx";
import {ModalType} from "data/enums";
import type {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface ILocalizationController extends ViewController {
	get i18n(): ILocalizationStore;
	onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onSearchLeagueForJoinChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class LocalizationController implements ILocalizationController {
	@observable searchTimer: ReturnType<typeof setTimeout> | undefined;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {}

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	public onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {name, value} = event.currentTarget;

		if (!name) {
			return;
		}

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._leaguesStore
				.fetchMyLeagues({
					search: value,
				})
				.catch(this.onError);
		}, 500);
	};

	public onSearchLeagueForJoinChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {name, value} = event.currentTarget;

		if (!name) {
			return;
		}

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._leaguesStore
				.fetchLeaguesForJoin({
					search: value,
				})
				.catch(this.onError);
		}, 500);
	};
}
