import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface IWebViewGateStore {
	setIsFromApp(flag: boolean): void;

	get isFromApp(): boolean;
}

@injectable()
export class WebViewGateStore implements IWebViewGateStore {
	@observable _isFromApp = false;

	constructor() {
		makeAutoObservable(this);
	}

	setIsFromApp(flag: boolean) {
		this._isFromApp = flag;
	}

	get isFromApp() {
		return this._isFromApp;
	}
}
