import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ISquadsStore, ISquad} from "data/stores/squads/squds.store";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {
	IPoolPlayer,
	IPlayerOpponentData,
	IPlayersStore,
} from "data/stores/players/players.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, TournamentStatus} from "data/enums";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {ITransfersStore} from "data/stores/transfers/transfers.store";
import {get, isEqual} from "lodash";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {MILLION} from "data/constants";

interface IModalData {
	player: IPoolPlayer;
}

interface IProps {
	isMobile: boolean;
}

export interface IPlayerCardController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get isTeamComplete(): boolean;
	get isTransferMode(): boolean;
	get selectedStatOption(): string;
	get isTransfersFull(): boolean;

	isInTrade: (player: IPoolPlayer) => boolean;
	addPlayerToPool: (player: IPoolPlayer) => void;
	removePlayerFromPool: (player: IPoolPlayer) => void;
	playerSquadById: (player: IPoolPlayer) => ISquad | undefined;
	openPlayerProfile: (player: IPoolPlayer) => void;
	selectUserForTransfer: (playerId: number) => void;
	transferOutPlayer: (playerId: number) => void;
	transferInPlayer: (playerId: number) => void;
	revertTrade: (playerId: number) => void;
	playerSelected: (player: IPoolPlayer) => number;
	playerOpponentData: (player: IPoolPlayer) => IPlayerOpponentData | undefined;
	playerTotalPoints: (player: IPoolPlayer) => number;
	playerRoundPoints: (player: IPoolPlayer) => number;
	checkIsPlayerPlayedInThisRound: (player: IPoolPlayer) => boolean;
	checkIsShowPlayerControllers: (player: IPoolPlayer) => boolean;
}

@injectable()
export class PlayerCardController implements IPlayerCardController {
	@observable private _isMobile: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	get isTeamComplete(): boolean {
		return this._teamStore.isComplete;
	}

	get player(): IPoolPlayer | undefined {
		if (!this.modalData?.player) {
			return;
		}
		return this.modalData?.player;
	}

	get isTransferMode(): boolean {
		return this._transfersStore.isTransferMode;
	}

	get selectedStatOption() {
		return this._teamBuilderStore.selectedStatPoolOption;
	}

	get isCurrentPositionFull() {
		const maxPrice = this._teamStore.budget / MILLION;
		const players = this._playersStore.getFilteredPlayers(
			this._teamBuilderStore.filters,
			maxPrice
		);
		return !players.find((player) => this._teamStore.canAddPlayerToTeam(player));
	}

	get isTransfersFull() {
		return !this._transfersStore.localTransfers.find((trade) => !trade.in);
	}

	get selectedRoundId() {
		return this._teamBuilderStore.selectedRoundId || this._roundsStore.currentRound?.id || 0;
	}

	isInTrade = (player: IPoolPlayer) => {
		return Boolean(
			this._transfersStore.localTransfers.find((trade) =>
				[trade.out, trade.in].includes(player?.id)
			)
		);
	};

	protected get modalData(): IModalData | null {
		return this._modalsStore.modalContent as IModalData;
	}

	public addPlayerToPool = (player: IPoolPlayer) => {
		this._teamStore.addPlayerInLineup(player);

		if (this.isCurrentPositionFull && this._isMobile) {
			this._teamBuilderStore.closePlayerPool();
		}
	};

	public removePlayerFromPool = (player: IPoolPlayer) => {
		this._teamStore.removePlayerFromPool(player);
		this._teamBuilderStore.selectedPlayerForBoost = null;
		this._teamBuilderStore.boosterForChoice = null;
	};

	init(param: IProps) {
		this._isMobile = param.isMobile;
	}

	playerSelected = (player: IPoolPlayer) => {
		if (!this._roundsStore.currentRound) {
			return 0;
		}
		return get(player.selected, this._roundsStore.currentRound.id, 0) || 0;
	};

	playerSquadById = (player: IPoolPlayer) => {
		return this._squadsStore.getSquadById(player?.squadId);
	};

	openPlayerProfile = (player: IPoolPlayer) => {
		this._modalsStore.showModal(ModalType.PLAYER_PROFILE, {player});
	};

	@action
	selectUserForTransfer = (playerId: number) => {
		this._transfersStore.transferOutPlayer(playerId);
	};

	@action
	transferOutPlayer = (playerId: number) => {
		this._transfersStore.transferOutPlayer(playerId);
	};

	@action
	transferInPlayer = (playerId: number) => {
		this._transfersStore.transferInPlayer(playerId);
		if (this.isTransfersFull && this._isMobile) {
			this._teamBuilderStore.closePlayerPool();
		}
	};

	@action
	revertTrade = (playerId: number) => {
		this._transfersStore.revertTrade(playerId);
	};

	playerOpponentData = (player: IPoolPlayer) => {
		const opponentsByRound = this._playersStore.getOpponents({
			tournaments: this._roundsStore.currentRound?.tournaments,
			squadId: player.squadId,
		});

		return opponentsByRound.find(
			({tournamentStatus}) =>
				isEqual(tournamentStatus, TournamentStatus.Scheduled) ||
				isEqual(tournamentStatus, TournamentStatus.Playing)
		);
	};

	playerTotalPoints = (player: IPoolPlayer) => {
		return get(player.stats, "totalPoints", 0) || 0;
	};

	playerRoundPoints = (player: IPoolPlayer) => {
		return get(player.stats, "lastRoundPoints", 0) || 0;
	};

	checkIsPlayerPlayedInThisRound = (player: IPoolPlayer) => {
		return Boolean(player?.isPlayerPlayedInThisRound);
	};

	checkIsShowPlayerControllers = (player: IPoolPlayer) => {
		return (
			Boolean(this.player?.addedInTeam && !this.checkIsPlayerPlayedInThisRound(player)) ||
			this.isInTrade(player) ||
			Boolean(player?.isAvailablePlayerCountry && player.isAvailablePlayerPosition)
		);
	};
}
