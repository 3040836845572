import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {SelectChangeEvent} from "@mui/material";
import {IRegistrationPayload} from "data/providers/api/user.api.provider";
import type {ICountriesStore, ICountry} from "data/stores/countries/countries.store";
import {ChangeEvent} from "react";

interface IController {
	ssoSupportingCountry: string | null;
}

export interface IModalRegisterController extends ViewController<IController> {
	i18n: ILocalizationStore;
	selectedTerms: boolean;
	selectedEmailNotifications: boolean;
	supportedCountry: string;

	get isOpen(): boolean;
	get countries(): ICountry[];
	get isDisabled(): boolean;
	get getIsLoading(): boolean;
	get userName(): string;
	set userName(userName: string);

	close: () => void;
	toggleSelectedTerms: () => void;
	toggleSelectedEmailNotifications: () => void;
	changeCountry: (e: SelectChangeEvent<unknown>) => void;
	sendRegisterData: (token: string) => void;
	updateUserName: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class ModalRegisterController implements IModalRegisterController {
	@observable _ssoSupportingCountry: string | null = null;
	@observable private _isLoading: boolean = false;

	public selectedTerms: boolean = false;
	public selectedEmailNotifications: boolean = true;
	public supportedCountry: string = "";
	private _userName: string = "";

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.CountriesStore) public _countriesStore: ICountriesStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.REGISTRATION && !this._userStore.isAuthorized;
	}

	get countries(): ICountry[] {
		return this._countriesStore.list;
	}

	get isNeedAddSupportingCountry() {
		return !this._ssoSupportingCountry || this._ssoSupportingCountry === "";
	}

	get isDisabled(): boolean {
		if (this.isNeedAddSupportingCountry) {
			return !this.selectedTerms || this.supportedCountry === "";
		}

		return !this.selectedTerms;
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get userName(): string {
		return this._userName;
	}

	set userName(userName: string) {
		this._userName = userName;
	}

	dispose(): void {
		return;
	}

	init(params: IController): void {
		this._ssoSupportingCountry = params.ssoSupportingCountry;
		this._countriesStore.fetchCountries().catch(this.onError);
	}

	onChange(param: IController): void {
		this._ssoSupportingCountry = param.ssoSupportingCountry;
	}

	public close = () => {
		this._modalsStore.hideModal();
	};

	public toggleSelectedTerms = () => {
		this.selectedTerms = !this.selectedTerms;
	};

	public toggleSelectedEmailNotifications = () => {
		this.selectedEmailNotifications = !this.selectedEmailNotifications;
	};

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
		this._isLoading = false;
	};

	public changeCountry = (e: SelectChangeEvent<unknown>) => {
		this.supportedCountry = e.target.value as string;
	};

	public sendRegisterData = (token: string) => {
		this._isLoading = true;
		const data = {
			token,
			terms: true,
			isNotificationsEnabled: this.selectedEmailNotifications,
		} as IRegistrationPayload;

		if (this.isNeedAddSupportingCountry) {
			data.supportedCountry = this.supportedCountry;
			// data.username = this.userName;
		}

		void this._userStore
			.register(data)
			.catch(this.onError)
			.finally(() => {
				this._isLoading = false;
			});
	};

	public updateUserName = (e: ChangeEvent<HTMLInputElement>) => {
		const {value} = e.target;
		this._userName = value;
	};
}
