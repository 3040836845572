import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import {useNavigate} from "react-router-dom";
import {LeaguePrivacy} from "data/enums";

interface IProps {
	leagueId: number;
	navigate: ReturnType<typeof useNavigate>;
}

export interface ILeagueInviteController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
}

@injectable()
export class LeagueInviteController implements ILeagueInviteController {
	@observable private _leagueId?: number;

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action onChange({leagueId}: IProps) {
		this._leagueId = leagueId;
	}

	@action init({leagueId, navigate}: IProps) {
		this._leagueId = leagueId;
		const isCommissioner = this.league?.leagueManager?.userId === this._userStore.user?.id;

		if (!isCommissioner && this.league?.privacy !== LeaguePrivacy.PUBLIC) {
			navigate(`/league/${this.league?.id || ""}/about`);
		}
	}
}
