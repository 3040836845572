import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {BoosterTypes, ModalType} from "data/enums";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ISelectOption} from "data/types/types";
import {SelectChangeEvent} from "@mui/material";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {IRoundsStore, IRound} from "data/stores/rounds/rounds.store";
import type {ITransfersStore} from "data/stores/transfers/transfers.store";
import {sumBy, get} from "lodash";
import type {IStatsPlayersStore} from "data/stores/stats_players/stats_players.store";
import type {IGameBar} from "data/providers/api/game_bar.provider";
import type {IGameBarStore} from "data/stores/game_bar/game_bar.store";
import Cookies from "js-cookie";

interface IProps {
	isMobile: boolean;
}

export interface IGameBarController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get budget(): number;
	get teamCreationBudgetNegativeErrorMessage(): string | null;
	get isTeamFull(): boolean;
	get countPlayersInTeam(): number;
	get playersInTeam(): number;
	get mustBePlayerInTeam(): number;
	get userName(): string | undefined;
	get userSupportedCountryFlag(): string;
	get isTeamChanged(): boolean;
	get playerPriceSum(): number;
	get statsOptions(): ISelectOption[];
	get selectedStatOption(): string;
	get isSelectedBoosterForChoice(): boolean;
	get isTeamComplete(): boolean;
	get gameBar(): IGameBar | undefined;
	get isTransferMode(): boolean;
	get isHideGameBar(): boolean;
	get rounds(): IRound[];
	get selectedRoundId(): number;
	get isShowHistoryTeam(): boolean;
	get roundPoints(): number;
	get isMatchLive(): boolean;
	get showPointsMessage(): boolean;
	get showFullGameBar(): boolean;
	get isHideMessageOnMobile(): boolean;

	updateStatToShow: (e: SelectChangeEvent) => void;
	changeSelectedRoundId: (e: SelectChangeEvent) => void;
	onAutofill: () => void;
	onClearTeam: () => void;
	onTeamUpdate: () => void;
	startTransfers: () => void;
	closePointsMessage: () => void;
	toggleGameBar: () => void;
}

@injectable()
export class GameBarController implements IGameBarController {
	@observable private _isMobile: boolean = false;
	@observable private _showPointsMessage: boolean = true;
	@observable private _showFullGameBar: boolean = false;
	@observable private _isMatchLive = false;
	@observable _liveMatchDisposer?: ReturnType<typeof reaction>;
	private _isNewTeam: boolean = false;

	get budget() {
		return this._transfersStore.isTransferMode
			? this._transfersStore.budget
			: this._teamStore.budget;
	}

	get teamCreationBudgetNegativeErrorMessage() {
		return !this._transfersStore.isTransferMode && this._teamStore.isBudgetNegative
			? this.i18n.t(
					"team.creation.over_budget",
					"You're over your budget so your team cannot be saved."
			  )
			: null;
	}

	get countPlayersInTeam() {
		return this._teamStore.lineupPlayers.length || 0;
	}

	get mustBePlayerInTeam() {
		return this._teamStore.lineupIDs.length;
	}

	get isTeamFull() {
		return this.countPlayersInTeam === this.mustBePlayerInTeam;
	}

	get playersInTeam() {
		return this._teamStore.lineupIDs.length;
	}

	get userName() {
		return this._userStore.user?.username;
	}

	get userSupportedCountryFlag() {
		return this._userStore.userSupportedCountryFlag;
	}

	get isTeamChanged() {
		return this._teamStore.isTeamChanged;
	}

	get playerPriceSum() {
		return this._teamStore.playerPriceSum;
	}

	get statsOptions() {
		return this._teamBuilderStore.statsOptions;
	}

	get selectedStatOption() {
		return this._teamBuilderStore.selectedStatOption;
	}

	get isSelectedBoosterForChoice() {
		return Boolean(this._teamBuilderStore.boosterForChoice);
	}

	get isTeamComplete() {
		return this._teamStore.isComplete;
	}

	get gameBar() {
		return this._gameBarStore.gameBar;
	}

	get isTransferMode() {
		return this._transfersStore.isTransferMode;
	}

	get isHideGameBar() {
		return this.isSelectedBoosterForChoice || this.isTransferMode;
	}

	get rounds() {
		return this._roundsStore.completeAndCurrentRounds.filter(({id}) => {
			if (!this._teamStore.team.startRoundId) {
				return true;
			}
			if (this._roundsStore.currentRound?.id === id) {
				return true;
			}
			return this._teamStore.team.startRoundId <= id;
		});
	}

	get selectedRoundId() {
		return this._teamBuilderStore.selectedRoundId || this._roundsStore.currentRound?.id || 0;
	}

	get isShowHistoryTeam() {
		return this._teamStore.isShowHistoryTeam;
	}

	get roundPoints() {
		if (!this.isMatchLive) {
			return this._gameBarStore.gameBar?.roundPoints || 0;
		}

		const players = this._teamStore.lineupPlayers;

		return sumBy(players, (player) => {
			const isCapitan = this._teamStore.getIsPlayerCapitan(player);
			const playerPoints = get(player, `stats.scores.${this.selectedRoundId}`, 0);
			const booster = this.playerBooster(player.id);
			const isPlayerBoosted = Boolean(booster);

			if (isPlayerBoosted && booster?.type !== BoosterTypes.TRIPLE_CAPTAIN) {
				return (
					playerPoints +
					(booster?.type === BoosterTypes.DEFENSIVE_KING
						? this._statsPlayersStore.getDefensiveKing(this.selectedRoundId)
						: this._statsPlayersStore.getSuperKickerBoostedPoints(this.selectedRoundId))
				);
			}

			if (isCapitan) {
				return this.currentBooster?.type === BoosterTypes.TRIPLE_CAPTAIN
					? playerPoints * 3
					: playerPoints * 2;
			}

			return playerPoints;
		});
	}

	get isMatchLive() {
		return (
			this._roundsStore.activeRound?.id === this.selectedRoundId &&
			this._roundsStore.isMatchLive
		);
	}

	get currentBooster() {
		return this._teamStore.team.boosters.find(
			(booster) => booster.roundId === this.selectedRoundId
		);
	}

	get showPointsMessage() {
		const hasSeenMessage = Cookies.get("points_message") === "true";
		return !hasSeenMessage && this._showPointsMessage && this.isMatchLive;
	}

	get showFullGameBar() {
		return this._showFullGameBar;
	}

	get isHideMessageOnMobile() {
		return this._teamBuilderStore.isPlayerPoolVisible && this._isMobile;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.GameBarStore) private readonly _gameBarStore: IGameBarStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore,
		@inject(Bindings.StatsPlayersStore) private _statsPlayersStore: IStatsPlayersStore
	) {
		makeAutoObservable(this);
	}

	playerBooster = (playerId: number) => {
		return this._teamStore.team.boosters.find(
			(booster) => booster.roundId === this.selectedRoundId && booster.playerId === playerId
		);
	};

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	private onSuccess = () => {
		if (this._isNewTeam) {
			this._modalsStore.showModal(ModalType.SAVING_YOUR_TEAM);
		}
	};

	private clearBoostersData = () => {
		this._teamBuilderStore.selectedPlayerForBoost = null;
		this._teamBuilderStore.boosterForChoice = null;
	};

	@action onAutofill = () => {
		this._teamStore.autofillTeam().catch(this.onError);
	};

	@action onClearTeam = () => {
		this._modalsStore.showModal(ModalType.CLEAR_TEAM_CONFIRMATION);
	};

	@action onTeamUpdate = () => {
		if (this._isMobile) {
			this._modalsStore.showModal(ModalType.REVIEW_TEAM);
		} else {
			this._isNewTeam = !this._teamStore.team.isComplete;
			this._teamStore.updateTeam().then(this.onSuccess).catch(this.onError);
		}
	};

	@action updateStatToShow = (e: SelectChangeEvent) => {
		const {value} = e.target;
		this._teamBuilderStore.selectedStatOption = value;
	};

	@action changeSelectedRoundId = (e: SelectChangeEvent) => {
		const {value} = e.target;
		const newRoundId = parseInt(value, 0);
		this._teamBuilderStore.selectedRoundId = newRoundId;
		const currentRound = this._roundsStore.currentRound;
		this.clearBoostersData();
		this._transfersStore.setTransferMode(false);

		if (newRoundId === currentRound?.id) {
			this._teamStore.fetchTeam().catch(this.onError);
		} else {
			this._teamStore.fetchHistoricalTeam(newRoundId).catch(this.onError);
		}
		this._gameBarStore.fetchGameBar(newRoundId).catch(this.onError);
	};

	@action init(param: IProps) {
		this._isMobile = param.isMobile;
		reaction(
			() => this._roundsStore.isMatchLive,
			(isLive) => {
				if (this._isMatchLive && !isLive) {
					this._gameBarStore
						.fetchGameBar(this._teamBuilderStore.selectedRoundId as number)
						.catch(this.onError);
				}
				this._isMatchLive = isLive;
			}
		);
	}

	dispose() {
		this._liveMatchDisposer?.();
	}

	@action onChange(param: IProps) {
		this._isMobile = param.isMobile;
		this.clearBoostersData();
	}

	@action startTransfers = () => {
		this._transfersStore.setTransferMode(true);
	};

	@action closePointsMessage = () => {
		if (this._roundsStore.nextScheduledTournament) {
			const expires = new Date(this._roundsStore.nextScheduledTournament.date);
			Cookies.set("points_message", "true", {expires});
		}
		this._showPointsMessage = false;
	};

	@action toggleGameBar = () => {
		this._showFullGameBar = !this._showFullGameBar;
	};
}
