import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ISquad, ISquadsStore} from "data/stores/squads/squds.store";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {SelectChangeEvent} from "@mui/material";
import {ChangeEvent} from "react";
import {ISelectOption} from "data/types/types";
import {orderBy} from "lodash";
import {StatsCentreView, UserStatuses} from "data/enums";
import {
	type IStatsCentreFilters,
	type IStatsCentreStore,
	ITableHeadAndValue,
} from "data/stores/stats_centre/stats_centre.store";

interface IProps {
	isMobile: boolean;
}

export interface IStatsCentreFiltersController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get filters(): IStatsCentreFilters;
	get isFiltersChanged(): boolean;
	get squads(): ISquad[];
	get positionsArray(): ISelectOption[];
	get expanded(): boolean;
	get statusesPlayerOptions(): ISelectOption[];
	get enabledFilters(): number;
	get viewType(): StatsCentreView;
	get statsColumns(): ITableHeadAndValue[];

	updateFilter: (e: SelectChangeEvent | SelectChangeEvent<string[]>) => void;
	updateSearch: (e: ChangeEvent<HTMLInputElement>) => void;
	resetSearch: () => void;
	setPosition: (e: SelectChangeEvent | SelectChangeEvent<string[]>) => void;
	toggleFilters: () => void;
	closeFilters: () => void;
	openFilters: () => void;
	resetFilters: () => void;
	updateViewType: (e: SelectChangeEvent) => void;
	setStatsViewType: () => void;
	setOverviewViewType: () => void;
}

@injectable()
export class StatsCentreFiltersController implements IStatsCentreFiltersController {
	private _statusesPlayerOptions = [
		{val: UserStatuses.All, label: "pool.status.all"},
		{val: UserStatuses.Starting, label: "pitch.key.available"},
		{val: UserStatuses.NotSelected, label: "pitch.key.not_selected"},
		{val: UserStatuses.Bench, label: "pitch.key.bench"},
		{val: UserStatuses.Injured, label: "pool.status.injured"},
	];

	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.StatsCentreStore) private _statsCentreStore: IStatsCentreStore
	) {
		makeAutoObservable(this);
	}

	get squads(): ISquad[] {
		return orderBy(this._squadsStore.list, ["name"], ["asc"]);
	}

	get filters(): IStatsCentreFilters {
		return this._statsCentreStore.filters;
	}

	get viewType() {
		return this._statsCentreStore.viewType;
	}

	get isFiltersChanged() {
		return this._statsCentreStore.isFiltersChanged;
	}

	get positionsArray(): ISelectOption[] {
		return this._teamStore.positionsArray;
	}

	get statusesPlayerOptions() {
		return this._statusesPlayerOptions;
	}

	get expanded(): boolean {
		return this._statsCentreStore.isFiltersVisible;
	}

	get enabledFilters() {
		let quantity = 1;

		if (this.filters.search !== "") {
			quantity++;
		}
		if (this.filters.squad !== "null") {
			quantity++;
		}
		if (this.filters.status !== "All") {
			quantity++;
		}
		if (!this.filters.position.find((item) => item === "null")) {
			quantity++;
		}

		return quantity;
	}

	get statsColumns() {
		return this._statsCentreStore.statsColumns;
	}

	public updateFilter = (e: SelectChangeEvent | SelectChangeEvent<string[]>) => {
		this._statsCentreStore.updateFilter(e);
	};

	public updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
		this._statsCentreStore.updateSearch(e);
	};

	public resetSearch = () => {
		this._statsCentreStore.resetSearch();
	};

	public setPosition = (e: SelectChangeEvent | SelectChangeEvent<string[]>) => {
		const value = e.target.value;

		if (value.length < 1) {
			return;
		}

		this._statsCentreStore.updateFilter(e);
	};

	public toggleFilters = (): void => {
		this._statsCentreStore.toggleFilters();
	};

	public closeFilters = () => {
		this._statsCentreStore.closeFilters();
	};

	public openFilters = () => {
		this._statsCentreStore.openFilters();
	};

	public resetFilters = () => {
		this._statsCentreStore.resetFilters();
	};

	public updateViewType = (e: SelectChangeEvent) => {
		const {value} = e.target;
		this._statsCentreStore.viewType = value as StatsCentreView;
		// this._statsCentreStore.updateSort(
		// 	value === StatsCentreView.Overview
		// 		? StatsCentreSort.TotalPoints
		// 		: StatsCentreSort.DefaultOverview
		// );
	};

	public setStatsViewType = () => {
		this._statsCentreStore.viewType = StatsCentreView.Stats;
		// this._statsCentreStore.updateSort(StatsCentreSort.TotalPoints);
	};

	public setOverviewViewType = () => {
		this._statsCentreStore.viewType = StatsCentreView.Overview;
		// this._statsCentreStore.updateSort(StatsCentreSort.DefaultOverview);
	};
}
