import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable} from "mobx";
import {BoosterTypes, PlayerPosition, SortOrder, UserStats, UserStatuses} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type ITeamApiProvider} from "data/providers/api/team.api.provider";
import {type IPoolFilters} from "data/stores/players/players.store";
import {ChangeEvent} from "react";
import {SelectChangeEvent} from "@mui/material";
import {get} from "lodash";
import type {ISelectOption} from "data/types/types";
import {parseSingleValueFromArray} from "data/utils/helpers";

const defaultFilters: IPoolFilters = {
	search: "",
	position: ["null"],
	squad: "null",
	price: "null",
	sort: "price",
	status: UserStatuses.All,
};

export interface ITeamBuilderStore {
	get filters(): IPoolFilters;
	get isFiltersChanged(): boolean;
	get statsOptions(): ISelectOption[];
	get statusesPlayerOptions(): ISelectOption[];
	get statsOptionsPreseason(): ISelectOption[];
	get selectedStatLabel(): string;
	get orderPrice(): SortOrder;
	get orderStatPercent(): SortOrder;
	get isPlayerPoolVisible(): boolean;
	get isPoolFiltersVisible(): boolean;
	get selectedStatOption(): string;
	set selectedStatOption(optionName: string);
	get selectedStatPoolOption(): string;
	set selectedStatPoolOption(optionName: string);
	get selectedPlayerStatus(): string;
	set selectedPlayerStatus(optionName: UserStatuses);
	get boosterForChoice(): BoosterTypes | null;
	set boosterForChoice(type: BoosterTypes | null);
	get selectedPlayerForBoost(): number | null;
	set selectedPlayerForBoost(playerId: number | null);
	get isStartTransfers(): boolean;
	set isStartTransfers(isStarted: boolean);
	get selectedRoundId(): number | null;
	set selectedRoundId(id: number | null);

	setSelectedFieldPosition: (position: PlayerPosition) => void;
	resetPosition: () => void;
	setPosition: (position: PlayerPosition) => void;
	setPositionWithoutOpenPlayerPool: (position: PlayerPosition) => void;
	updateFilter: (e: SelectChangeEvent | SelectChangeEvent<string[]>) => void;
	updateSearch: (e: ChangeEvent<HTMLInputElement>) => void;
	sortPlayersStat: () => void;
	sortPlayersPrice: () => void;
	resetFilters: () => void;
	resetSearch: () => void;
	openPlayerPool: () => void;
	closePlayerPool: () => void;
	toggleFilters: () => void;
	openFilters: () => void;
	closeFilters: () => void;
	openTwelfthManPlayerPool: () => void;
}

@injectable()
export class TeamBuilderStore implements ITeamBuilderStore {
	@observable private _selectedFieldPosition: null | PlayerPosition = null;
	@observable private _boosterForChoice: BoosterTypes | null = null;
	@observable private _selectedPlayerForBoost: number | null = null;
	@observable private _isStartTransfers: boolean = false;
	@observable private _selectedRoundId: number | null = null;

	private _statsOptionsPreseason = [
		{val: UserStats.Price, label: "stats.dropdown.price"},
		{val: UserStats.NextFixture, label: "stats.dropdown.next_fixture"},
		{val: UserStats.PercentSelected, label: "stats.dropdown.selected"},
	];
	@observable private _isPoolFiltersVisible = true;
	@observable private _isPlayerPoolVisible: boolean = false;
	@observable private _orderPrice: SortOrder = SortOrder.DESC;
	@observable private _orderStat: SortOrder = SortOrder.DESC;
	@observable private _filters: IPoolFilters = {
		...defaultFilters,
	};
	@observable _selectedStatLabel: string = "Price";
	@observable private _selectedStatOption = UserStats.Price;
	@observable private _selectedStatPoolOption = UserStats.PercentSelected;

	private _statusesPlayerOptions = [
		{val: UserStatuses.All, label: "pool.status.all"},
		{val: UserStatuses.Starting, label: "pitch.key.available"},
		{val: UserStatuses.NotSelected, label: "pitch.key.not_selected"},
		{val: UserStatuses.Bench, label: "pitch.key.bench"},
		{val: UserStatuses.Injured, label: "pool.status.injured"},
	];

	private _statsOptions = [
		{val: UserStats.Price, label: "stats.dropdown.price"},
		{val: UserStats.NextFixture, label: "stats.dropdown.next_fixture"},
		{val: UserStats.RoundPoints, label: "stats.dropdown.round_points"},
		{val: UserStats.TotalPoints, label: "stats.dropdown.total_points"},
		{val: UserStats.PercentSelected, label: "stats.dropdown.selected"},
	];

	constructor(@inject(Bindings.TeamApiProvider) private _teamApiProvider: ITeamApiProvider) {
		makeAutoObservable(this);
	}

	get isPoolFiltersVisible(): boolean {
		return this._isPoolFiltersVisible;
	}

	get orderPrice() {
		return this._orderPrice;
	}

	get orderStatPercent() {
		return this._orderStat;
	}

	get filters(): IPoolFilters {
		return this._filters;
	}

	@computed
	get isFiltersChanged() {
		return JSON.stringify(this._filters) !== JSON.stringify(defaultFilters);
	}

	get selectedStatLabel(): string {
		return this._selectedStatLabel;
	}

	get statsOptions() {
		return this._statsOptions;
	}

	get statusesPlayerOptions() {
		return this._statusesPlayerOptions;
	}

	get statsOptionsPreseason() {
		return this._statsOptionsPreseason;
	}

	get selectedStatOption() {
		return this._selectedStatOption;
	}

	set selectedStatOption(optionName: UserStats) {
		this._selectedStatOption = optionName;
	}

	get selectedStatPoolOption() {
		return this._selectedStatPoolOption;
	}

	set selectedStatPoolOption(optionName: UserStats) {
		this._selectedStatPoolOption = optionName;
	}

	get selectedPlayerStatus() {
		return this._filters.status;
	}

	set selectedPlayerStatus(optionName: UserStatuses) {
		this._filters.status = optionName;
	}

	get isPlayerPoolVisible(): boolean {
		return this._isPlayerPoolVisible;
	}

	get boosterForChoice(): BoosterTypes | null {
		return this._boosterForChoice;
	}

	set boosterForChoice(type: BoosterTypes | null) {
		this._boosterForChoice = type;
	}

	get selectedPlayerForBoost(): number | null {
		return this._selectedPlayerForBoost;
	}

	set selectedPlayerForBoost(playerId: number | null) {
		this._selectedPlayerForBoost = playerId;
	}

	get isStartTransfers(): boolean {
		return this._isStartTransfers;
	}

	set isStartTransfers(isStarted: boolean) {
		this._isStartTransfers = isStarted;
	}

	get selectedRoundId() {
		return this._selectedRoundId;
	}
	set selectedRoundId(id: number | null) {
		this._selectedRoundId = id;
	}

	@action
	setSelectedFieldPosition = (position: PlayerPosition) => {
		this.resetFilters();
		this._filters = {
			...this._filters,
			position: [position],
		};
	};

	@action
	sortPlayersPrice = () => {
		this._orderPrice = this._orderPrice === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		this._filters = {
			...this._filters,
			sort: "price",
		};
	};

	@action
	sortPlayersStat = () => {
		this._orderStat = this._orderStat === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		this._filters = {
			...this._filters,
			sort: "stat",
		};
	};

	@action
	resetFilters = () => {
		this._filters = {
			...defaultFilters,
		};
		this._orderPrice = SortOrder.DESC;
		this._selectedStatLabel = "Price";
	};

	@action
	resetSearch = () => {
		this._filters.search = "";
	};

	@action
	resetPosition = () => {
		this._filters = {
			...this._filters,
			position: [],
		};
	};

	@action
	setPosition = (position: PlayerPosition) => {
		this.openPlayerPool();

		this._filters = {
			...this._filters,
			position: [position],
		};
	};

	@action
	setPositionWithoutOpenPlayerPool = (position: PlayerPosition) => {
		this._filters = {
			...this._filters,
			position: [position],
		};
	};

	@action
	updateFilter = (e: SelectChangeEvent | SelectChangeEvent<string[]>) => {
		const {value, name} = e.target;
		const newValue = parseSingleValueFromArray(value, "null");

		this._filters = {
			...this._filters,
			[name]: newValue,
		};

		if (name === "stat") {
			this._selectedStatLabel = get(
				this._statsOptions.find(({val}) => val === value),
				"label",
				""
			);
			this._orderPrice = SortOrder.DESC;
		}
	};

	updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const {value, name} = e.target;

		this._filters = {
			...this._filters,
			[name]: value,
		};
	};

	@action
	public openPlayerPool = () => {
		this._isPlayerPoolVisible = true;
		window.document.body.classList.add("overflow-hidden-mobile");
	};

	@action
	public closePlayerPool = () => {
		this._isPlayerPoolVisible = false;
		window.document.body.classList.remove("overflow-hidden-mobile");
	};

	@action
	public openTwelfthManPlayerPool = () => {
		this._isPlayerPoolVisible = true;
		this._isPoolFiltersVisible = false;
	};

	@action
	public toggleFilters(): void {
		this._isPoolFiltersVisible = !this._isPoolFiltersVisible;
	}

	@action
	public openFilters(): void {
		this._isPoolFiltersVisible = true;
	}

	@action
	public closeFilters(): void {
		this._isPoolFiltersVisible = false;
	}
}
