import React from "react";
import {observer} from "mobx-react";
import {Modal, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {PureButton, ButtonBase} from "views/components/buttons/buttons.component";
import {ModalContent} from "views/components/modals/common";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeIcon.svg";
import logo from "assets/img/logoBadge.svg";
import type {IModalPoolsCompleteController} from "./modal_pools_complete.controller";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
`;

const Title = styled.h6`
	color: #19194b;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
`;

const Description = styled.div`
	color: #19194b;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	strong {
		font-weight: 700;
	}
`;

const ButtonBaseStyled = styled(ButtonBase)`
	max-width: 300px;
	margin: 20px auto 0 auto;
`;

export const ModalPoolsComplete: React.FC = observer(() => {
	const {i18n, close, isOpen} = useViewController<IModalPoolsCompleteController>(
		Bindings.ModalPoolsCompleteController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<PureButton onClick={close}>
						<CloseIcon />
					</PureButton>
				</CloseBlock>
				<Icon>
					<img src={logo} alt={"World Cup France 2023"} />
				</Icon>

				<Title>{i18n.t("modal.pools_complete.header")}</Title>
				<Description
					dangerouslySetInnerHTML={{__html: i18n.t("modal.pools_complete.copy_one")}}
				/>
				{/*<Description*/}
				{/*	dangerouslySetInnerHTML={{__html: i18n.t("modal.pools_complete.copy_two")}}*/}
				{/*/>*/}
				{/*<Description*/}
				{/*	dangerouslySetInnerHTML={{__html: i18n.t("modal.pools_complete.copy_three")}}*/}
				{/*/>*/}
				{/*<Description*/}
				{/*	dangerouslySetInnerHTML={{__html: i18n.t("modal.pools_complete.copy_four")}}*/}
				{/*/>*/}
				<Stack sx={{width: "100%", flexDirection: {xs: "column", sm: "row"}}} gap={2.5}>
					<ButtonBaseStyled onClick={close} className="primary">
						{i18n.t("boosters.confirmed.view_team", "VIEW TEAM")}
					</ButtonBaseStyled>
				</Stack>
			</ModalContent>
		</Modal>
	);
});
