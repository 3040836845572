import {action, computed, makeAutoObservable, observable, runInAction, toJS} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ITransfersApiProvider} from "data/providers/api/transfers.api.provider";
import {IPlayer, type IPlayersStore} from "data/stores/players/players.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {chain, indexOf, isEqual, values} from "lodash";
import {type ILineup, type ITeamStore} from "data/stores/team/team.store";
import {hasEmptySpot} from "data/utils/lineup";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {checkRoundState} from "data/utils/helpers";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export type ITransfer = {
	out: number;
	in?: number;
};

export interface ITransfersStore {
	get transferLineup(): ILineup;
	get isTransferMode(): boolean;
	get localTransfers(): ITransfer[];
	get transferTotalBudget(): number;
	get budget(): number;
	get isBudgetNegative(): boolean;
	get lineupPlayersIDs(): number[];
	get transferLineupWithoutUnpairedTradePlayers(): ILineup;
	get isTeamChanged(): boolean;

	setTransferMode(isTransferMode: boolean): void;
	transferOutPlayer(playerID: number): void;
	transferInPlayer(playerID: number): void;
	resetLocalTransfers(): void;
	isPlayerCanBeTradeIn(player: IPlayer): boolean;
	makeTransfers(): Promise<void>;
	revertTrade(playerID: number): void;
	getIsPlayerDisabledInCurrentRound: (player: IPlayer) => boolean;
	removeAllPlayers(): void;
}

@injectable()
export class TransfersStore implements ITransfersStore {
	@observable private _isTransferMode = false;
	@observable private _localTransfers: ITransfer[] = [];

	get isTeamChanged() {
		return this._isTransferMode ? !!this._localTransfers.length : false;
	}

	@computed get transferLineupWithoutUnpairedTradePlayers() {
		try {
			const tmpLineup = toJS(this._teamStore.lineup);

			this.localTransfers.forEach((trade) => {
				this.removePlayerFromLineup(tmpLineup, trade.out);

				if (trade.in) {
					this.addPlayerToLineup(tmpLineup, trade.in);
				}
			});

			return observable(tmpLineup);
		} catch (_err) {
			/**
			 * Reset trades to the initial state, in case failed formation error
			 */
			this._localTransfers = [];
			return observable(toJS(this._teamStore.lineup));
		}
	}

	@computed get transferLineup() {
		try {
			const tmpLineup = toJS(this.transferLineupWithoutUnpairedTradePlayers);

			// /**
			//  * Add back to the team players who have no trade pairs, i.e. wasn't traded yet
			//  */
			// this.localTransfers
			// 	.filter((trade) => trade.out && !trade.in)
			// 	.forEach((trade) => this.addPlayerToLineup(tmpLineup, trade.out));

			return observable(tmpLineup);
		} catch (_err) {
			return observable(toJS(this._teamStore.lineup));
		}
	}

	get lineupIDs(): number[] {
		return chain(this.transferLineup).values().flatten().value();
	}

	get lineupPlayers(): IPlayer[] {
		const lineupIds = this.lineupIDs;
		return lineupIds
			.map((id) => this._playersStore.getPlayerById(id))
			.filter((player) => player !== undefined) as IPlayer[];
	}

	@computed get lineupPlayersIDs() {
		return values(this.transferLineup).flatMap((arr) => arr);
	}

	get isTransferMode() {
		return this._isTransferMode;
	}

	get localTransfers() {
		return this._localTransfers;
	}

	@computed
	get playerPriceSum() {
		return this._playersStore.getLineupPrice(this.lineupIDs);
	}

	@computed
	get budget() {
		return this._teamStore.salaryCap - this.playerPriceSum;
	}

	get isBudgetNegative() {
		return this.budget < 0;
	}

	/*
	 * An entrant’s team can contain a maximum of TWO players from any one nation. However, this will increase during the Knockout Stage.
	 * Quarter Finals: Four Players
	 * Semi-Finals: Five Players
	 * Final & Bronze Final: Six Players
	 * */
	getIsPlayerCountryAvailable = (newPlayer: IPlayer) => {
		const playersInTeam = this.lineupPlayers;
		const activeRound = this._roundsStore.currentRound;
		let maxCountriesSelect = 3;

		if (activeRound?.number && checkRoundState.isQuarterFinals(activeRound.number)) {
			maxCountriesSelect = 4;
		}
		if (activeRound?.number && checkRoundState.isSemiFinals(activeRound.number)) {
			maxCountriesSelect = 5;
		}
		if (activeRound?.number && checkRoundState.isFinalAndBronzeFinal(activeRound.number)) {
			maxCountriesSelect = 6;
		}

		const foundPlayersFromTheSameCountry = playersInTeam.filter(
			(player) => player.squadId === newPlayer.squadId
		);
		return foundPlayersFromTheSameCountry.length !== maxCountriesSelect;
	};

	@computed get transferTotalBudget() {
		return this.localTransfers.reduce((budget, transfer) => {
			budget += this._playersStore.getPlayerById(transfer.out)?.cost ?? 0;
			budget -= this._playersStore.getPlayerById(transfer.in ?? 0)?.cost ?? 0;

			return budget;
		}, 0);
	}

	constructor(
		@inject(Bindings.TransfersApiProvider) private _transfersApiProvider: ITransfersApiProvider,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action setTransferMode(isTransferMode: boolean) {
		this._isTransferMode = isTransferMode;
	}

	@action transferOutPlayer(playerID: number) {
		this._localTransfers.push({out: playerID});
	}

	@action transferInPlayer(playerID: number) {
		const playerIn = this._playersStore.getPlayerById(playerID);

		const trade =
			this._localTransfers.find((transfer) => {
				const playerOut = this._playersStore.getPlayerById(transfer.out);
				/**
				 * Try to find swap trade with the same positions
				 */
				return isEqual(playerIn?.position, playerOut?.position) && !transfer.in;
			}) ||
			this._localTransfers.find((transfer) => {
				return !transfer.in;
			});

		if (trade) {
			trade.in = playerID;
		}
	}

	@action resetLocalTransfers() {
		this._localTransfers = [];
	}

	private removePlayerFromLineup(lineup: ILineup, playerID: number) {
		const player = this._playersStore.getPlayerById(playerID);

		if (!player) return;

		const line = lineup[player.position[0]];
		line[indexOf(line, playerID)] = 0;
	}

	private addPlayerToLineup(lineup: ILineup, playerID: number) {
		const player = this._playersStore.getPlayerById(playerID);

		if (!player) return;

		const {position} = player;

		if (hasEmptySpot(lineup, position)) {
			const line = lineup[position[0]];
			line[indexOf(line, 0)] = playerID;
		} else {
			throw new Error("Can't find available spot to set a player");
		}
	}

	getIsPlayerDisabledInCurrentRound = (newPlayer: IPlayer) => {
		if (this._teamStore.isShowHistoryTeam) {
			return true;
		}

		return newPlayer.isLocked;
		// const currentRound = this._roundsStore.currentRound;
		//
		// return Boolean(
		// 	currentRound?.tournaments.find(
		// 		(tournament) =>
		// 			tournament.status !== TournamentStatus.Scheduled &&
		// 			(newPlayer.squadId === tournament.awaySquadId ||
		// 				newPlayer.squadId === tournament.homeSquadId)
		// 	)
		// );
	};

	isPlayerCanBeTradeIn(player: IPlayer) {
		const {position} = player;

		if (
			!this.getIsPlayerCountryAvailable(player) ||
			this.getIsPlayerDisabledInCurrentRound(player)
		) {
			return false;
		}

		return hasEmptySpot(this.transferLineupWithoutUnpairedTradePlayers, position);
	}

	@action async makeTransfers() {
		const response = await this._transfersApiProvider
			.make({
				tradePairs: this._localTransfers,
			})
			.finally(() => {
				this._modalsStore.showModal(ModalType.TRANSFERS, {
					localTransfers: this._localTransfers,
				});
			});

		runInAction(() => {
			this._localTransfers = [];
			this._isTransferMode = false;
			this._teamStore.team = response.data.success.team;
		});
	}

	@action revertTrade(playerID: number) {
		this._localTransfers = this._localTransfers.filter(
			(trade) => ![trade.in, trade.out].includes(playerID)
		);
	}

	@action removeAllPlayers(): void {
		if (!this.isTransferMode) {
			return;
		}

		this._localTransfers = chain(this._teamStore.lineupIDs)
			.filter((id) => {
				const player = this._playersStore.getPlayerById(id);
				return Boolean(player && !this.getIsPlayerDisabledInCurrentRound(player));
			})
			.map((id) => ({out: id}))
			.value();
	}
}
