import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import Keycloak from "keycloak-js";

export interface IModalSaveTeamController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	close: () => void;
}

@injectable()
export class ModalSaveTeamController implements IModalSaveTeamController {
	@observable _keycloak: Keycloak | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.SAVING_YOUR_TEAM;
	}

	dispose(): void {
		return;
	}

	public close = () => {
		this._modalsStore.hideModal();
	};
}
