import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import Keycloak from "keycloak-js";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {ITeamStore} from "data/stores/team/team.store";

export interface IModalReviewTeamController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	close: () => void;
	onTeamSave: () => void;
}

@injectable()
export class ModalReviewTeamController implements IModalReviewTeamController {
	@observable _keycloak: Keycloak | null = null;
	private _isNewTeam: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.REVIEW_TEAM;
	}

	dispose(): void {
		return;
	}

	public close = () => {
		this._modalsStore.hideModal();
	};

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	private onSuccess = () => {
		if (this._isNewTeam) {
			this._modalsStore.showModal(ModalType.SAVING_YOUR_TEAM);
		}
	};

	@action onTeamSave = () => {
		this._isNewTeam = !this._teamStore.team.isComplete;
		this._teamStore.updateTeam().then(this.onSuccess).catch(this.onError);
	};
}
