import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {Modal} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeIcon.svg";
import {CloseButton, ModalCloseBlock, ModalContent} from "views/components/modals/common";
import {Exist} from "views/components/exist/exist.component";
import {ButtonBase, PureButton} from "views/components/buttons/buttons.component";
import logo from "assets/img/logo.png";
import step1 from "assets/img/tutorial/RWCTutorialStep7-Part2.gif";
import step2 from "assets/img/tutorial/RWCTutorialStep8-Part2.gif";
import step3 from "assets/img/tutorial/RWCTutorialStep10-Part2.gif";
import step4 from "assets/img/tutorial/RWCTutorialStep9-Part2.gif";
import step5 from "assets/img/tutorial/RWCTutorialStep12-Part2.gif";
import step6 from "assets/img/tutorial/RWCTutorialStep11-Part2.gif";
import {range} from "lodash";
import {ReactComponent as PreviousArrow} from "assets/img/icons/previousArrow.svg";
import {ReactComponent as NextArrow} from "assets/img/icons/nextArrow.svg";
import {IModalTutorialPositionController} from "views/components/modals/modal_tutorial_position/modal_tutorial_position.controller";

const Icon = styled.div`
	width: 88px;
	margin-top: -10px;

	img {
		width: 100%;
	}
`;

const Title = styled.h4`
	color: #19194b;
	text-align: center;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
`;

const Description = styled.p`
	color: #19194b;
	text-align: center;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 16px;
	line-height: 20px;
`;

const Buttons = styled.div`
	display: flex;
	gap: 12px;
	align-self: stretch;

	button {
		width: 100%;
	}

	@media screen and (max-width: 640px) {
		flex-direction: column;
	}
`;

const StepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
	gap: 12px;
	min-height: 520px;

	@media screen and (max-width: 640px) {
		min-height: 550px;
	}
`;

const ImageWrapper = styled.div`
	height: 380px;

	img {
		max-width: 100%;
		height: 100%;
	}
`;

const IndicatorsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
	margin-top: auto;
`;

const IndicatorsInner = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
`;

const Step = styled.div`
	width: 8px;
	height: 8px;
	background: #d2d2d2;
	border-radius: 50%;
`;

const NavigateButton = styled(PureButton)<{isHidden?: boolean}>`
	visibility: ${({isHidden}) => isHidden && "hidden"};

	svg {
		vertical-align: middle;
	}
`;

const StepIndicator = styled.div`
	// Step width (9px) + gap (11px)
	width: calc(8px * 2 + 8px);
	height: 8px;
	background: #2d3cff;
	border-radius: 5px;
	position: absolute;
	left: 0;
	top: 0;
	transition: 0.25s;
`;

interface IProps {
	step: number;
	totalSteps: number;
	previous: () => void;
	next: () => void;
}

const Indicators: React.FC<IProps> = observer(({step, totalSteps, previous, next}) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) {
			// Step width (8px), gap (8px)
			const offsetLeft = (step - 1) * 16;
			ref.current.style.left = `${offsetLeft}px`;
		}
	}, [step]);

	return (
		<IndicatorsWrapper>
			<NavigateButton isHidden={step < 2} onClick={previous}>
				<PreviousArrow />
			</NavigateButton>
			<IndicatorsInner>
				<StepIndicator ref={ref} />
				{range(totalSteps + 1).map((stepIndex) => (
					<Step key={stepIndex} />
				))}
			</IndicatorsInner>
			<NavigateButton onClick={next}>
				<NextArrow />
			</NavigateButton>
		</IndicatorsWrapper>
	);
});

interface IStepProps {
	title: string;
	description: string;
	image: string;
}

export const TutorialStep: React.FC<IStepProps> = ({title, description, image}) => {
	return (
		<StepWrapper>
			<ImageWrapper>
				<img src={image} alt={title} />
			</ImageWrapper>
			<Title>{title}</Title>
			<Description>{description}</Description>
		</StepWrapper>
	);
};

export const ModalTutorialPosition: React.FC = observer(() => {
	const {
		isOpen,
		i18n,
		step,
		totalSteps,
		showIndicators,
		buttonText,
		close,
		previous,
		next,
		reset,
	} = useViewController<IModalTutorialPositionController>(
		Bindings.ModalTutorialPositionController
	);
	return (
		<Modal open={isOpen}>
			<ModalContent>
				<ModalCloseBlock>
					<CloseButton onClick={close}>
						<CloseIcon />
					</CloseButton>
				</ModalCloseBlock>

				<Exist when={step === 0}>
					<Icon>
						<img src={logo} alt={"World Cup France 2023"} />
					</Icon>
					<Title>{i18n.t("tutorial.part_two.header", "Get Started")}</Title>
					<Description>
						{i18n.t(
							"tutorial.part_two.copy_one",
							"Build your team, create leagues and climb up the overall rankings. Hit ‘Learn More’ to find out about positions"
						)}
					</Description>
					<Description>
						{i18n.t(
							"tutorial.part_two.copy_two",
							"Build your team, create leagues and climb up the overall rankings. Hit ‘Learn More’ to find out about positions"
						)}
					</Description>
					<Buttons>
						<ButtonBase onClick={close} className="secondary">
							{buttonText}
						</ButtonBase>
						<ButtonBase onClick={next} className="primary">
							{i18n.t("tutorial.part_two.learn_more", "LEARN MORE")}
						</ButtonBase>
					</Buttons>
				</Exist>

				<Exist when={step === 1}>
					<TutorialStep
						title={i18n.t("tutorial.part_two.positions_header", "Positions Explained")}
						description={i18n.t(
							"tutorial.part_two.positions_copy",
							"Rugby positions require all different sets of physical and technical attributes with each position responsible for key actions."
						)}
						image={step1}
					/>
				</Exist>

				<Exist when={step === 2}>
					<TutorialStep
						title={i18n.t("tutorial.part_two.forwards_header", "Forwards")}
						description={i18n.t(
							"tutorial.part_two.forwards_copy",
							"These 8 Players are your Forwards. They are the workhorses of your team and generally score points for defensive actions like making tackles."
						)}
						image={step2}
					/>
				</Exist>

				<Exist when={step === 3}>
					<TutorialStep
						title={i18n.t("tutorial.part_two.fly_half_header", "Fly Half")}
						description={i18n.t(
							"tutorial.part_two.fly_half_copy",
							"This is one of your key players. Most Fly Halves are great kickers and will score your team lots of points for try conversions, penalties etc. Choose wisely!"
						)}
						image={step3}
					/>
				</Exist>

				<Exist when={step === 4}>
					<TutorialStep
						title={i18n.t("tutorial.part_two.scrum_half_header", "Scrum Half")}
						description={i18n.t(
							"tutorial.part_two.scrum_half_copy",
							"The scrum-half is the heartbeat of a rugby union team. These guys will score you points through offloads & try assists."
						)}
						image={step4}
					/>
				</Exist>

				<Exist when={step === 5}>
					<TutorialStep
						title={i18n.t("tutorial.part_two.centres_header", "Centres")}
						description={i18n.t(
							"tutorial.part_two.centres_copy",
							"Your centres can play both inside & outside roles. Scoring you points through line breaks & metres gained."
						)}
						image={step5}
					/>
				</Exist>

				<Exist when={step === 6}>
					<TutorialStep
						title={i18n.t("tutorial.part_two.winger_header", "Wingers")}
						description={i18n.t(
							"tutorial.part_two.winger_copy",
							"The wingers are usually among the fastest players in a team and will be scoring a ton of fantasy points through tries!"
						)}
						image={step6}
					/>
				</Exist>

				<Exist when={step === 7}>
					<Icon>
						<img src={logo} alt={"World Cup France 2023"} />
					</Icon>
					<Title>{i18n.t("tutorial.part_two.final_header", "That’s All")}</Title>
					<Description>
						{i18n.t(
							"tutorial.part_two.final_copy",
							"You’re all set. Build your team, create leagues and climb up the overall rankings for your chance to win <prize name>."
						)}
					</Description>
					<Buttons>
						<ButtonBase onClick={close} background="#19194B">
							{buttonText}
						</ButtonBase>
						<ButtonBase onClick={reset}>
							{i18n.t("tutorial.part_two.restart", "RE-START TUTORIAL")}
						</ButtonBase>
					</Buttons>
				</Exist>

				<Exist when={showIndicators}>
					<Indicators
						previous={previous}
						next={next}
						step={step}
						totalSteps={totalSteps}
					/>
				</Exist>
			</ModalContent>
		</Modal>
	);
});
