import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {
	ILeaderboardApiProvider,
	ILeaderboardPayload,
	ILeaderboardLeaguePayload,
} from "data/providers/api/leaderboard.api.provider";
import {RoundStatus, StatFilter} from "data/enums";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {get} from "lodash";

export interface IRanking {
	userId: number | null;
	userName: string;
	leagueId: number | null;
	roundId: number | null;
	rank: number | null;
	points: number | null;
	overallRank: number;
	overallPoints: number;
	averagePoints: number;
	roundPoints: number;
}

export interface ILeaderboard {
	rankings: IRanking[];
	user: IRanking | null;
	nextPage: boolean;
	page: number;
}

export interface ILeaderboardStore {
	get leaderboard(): ILeaderboard;
	get selectedStat(): StatFilter;
	get selectedRound(): string;
	get viewTeamRoundID(): number;
	get canViewTeam(): boolean;
	fetchRankings(params?: ILeaderboardPayload): Promise<void>;
	fetchLeagueRankings(params: ILeaderboardLeaguePayload): Promise<void>;
	setSelectedStat(stat: StatFilter): void;
	setSelectedRound(stat: string): void;
	loadMoreLeaderboard(params?: ILeaderboardPayload): void;
	loadMoreLeagueLeaderboard(params: ILeaderboardLeaguePayload): void;
}

@injectable()
export class LeaderboardStore implements ILeaderboardStore {
	@observable private _leaderboard: ILeaderboard = {
		rankings: [],
		user: null,
		nextPage: false,
		page: 0,
	};
	@observable private _selectedStat: StatFilter = StatFilter.TotalPoints;
	@observable private _selectedRound: string = "all";

	get leaderboard() {
		return this._leaderboard;
	}

	get selectedStat() {
		return this._selectedStat;
	}

	get selectedRound() {
		return this._selectedRound;
	}

	get viewTeamRoundID() {
		const selectedRound = Number(this.selectedRound);
		if (!isNaN(selectedRound)) {
			return selectedRound;
		}
		return get(this._roundsStore.scoreRound, "id", 0);
	}

	get canViewTeam() {
		const round = this._roundsStore.getRoundById(this.viewTeamRoundID);
		// return round?.status === RoundStatus.Complete || round?.status === RoundStatus.Playing;
		return round?.status === RoundStatus.Complete;
	}

	constructor(
		@inject(Bindings.LeaderboardApiProvider)
		private _leaderboardApiProvider: ILeaderboardApiProvider,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action setSelectedStat = (stat: StatFilter) => {
		this._selectedStat = stat;
	};

	@action setSelectedRound = (round: string) => {
		this._selectedRound = round;
	};

	@action async fetchRankings(params: ILeaderboardPayload = {}): Promise<void> {
		const response = await this._leaderboardApiProvider.fetchRankings(params);

		runInAction(() => {
			this._leaderboard = {
				...response.data.success,
				page: 1,
			};
		});
	}

	@action async loadMoreLeaderboard(params: ILeaderboardPayload = {}): Promise<void> {
		const page = this._leaderboard.page + 1;

		const response = await this._leaderboardApiProvider.fetchRankings({
			...params,
			page,
		});

		runInAction(() => {
			this._leaderboard = {
				...response.data.success,
				rankings: this._leaderboard.rankings.concat(response.data.success.rankings),
				page,
			};
		});
	}

	@action async fetchLeagueRankings(params: ILeaderboardLeaguePayload): Promise<void> {
		const response = await this._leaderboardApiProvider.fetchLeagueRankings(params);

		runInAction(() => {
			this._leaderboard = {
				...response.data.success,
				page: 1,
			};
		});
	}

	@action async loadMoreLeagueLeaderboard(params: ILeaderboardLeaguePayload): Promise<void> {
		const page = this._leaderboard.page + 1;

		const response = await this._leaderboardApiProvider.fetchLeagueRankings({
			...params,
			page,
		});

		runInAction(() => {
			this._leaderboard = {
				...response.data.success,
				rankings: this._leaderboard.rankings.concat(response.data.success.rankings),
				page,
			};
		});
	}
}
