import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IUserStore} from "data/stores/user/user.store";
import {type IWebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";
import {type ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";

export interface IHeaderController extends ViewController {
	get i18n(): ILocalizationStore;
	get isAuthorized(): boolean;
	get isFromApp(): boolean;
	get isPlayerPoolVisible(): boolean;
}

@injectable()
export class HeaderController implements IHeaderController {
	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.WebViewGateStore) private _webViewGameStore: IWebViewGateStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get isFromApp() {
		return this._webViewGameStore.isFromApp;
	}

	get isPlayerPoolVisible() {
		return this._teamBuilderStore.isPlayerPoolVisible;
	}
}
