import React, {Fragment} from "react";
import {SubNavigationMenu} from "views/components/sub_navigation_menu/sub_navigation_menu";
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router";
import {useViewController} from "data/services/locator";
import {IMenuItemsController} from "views/components/menu_items/menu_items.controller";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";
import {Exist} from "views/components/exist/exist.component";
import {useKeycloak} from "@react-keycloak/web";

export const DesktopMenu: React.FC = observer(() => {
	const {keycloak} = useKeycloak();
	const {isLeaguesPage, isAuthorized, i18n, openTutorialModal} =
		useViewController<IMenuItemsController>(Bindings.MenuItemsController, {
			location: useLocation(),
			keycloak,
		});

	return (
		<SubNavigationMenu>
			<Fragment>
				<li>
					<NavLink to="/my-team">{i18n.t("navigation.my_team", "My Team")}</NavLink>
				</li>
				<Exist when={isAuthorized}>
					<Fragment>
						<li>
							<NavLink
								to="/leagues"
								className={({isActive}) =>
									isActive || isLeaguesPage ? "active" : ""
								}>
								{i18n.t("navigation.my_leagues", "My Leagues")}
							</NavLink>
						</li>
						<li>
							<NavLink to="/leaderboard">
								{i18n.t("navigation.leaderboard", "Leaderboard")}
							</NavLink>
						</li>
						<li>
							<NavLink to="/stats-centre">
								{i18n.t("navigation.stats_centre", "Stats Centre")}
							</NavLink>
						</li>
					</Fragment>
				</Exist>

				<li style={{display: "none"}}>
					<NavLink to="/prizes">{i18n.t("navigation.prize", "Prizes")}</NavLink>
				</li>

				<li>
					<NavLink to="/matches">{i18n.t("navigation.matches.title", "Matches")}</NavLink>
				</li>

				<li>
					<NavLink to="/help">{i18n.t("navigation.help", "Help")}</NavLink>
				</li>
				<li className="last">
					<button onClick={openTutorialModal}>
						{i18n.t("navigation.tutorial", "Tutorial")}
					</button>
				</li>
			</Fragment>
		</SubNavigationMenu>
	);
});
