import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPlayer, IPlayersStore, IPoolPlayer} from "data/stores/players/players.store";
import type {ITeamStore, IBooster} from "data/stores/team/team.store";
import {BoosterTypes, ModalType, PlayerPosition, RoundStatus, TournamentStatus} from "data/enums";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {get} from "lodash";
import type {ISquad, ISquadsStore} from "data/stores/squads/squds.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITransfersStore} from "data/stores/transfers/transfers.store";
import type {ILeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import type {IStatsPlayersStore} from "data/stores/stats_players/stats_players.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";

interface IController {
	key: number;
	position: PlayerPosition;
}

export interface ILineupPlayerController extends ViewController<IController> {
	get player(): IPoolPlayer | undefined;
	get playerSquad(): ISquad | undefined;
	get playerOpponentData(): ISquad | undefined;
	get selectedStatOption(): string;
	get isTripleCaptain(): boolean;
	get boosterForChoice(): BoosterTypes | null;
	get selectedPlayerForBoost(): number | null;
	get isTeamFull(): boolean;
	get playerBooster(): IBooster | undefined;
	get playerBoosterByTeam(): IBooster | undefined;
	get tradeStarted(): boolean;
	get showPlayerStatus(): boolean;
	get selected(): number;
	get isBoosted(): boolean;
	get selectedRoundId(): number;
	get teamByUserPlayer(): IPlayer | undefined;
	get teamByUserPlayerSquad(): ISquad | undefined;
	get isTeamByUserPlayerCaptain(): boolean;
	get teamByUserRoundId(): number;
	get playerRoundPoints(): number;
	get playerRoundPointsForLeaderboard(): number;
	get showRoundPoints(): boolean;

	isInTrade: (player: IPoolPlayer | undefined) => boolean;
	setPosition: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	openPlayerProfile: (player: IPlayer) => void;
	selectUserForBoost: (userId: number | undefined) => void;
	selectPlayerForTransfer: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	revertTrade: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	handleClick: () => void;
	fetchPlayerStats: () => void;
	fetchPlayerStatsByUserTeam: () => void;
}

@injectable()
export class LineupPlayerController implements ILineupPlayerController {
	private _keyInLineup: number | undefined;
	private _playerPosition: PlayerPosition | undefined;
	private _showPlayerStatus: boolean = true;

	get showPlayerStatus() {
		return this._showPlayerStatus;
	}

	get player(): IPoolPlayer | undefined {
		const playerId = this._teamStore.getPlayerByLineupKeyAndPosition(
			this._keyInLineup as number,
			this._playerPosition as PlayerPosition,
			this._transfersStore.isTransferMode,
			this._transfersStore.transferLineup
		);
		const player = playerId ? this._playersStore.getPlayerById(playerId) : undefined;

		return this.calculatePlayerStatuses(player);
	}

	get playerByUserTeam(): IPoolPlayer | undefined {
		const playerId = this._teamStore.getPlayerByLineupKeyAndPositionByUserTeam(
			this._keyInLineup as number,
			this._playerPosition as PlayerPosition
		);
		const player = playerId ? this._playersStore.getPlayerById(playerId) : undefined;

		return player
			? {
					...player,
					isInTeam: true,
					displayPrice: "",
					canAddToTeam: true,
					addedInTeam: true,
					isCapitan: player?.id === this._teamStore.teamByUser.captainId,
			  }
			: undefined;
	}

	get playerSquad(): ISquad | undefined {
		return this._squadsStore.getSquadById(this.player?.squadId);
	}

	get teamByUserPlayer(): IPlayer | undefined {
		const playerId = this._teamStore.getTeamByUserPlayerByLineupKeyAndPosition(
			this._keyInLineup as number,
			this._playerPosition as PlayerPosition
		);
		return playerId ? this._playersStore.getPlayerById(playerId) : undefined;
	}

	get teamByUserPlayerSquad(): ISquad | undefined {
		return this._squadsStore.getSquadById(this.teamByUserPlayer?.squadId);
	}

	get isTeamByUserPlayerCaptain() {
		return this._teamStore.teamByUser.captainId === this.teamByUserPlayer?.id;
	}

	get teamByUserRoundId() {
		const selectedRound = Number(this._leaderboardStore.selectedRound);
		if (!isNaN(selectedRound)) {
			return selectedRound;
		}
		return get(this._roundsStore.scoreRound, "id", 0);
	}

	get playerOpponentData(): ISquad | undefined {
		const nextTournament = this._roundsStore.getAllScheduledTournaments.find(
			(tournament) => tournament.id === this.player?.stats?.nextFixture
		);
		if (nextTournament) {
			const playerSquad =
				nextTournament.homeSquadId !== this.player?.squadId
					? nextTournament.homeSquadId
					: nextTournament.awaySquadId;
			return this._squadsStore.getSquadById(playerSquad);
		}
		return undefined;
	}

	get selectedStatOption() {
		return this._teamBuilderStore.selectedStatOption;
	}

	get isTripleCaptain() {
		const actualRoundId = this.selectedRoundId;
		return Boolean(
			this._teamStore.team.boosters.find(
				(booster) =>
					booster.roundId === actualRoundId &&
					booster.type === BoosterTypes.TRIPLE_CAPTAIN
			)
		);
	}

	get boosterForChoice(): BoosterTypes | null {
		return this._teamBuilderStore.boosterForChoice;
	}

	get selectedPlayerForBoost() {
		return this._teamBuilderStore.selectedPlayerForBoost;
	}

	get isTeamFull() {
		return Boolean(this._teamStore.lineupIDs);
	}

	get playerBooster() {
		const actualRoundId = this.selectedRoundId;
		return this._teamStore.team.boosters.find(
			(booster) => booster.roundId === actualRoundId && booster.playerId === this.player?.id
		);
	}

	get playerBoosterByTeam() {
		return this._teamStore.teamByUser.boosters.find(
			(booster) =>
				booster.roundId === this.teamByUserRoundId &&
				(booster.playerId === this.playerByUserTeam?.id ||
					(booster.type === BoosterTypes.TRIPLE_CAPTAIN &&
						this.playerByUserTeam?.isCapitan))
		);
	}

	get tradeStarted() {
		return this._transfersStore.isTransferMode;
	}

	get selected() {
		if (!this._roundsStore.currentRound) {
			return 0;
		}
		return get(this.player?.selected, this._roundsStore.currentRound.id, 0);
	}

	get isBoosted() {
		return this.selectedPlayerForBoost === this.player?.id || Boolean(this.playerBooster);
	}

	get selectedRoundId() {
		return this._teamBuilderStore.selectedRoundId || this._roundsStore.currentRound?.id || 0;
	}

	get playerRoundPoints() {
		const points = get(this.player, `stats.scores[${this.selectedRoundId}]`, 0);
		return this.calculatePlayerPoints(points);
	}

	get playerRoundPointsForLeaderboard() {
		const points = get(this.playerByUserTeam, `stats.scores[${this.teamByUserRoundId}]`, 0);

		if (
			this.playerBoosterByTeam &&
			this.playerBoosterByTeam?.type !== BoosterTypes.TRIPLE_CAPTAIN
		) {
			return this.getBoostedPlayerPointsForLeaderboard(points);
		}

		if (this.playerByUserTeam?.isCapitan) {
			return this.playerBoosterByTeam?.type === BoosterTypes.TRIPLE_CAPTAIN
				? points * 3
				: points * 2;
		}

		return points;
	}

	get showRoundPoints() {
		const currentRound = this._roundsStore.getRoundById(this.selectedRoundId);
		if (currentRound?.status === RoundStatus.Complete) {
			return true;
		}
		return Boolean(this.player?.isPlayerPlayedInThisRound);
	}

	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore,
		@inject(Bindings.LeaderboardStore) private _leaderboardStore: ILeaderboardStore,
		@inject(Bindings.StatsPlayersStore) private _statsPlayersStore: IStatsPlayersStore
	) {
		makeAutoObservable(this);
	}

	init(params: IController) {
		this._keyInLineup = params.key;
		this._playerPosition = params.position;
	}

	private calculatePlayerStatuses(player: IPlayer | undefined) {
		const currentRound = this._roundsStore.getRoundById(this.selectedRoundId);
		return player
			? {
					...player,
					isInTeam: true,
					displayPrice: this._playersStore.getPlayerDisplayPrice(player.cost),
					canAddToTeam: this._teamStore.canAddPlayerToTeam(player),
					addedInTeam: this._teamStore.getIsPlayerInTeam(player),
					isCapitan: this._teamStore.getIsPlayerCapitan(player),
					isPlayerPlayedInThisRound:
						this._transfersStore.getIsPlayerDisabledInCurrentRound(player),
					isPlaying: Boolean(
						currentRound?.tournaments.find(
							(tournament) =>
								tournament.status === TournamentStatus.Playing &&
								(player.squadId === tournament.awaySquadId ||
									player.squadId === tournament.homeSquadId)
						)
					),
			  }
			: undefined;
	}

	private calculatePlayerPoints(points: number) {
		if (this.isBoosted && this.playerBooster?.type !== BoosterTypes.TRIPLE_CAPTAIN) {
			return this.getBoostedPlayerPoints(points);
		}

		if (this.player?.isCapitan) {
			return this.isTripleCaptain ? points * 3 : points * 2;
		}

		return points;
	}

	private getBoostedPlayerPoints(points: number) {
		return (
			points +
			(this.playerBooster?.type === BoosterTypes.DEFENSIVE_KING
				? this._statsPlayersStore.getDefensiveKing(this.selectedRoundId)
				: this._statsPlayersStore.getSuperKickerBoostedPoints(this.selectedRoundId))
		);
	}

	private getBoostedPlayerPointsForLeaderboard(points: number) {
		return (
			points +
			(this.playerBoosterByTeam?.type === BoosterTypes.DEFENSIVE_KING
				? this._statsPlayersStore.getDefensiveKing(this.teamByUserRoundId)
				: this._statsPlayersStore.getSuperKickerBoostedPoints(this.teamByUserRoundId))
		);
	}

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	isInTrade = (player: IPoolPlayer | undefined) => {
		return Boolean(
			this._transfersStore.localTransfers.find((trade) =>
				[trade.out, trade.in].includes(player?.id)
			)
		);
	};

	@action
	setPosition = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const position = e.currentTarget.value as PlayerPosition;
		this._teamBuilderStore.setPosition(position);
	};

	@action
	openPlayerProfile = (player: IPlayer) => {
		if (!player) {
			return;
		}
		this._modalsStore.showModal(ModalType.PLAYER_PROFILE, {player});
	};

	@action
	selectUserForBoost = (userId: number | undefined) => {
		if (userId) {
			this._teamBuilderStore.selectedPlayerForBoost = userId;
		}
	};

	@action
	selectPlayerForTransfer = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (this.playerBooster) {
			this._modalsStore.showModal(ModalType.TRANSFER_BOOSTER, {player: this.player});
			return;
		}
		if (this.player && this._playerPosition) {
			this._transfersStore.transferOutPlayer(this.player.id);
			this._teamBuilderStore.setPositionWithoutOpenPlayerPool(this._playerPosition);
		}
	};

	@action
	revertTrade = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (this.player) {
			this._transfersStore.revertTrade(this.player.id);
		}
	};

	@action
	handleClick = () => {
		if (!this.player) {
			return;
		}
		if (this.boosterForChoice && !this.player.isCapitan) {
			this.selectUserForBoost(this.player.id);
			return;
		}
		if (this.boosterForChoice && this.player.isCapitan) {
			this._modalsStore.showModal(ModalType.APPLY_NOT_SUITABLE_BOOSTER_TO_CAPTAIN);
			return;
		}
		this.openPlayerProfile(this.player);
	};

	@action
	fetchPlayerStats = () => {
		if (this.player) {
			this._statsPlayersStore
				.fetchPlayerTournamentStatsTeam(this.player.id)
				.catch(this.onError);
		}
	};

	@action
	fetchPlayerStatsByUserTeam = () => {
		if (this.playerByUserTeam) {
			this._statsPlayersStore
				.fetchPlayerTournamentStatsTeam(this.playerByUserTeam.id)
				.catch(this.onError);
		}
	};
}
