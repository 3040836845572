import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {LeagueStatus, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import {useNavigate} from "react-router-dom";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

interface IProps {
	leagueId: number;
	navigate: ReturnType<typeof useNavigate>;
}

export interface ILeagueAboutController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get roundNumber(): number | null;
	get isLoading(): boolean;
	get isLeagueStarted(): boolean;

	leaveLeague: () => void;
	joinLeague: () => void;
}

@injectable()
export class LeagueAboutController implements ILeagueAboutController {
	@observable private _leagueId?: number;
	@observable private _leagueDisposer?: ReturnType<typeof reaction>;
	@observable private _requestState = RequestState.IDLE;
	private _navigate!: IProps["navigate"];

	private get leagueID() {
		return this._leagueId!;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	@computed
	get roundNumber() {
		return this.league?.roundStartNumber || null;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		console.error(extractErrorMessage(error));
	};

	@action leaveLeague = () => {
		this._requestState = RequestState.PENDING;

		this._leaguesStore
			.leaveLeague({leagueId: this.leagueID})
			.then(this.onSuccess)
			.catch(this.onError)
			.finally(() => {
				this._navigate(`/leagues`);
			});
	};

	@action joinLeague = () => {
		const code = this.league?.code;

		if (!code) return;

		this._requestState = RequestState.PENDING;

		this._leaguesStore.joinToLeague({code}).then(this.onSuccess).catch(this.onError);
	};

	@action init({leagueId, navigate}: IProps) {
		this._navigate = navigate;
		this._leagueId = leagueId;
	}
}
