import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {find, filter, sum} from "lodash";
import {IPlayer} from "data/stores/players/players.store";

export interface IPlayerStat {
	tries: number;
	assists: number;
	conversions: number;
	penalties: number;
	dropGoals: number;
	tackles: number;
	linebreaks: number;
	linebreakAssists: number;
	turnovers: number;
	interceptions: number;
	defendersBeaten: number;
	lineoutsWon: number;
	metresGained: number;
	gamesPlayed: number;
	penaltiesConceded: number;
	errors: number;
	yellowCards: number;
	redCards: number;
	offloads: number;
	scrumsWon: number;
}

export interface IPlayerStats {
	[key: string]: IPlayerStat;
}

export interface IPlayerWithStats extends IPlayer {
	matchStats: IPlayerStat;
}

export interface IScoringMetrics {
	minutes: number;
	tries: number;
	assists: number;
	conversions: number;
	conversion_miss: number;
	penalties: number;
	penalty_miss: number;
	drop_goal: number;
	drop_goal_miss: number;
	yellow_card: number;
	red_card: number;
	turnover_forced: number;
	interception: number;
	lineout_won: number;
	lineout_steal: number;
	lineout_error: number;
	tackles: number;
	missed_tackles: number;
	defenders_beaten: number;
	offloads: number;
	linebreak: number;
	linebreak_assist: number;
	carries: number;
	penalty_conceded: number;
	error: number;
	scrums: number;
}

export interface IPlayerTournamentStats {
	tournamentId: number;
	roundId: number;
	points: number;
	avg: number;
	stats: {
		MP: number;
		C: number;
		CM: number;
		PGM: number;
		YC: number;
		RC: number;
		TC: number;
		O: number;
		PC: number;
		DGM: number;
		LS: number;
		LE: number;
		MT: number;
		E: number;
		T: number;
		TA: number;
		CG: number;
		PG: number;
		DG: number;
		TK: number;
		LB: number;
		LC: number;
		TW: number;
		I: number;
		TB: number;
		LT: number;
		MG: number;
		SW: number;
	};
	scoringMetrics?: IScoringMetrics;
}

export interface IStatsPlayersStore {
	get playerTournamentStats(): IPlayerTournamentStats[];
	get allPlayersStats(): IPlayerStats;
	fetchPlayerTournamentStats(playerId: number): Promise<void>;
	fetchPlayerTournamentStatsTeam(playerId: number): Promise<void>;
	fetchPlayersStats(): Promise<void>;
	getPlayerStatsByPlayerId(playerId: number | undefined): IPlayerStat | undefined;
	getPlayerTournamentStatsByTournamentId(
		tournamentId: number | undefined
	): IPlayerTournamentStats | undefined;
	getSuperKickerBoostedPoints(roundId: number): number;
	getDefensiveKing(roundId: number): number;
}

@injectable()
export class StatsPlayersStore implements IStatsPlayersStore {
	@observable private _playerStats: IPlayerStats = {};
	@observable private _playerTournamentStats: IPlayerTournamentStats[] = [];
	@observable private _playerTournamentStatsTeam: IPlayerTournamentStats[] = [];

	get playerTournamentStats() {
		return this._playerTournamentStats;
	}

	get allPlayersStats() {
		return this._playerStats;
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@action
	async fetchPlayerTournamentStats(playerId: number) {
		const {data} = await this._jsonProvider.player_tournament_stats(playerId);

		runInAction(() => {
			this._playerTournamentStats = data;
		});
	}

	@action
	async fetchPlayerTournamentStatsTeam(playerId: number) {
		const {data} = await this._jsonProvider.player_tournament_stats(playerId);

		runInAction(() => {
			this._playerTournamentStatsTeam = data;
		});
	}

	@action
	async fetchPlayersStats() {
		const {data} = await this._jsonProvider.player_stats();

		runInAction(() => {
			this._playerStats = data;
		});
	}

	getPlayerStatsByPlayerId(playerId: number | undefined) {
		return find(this._playerStats, (stats, id) => parseInt(id, 0) === playerId);
	}

	getScoringMetrics({stats}: IPlayerTournamentStats) {
		return {
			minutes: stats.MP > 61 ? 2 : stats.MP > 1 ? 1 : 0,
			tries: stats.T * 15,
			assists: stats.TA * 9,
			conversions: stats.C * 2,
			conversion_miss: stats.CM * -1,
			penalties: stats.PG * 3,
			penalty_miss: stats.PGM * -1,
			drop_goal: stats.DG * 3,
			drop_goal_miss: stats.DGM * -1,
			yellow_card: stats.YC * -5,
			red_card: stats.RC * -10,
			turnover_forced: stats.TW * 4,
			interception: stats.I * 5,
			lineout_won: stats.LT,
			lineout_steal: stats.LS * 5,
			lineout_error: stats.LE * -2,
			tackles: stats.TK,
			missed_tackles: stats.MT * -1,
			defenders_beaten: stats.TB * 2,
			offloads: stats.O * 2,
			linebreak: stats.LB * 7,
			linebreak_assist: stats.LC * 5,
			carries: Math.floor(stats.MG / 10),
			penalty_conceded: stats.PC * -1,
			error: stats.E * -1,
			scrums: stats.SW * 3,
		};
	}

	getPlayerTournamentStatsByTournamentId(tournamentId: number | undefined) {
		const stats = find(
			this._playerTournamentStats,
			(stats) => stats.tournamentId === tournamentId
		);

		if (stats) {
			return {
				...stats,
				scoringMetrics: this.getScoringMetrics(stats),
			};
		}

		return undefined;
	}

	getSuperKickerBoostedPoints(roundId: number) {
		let points = 0;
		const roundTournaments = filter(
			this._playerTournamentStatsTeam,
			(stats) => stats.roundId === roundId
		);
		roundTournaments.forEach((stats) => {
			const {
				conversions,
				conversion_miss,
				penalties,
				penalty_miss,
				drop_goal,
				drop_goal_miss,
			} = this.getScoringMetrics(stats);

			const sumStats = sum([
				conversions,
				conversion_miss,
				penalties,
				penalty_miss,
				drop_goal,
				drop_goal_miss,
			]);

			if (sumStats) {
				points = points + sumStats;
			}
		});

		return points;
	}

	getDefensiveKing(roundId: number) {
		let points = 0;
		const roundTournaments = filter(
			this._playerTournamentStatsTeam,
			(stats) => stats.roundId === roundId
		);
		roundTournaments.forEach((stats) => {
			const {turnover_forced, interception, tackles, missed_tackles} =
				this.getScoringMetrics(stats);
			const sumStats = sum([turnover_forced, interception, tackles, missed_tackles]);

			if (sumStats) {
				points += points + sumStats;
			}
		});

		return points;
	}
}
