import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {Modal} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeIcon.svg";
import {CloseButton, ModalCloseBlock, ModalContent} from "views/components/modals/common";
import {IModalTutorialController} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {Exist} from "views/components/exist/exist.component";
import {ButtonBase, PureButton} from "views/components/buttons/buttons.component";
import logo from "assets/img/logo.png";
import step1 from "assets/img/tutorial/RWCTutorial1-Desktop.gif";
import step2 from "assets/img/tutorial/RWCTutorial2-Desktop.gif";
import step3 from "assets/img/tutorial/RWCTutorial3-Desktop.gif";
import step4 from "assets/img/tutorial/RWCTutorial4-Desktop.gif";
import step5 from "assets/img/tutorial/RWCTutorial5-Desktop.gif";
import step6 from "assets/img/tutorial/RWCTutorial6-Desktop.gif";
import step1Mobile from "assets/img/tutorial/RWCTutorialStep1-MWeb.gif";
import step2Mobile from "assets/img/tutorial/RWCTutorialStep2-MWeb.gif";
import step3Mobile from "assets/img/tutorial/RWCTutorialStep3-MWeb.gif";
import step4Mobile from "assets/img/tutorial/RWCTutorialStep4-MWeb.gif";
import step5Mobile from "assets/img/tutorial/RWCTutorialStep5-MWeb.gif";
import step6Mobile from "assets/img/tutorial/RWCTutorialStep6-MWeb.gif";
import {range} from "lodash";
import {ReactComponent as PreviousArrow} from "assets/img/icons/previousArrow.svg";
import {ReactComponent as NextArrow} from "assets/img/icons/nextArrow.svg";

const StyledModalContent = styled(ModalContent)`
	gap: 13px;
	padding: 20px 15px;
`;

const Icon = styled.div`
	width: 88px;
	margin-top: -10px;

	img {
		width: 100%;
	}
`;

const Title = styled.h4`
	color: #19194b;
	text-align: center;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
`;

const Description = styled.p`
	color: #19194b;
	text-align: center;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 16px;
	line-height: 20px;
`;

const Buttons = styled.div`
	display: flex;
	gap: 12px;
	align-self: stretch;

	button {
		width: 100%;
	}

	@media screen and (max-width: 640px) {
		flex-direction: column;
	}
`;

const StepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	min-height: 300px;

	@media screen and (max-width: 640px) {
		min-height: 330px;
	}
`;

const ImageWrapper = styled.div`
	height: auto;
	min-height: 160px;

	img {
		max-width: 100%;
		height: 100%;
	}

	.mobile {
		display: none;
	}

	@media screen and (max-width: 640px) {
		height: 160px;

		.desktop {
			display: none;
		}

		.mobile {
			display: inline;
		}
	}
`;

const IndicatorsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
	margin-top: auto;
`;

const IndicatorsInner = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
`;

const Step = styled.div`
	width: 8px;
	height: 8px;
	background: #d2d2d2;
	border-radius: 50%;
`;

const NavigateButton = styled(PureButton)<{isHidden?: boolean}>`
	visibility: ${({isHidden}) => isHidden && "hidden"};

	svg {
		vertical-align: middle;
	}
`;

const StepIndicator = styled.div`
	// Step width (9px) + gap (11px)
	width: calc(8px * 2 + 8px);
	height: 8px;
	background: #2d3cff;
	border-radius: 5px;
	position: absolute;
	left: 0;
	top: 0;
	transition: 0.25s;
`;

interface IProps {
	step: number;
	totalSteps: number;
	previous: () => void;
	next: () => void;
}

const Indicators: React.FC<IProps> = observer(({step, totalSteps, previous, next}) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) {
			// Step width (8px), gap (8px)
			const offsetLeft = (step - 1) * 16;
			ref.current.style.left = `${offsetLeft}px`;
		}
	}, [step]);

	return (
		<IndicatorsWrapper>
			<NavigateButton isHidden={step < 2} onClick={previous}>
				<PreviousArrow />
			</NavigateButton>
			<IndicatorsInner>
				<StepIndicator ref={ref} />
				{range(totalSteps + 1).map((stepIndex) => (
					<Step key={stepIndex} />
				))}
			</IndicatorsInner>
			<NavigateButton onClick={next}>
				<NextArrow />
			</NavigateButton>
		</IndicatorsWrapper>
	);
});

interface IStepProps {
	title: string;
	description: string;
	image: string;
	imageMobile: string;
}

export const TutorialStep: React.FC<IStepProps> = ({title, description, image, imageMobile}) => {
	return (
		<StepWrapper>
			<ImageWrapper>
				<img className="desktop" src={image} alt={title} />
				<img className="mobile" src={imageMobile} alt={title} />
			</ImageWrapper>
			<Title>{title}</Title>
			<Description>{description}</Description>
		</StepWrapper>
	);
};

export const ModalTutorial: React.FC = observer(() => {
	const {isOpen, i18n, step, totalSteps, showIndicators, buttonText, close, previous, next} =
		useViewController<IModalTutorialController>(Bindings.ModalTutorialController);
	return (
		<Modal open={isOpen}>
			<StyledModalContent>
				<ModalCloseBlock>
					<CloseButton onClick={close}>
						<CloseIcon />
					</CloseButton>
				</ModalCloseBlock>

				<Exist when={step === 0}>
					<Icon>
						<img src={logo} alt={"World Cup France 2023"} />
					</Icon>
					<Title>{i18n.t("tutorial.start.header", "Welcome to RWC23 Fantasy")}</Title>
					<Description>
						{i18n.t(
							"tutorial.start.copy",
							"Using your budget build out your fantasy team and challenge your friend’s worldwide"
						)}
					</Description>
					<Buttons>
						<ButtonBase onClick={close} className="secondary">
							{buttonText}
						</ButtonBase>
						<ButtonBase onClick={next} className="primary">
							{i18n.t("tutorial.start.tutorial", "TUTORIAL")}
						</ButtonBase>
					</Buttons>
				</Exist>

				<Exist when={step === 1}>
					<TutorialStep
						title={i18n.t("tutorial.part_one.build_team_header", "Build your team")}
						description={i18n.t(
							"tutorial.part_one.build_team_copy",
							"With a $100m budget select a team of 15 players. Tap on a player to open the  player pool to add your choice."
						)}
						image={step1}
						imageMobile={step1Mobile}
					/>
				</Exist>

				<Exist when={step === 2}>
					<TutorialStep
						title={i18n.t("tutorial.part_one.pick_captain_header", "Pick a Captain")}
						description={i18n.t(
							"tutorial.part_one.pick_captain_copy",
							"Select a player to be your Captain. They will score double points. Tap the ‘Edit’ icon on a player to enable captaincy."
						)}
						image={step2}
						imageMobile={step2Mobile}
					/>
				</Exist>

				<Exist when={step === 3}>
					<TutorialStep
						title={i18n.t("tutorial.part_one.boosters_header", "Boosters")}
						description={i18n.t(
							"tutorial.part_one.boosters_copy",
							"Play smart by using your Super Kicker, Triple Captain and Defensive King boosters wisely. You get to use these one per stage of the tournament. "
						)}
						image={step3}
						imageMobile={step3Mobile}
					/>
				</Exist>

				<Exist when={step === 4}>
					<TutorialStep
						title={i18n.t("tutorial.part_one.score_header", "Score Points")}
						description={i18n.t(
							"tutorial.part_one.score_copy",
							"Earn points based on player performances in every game of the Rugby World Cup."
						)}
						image={step4}
						imageMobile={step4Mobile}
					/>
				</Exist>

				<Exist when={step === 5}>
					<TutorialStep
						title={i18n.t("tutorial.part_one.transfers_header", "Transfers")}
						description={i18n.t(
							"tutorial.part_one.transfers_copy",
							"Make use of unlimited transfers to make sure your team always is the best it can be"
						)}
						image={step5}
						imageMobile={step5Mobile}
					/>
				</Exist>

				<Exist when={step === 6}>
					<TutorialStep
						title={i18n.t("tutorial.part_one.compete_header", "Compete")}
						description={i18n.t(
							"tutorial.part_one.compete_copy",
							"Compete against your friends and in the Global Leaderboard for money can't buy prizes."
						)}
						image={step6}
						imageMobile={step6Mobile}
					/>
				</Exist>

				<Exist when={showIndicators}>
					<Indicators
						previous={previous}
						next={next}
						step={step}
						totalSteps={totalSteps}
					/>
				</Exist>
			</StyledModalContent>
		</Modal>
	);
});
