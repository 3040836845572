import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboardStore, IRanking} from "data/stores/leaderboard/leaderboard.store";
import {StatFilter} from "data/enums";

export interface IUserTableController extends ViewController {
	get userRank(): IRanking | null;
	get selectedStat(): StatFilter;
	get isUserInRankings(): boolean;
	get canViewTeam(): boolean;
}

@injectable()
export class UserTableController implements IUserTableController {
	get userRank() {
		return this._leaderboardStore.leaderboard.user;
	}

	get selectedStat() {
		return this._leaderboardStore.selectedStat;
	}

	get isUserInRankings() {
		return (
			this._leaderboardStore.leaderboard.rankings.findIndex(
				({userId}) => userId === this.userRank?.userId
			) > -1
		);
	}

	get canViewTeam() {
		return this._leaderboardStore.canViewTeam;
	}

	constructor(@inject(Bindings.LeaderboardStore) private _leaderboardStore: ILeaderboardStore) {
		makeAutoObservable(this);
	}
}
