import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType, RequestState, RoundStatus, StatFilter} from "data/enums";
import type {ILeaderboardStore, IRanking} from "data/stores/leaderboard/leaderboard.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {ITeam, ITeamHistory, ITeamStore} from "data/stores/team/team.store";
import {isEqual} from "lodash";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";

interface IProps {
	ranking: IRanking;
	openTeamID: number | null;
}

export interface IRankingsItemController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get selectedStat(): StatFilter;
	get selectedRound(): string;
	get canViewTeam(): boolean;
	get isUserRank(): boolean;
	get showTeam(): boolean;
	get isLoading(): boolean;
	get teamByUser(): ITeamHistory | ITeam;

	toggleTeam: () => void;
}

@injectable()
export class RankingsItemController implements IRankingsItemController {
	@observable private _ranking?: IRanking;
	@observable private _openTeamID?: number | null;
	@observable private _requestState = RequestState.PENDING;

	get selectedStat() {
		return this._leaderboardStore.selectedStat;
	}

	get selectedRound() {
		return this._leaderboardStore.selectedRound;
	}

	get canViewTeam() {
		return this._leaderboardStore.canViewTeam;
	}

	get isUserRank() {
		return this._ranking?.userId === this._userStore.user?.id;
	}

	get showTeam() {
		if (!this._openTeamID) {
			return false;
		}
		return this._openTeamID === this._ranking?.userId;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get teamByUser(): ITeamHistory | ITeam {
		return this._teamStore.teamByUser;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardStore) private _leaderboardStore: ILeaderboardStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	public toggleTeam = () => {
		const isCompleted =
			this._roundsStore.getRoundById(this._leaderboardStore.viewTeamRoundID)?.status ===
			RoundStatus.Complete;

		if (isCompleted) {
			this._teamStore
				.fetchHistoricalTeamByUser({
					roundId: this._leaderboardStore.viewTeamRoundID,
					userId: this._ranking?.userId ?? 0,
				})
				.then(this.onSuccess)
				.catch(this.onError);
		} else {
			this._teamStore
				.fetchOtherUserTeam(this._ranking?.userId ?? 0)
				.then(this.onSuccess)
				.catch(this.onError);
		}
	};

	init(param: IProps): void {
		this._ranking = param.ranking;
		this._openTeamID = param.openTeamID;
	}

	onChange(param: IProps): void {
		this._openTeamID = param.openTeamID;
	}
}
