import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {ITransfersStore, ITransfer} from "data/stores/transfers/transfers.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squds.store";

interface IModalData {
	localTransfers: ITransfer[];
}

export interface ITransferPlayers {
	outPlayer: IPlayer | undefined;
	outSquad: ISquad | undefined;
	inPlayer: IPlayer | undefined;
	inSquad: ISquad | undefined;
}

export interface IModalTransfersController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	get transferPlayers(): ITransferPlayers[];
	get isScrollable(): boolean;
	close: () => void;
}

@injectable()
export class ModalTransfersController implements IModalTransfersController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	protected get modalData(): IModalData | null {
		return this._modalsStore.modalContent as IModalData;
	}

	get localTransfers(): ITransfer[] | undefined {
		if (!this.modalData?.localTransfers) {
			return;
		}

		return this.modalData?.localTransfers;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TRANSFERS;
	}

	get transferPlayers() {
		if (!this.localTransfers) {
			return [];
		}

		return this.localTransfers.map((transfers) => {
			const outPlayer = this._playersStore.getPlayerById(transfers.out);
			const inPlayer = this._playersStore.getPlayerById(transfers.in as number);

			return {
				outPlayer: outPlayer,
				outSquad: this._squadsStore.getSquadById(outPlayer?.squadId),
				inPlayer: inPlayer,
				inSquad: this._squadsStore.getSquadById(inPlayer?.squadId),
			};
		});
	}

	get isScrollable() {
		return this.transferPlayers.length > 4;
	}

	dispose(): void {
		return;
	}

	public close = () => {
		this._modalsStore.hideModal();
		// this._transfersStore.resetLocalTransfers();
	};
}
