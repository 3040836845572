import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {useLocation} from "react-router";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import Keycloak from "keycloak-js";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Locale, ModalType} from "data/enums";
import type {IHttpClientService} from "data/services/http";
import {type IWebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";
import {delay} from "lodash";

interface IController {
	location: ReturnType<typeof useLocation>;
	keycloak: Keycloak;
}
export interface IMenuItemsController extends ViewController<IController> {
	readonly i18n: ILocalizationStore;
	get isLeaguesPage(): boolean;
	get isAuthorized(): boolean;
	get isMobileMenuOpen(): boolean;
	get isFromApp(): boolean;

	openMobileMenu: () => void;
	closeMobileMenu: () => void;
	logout: () => void;
	openTutorialModal: () => void;
	changeLanguage: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

@injectable()
export class MenuItemsController implements IMenuItemsController {
	@observable private _isLeaguesPage = false;
	@observable private _mobileMenuOpen = false;
	@observable _keycloak: Keycloak | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ApiHTTPClient) private _apiHTTPClient: IHttpClientService,
		@inject(Bindings.WebViewGateStore) private _webViewGameStore: IWebViewGateStore
	) {
		makeAutoObservable(this);
	}
	get isLeaguesPage() {
		return this._isLeaguesPage;
	}

	get isMobileMenuOpen(): boolean {
		return this._mobileMenuOpen;
	}

	get isFromApp() {
		return this._webViewGameStore.isFromApp;
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}
	onChange(params: IController) {
		this._isLeaguesPage = params.location.pathname.includes("league/");
	}

	init(params: IController) {
		this._isLeaguesPage = params.location.pathname.includes("league/");
		this._keycloak = params.keycloak;
	}

	openMobileMenu = () => {
		this._mobileMenuOpen = true;
	};

	closeMobileMenu = () => {
		// Delay added due to sidedrawer bug when used with lazy loading
		// https://github.com/mui/material-ui/issues/36934
		delay(() => {
			this._mobileMenuOpen = false;
		}, 400);
	};

	openTutorialModal = () => {
		this._mobileMenuOpen = false;
		this._modalsStore.showModal(ModalType.TUTORIAL);
	};

	logout = () => {
		void this._userStore.logout().finally(() => {
			if (this._keycloak) {
				void this._keycloak.logout();
			}
		});
	};

	@action
	changeLanguage = async (event: React.SyntheticEvent<HTMLButtonElement>) => {
		const locale = event.currentTarget.dataset.locale as Locale;

		await this.i18n
			.switchLocale({
				locale,
			})
			.then(() => this._apiHTTPClient.setLocale(this.i18n.lang));

		this._mobileMenuOpen = false;
	};
}
