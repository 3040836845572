import styled from "@emotion/styled";
import modalBg from "assets/img/modalBg.png";
import modalBgMob from "assets/img/modalBgMob.png";

export const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	background-size: cover;
	max-width: 600px;
	width: 100%;
	background: url(${modalBg}) no-repeat center top #ffffff;
	padding: 20px;
	border-radius: 10px;
	text-align: center;
	max-height: 90%;
	overflow: auto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
		background: url(${modalBgMob}) no-repeat center top #ffffff;
	}
`;

export const ModalCloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const CloseButton = styled.button`
	background: transparent;
	border: none;
`;
