import React from "react";
import {ModalSuccess} from "views/components/modals/modal_success/modal_success.component";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {ModalRegister} from "views/components/modals/modal_register/modal_register.component";
import {ModalAuthorization} from "./modal_authorization/modal_authorization.component";
import {ModalPlayer} from "./modal_player/modal_player.component";
import {ModalTutorial} from "./modal_tutorial/modal_tutorial.component";
import {ModalTutorialPosition} from "./modal_tutorial_position/modal_tutorial_position.component";
import {ModalModalConfirmed} from "./modal_confirmed/modal_confirmed.component";
import {ModalPoolsComplete} from "./modal_pools_complete/modal_pools_complete.component";

export const GlobalModals: React.FC = () => {
	return (
		<React.Fragment>
			<ModalSuccess />
			<ModalError />
			<ModalRegister />
			<ModalAuthorization />
			<ModalPlayer />
			<ModalTutorial />
			<ModalTutorialPosition />
			<ModalModalConfirmed />
			<ModalPoolsComplete />
		</React.Fragment>
	);
};
