import {last} from "lodash";
import {PlayerPosition} from "data/enums";
import {ISelectOption} from "data/types/types";
import {IAxiosApiErrorGeneral, IAxiosErrorMessageObject} from "data/types/modals";

export function getErrorMessageFromAxiosResponse(event: IAxiosApiErrorGeneral) {
	if (!event) {
		return "";
	}

	if (!event.response?.data?.errors) {
		return event.message;
	}

	return Object.values(event.response?.data?.errors)
		.map((e: IAxiosErrorMessageObject) => e.message)
		.join(" \n");
}

/*
 * If last value of array is necessary value (for ex afford) - clear all other values
 * If added new value after necessary value - remove it from array to filter correct
 */
export function parseSingleValueFromArray(
	values: string | string[],
	singleValue: string
): string | string[] {
	if (!Array.isArray(values)) {
		return values;
	}
	if (last(values) === singleValue) {
		return [singleValue];
	}
	if (values.includes(singleValue) && last(values) !== singleValue) {
		return values.filter((value) => value !== singleValue);
	}
	return values;
}

export const playerPositionsSelect: ISelectOption[] = [
	{label: "Prop", val: PlayerPosition.Prop},
	{label: "Hooker", val: PlayerPosition.Hooker},
	{label: "Lock", val: PlayerPosition.Lock},
	{label: "Loose Forward", val: PlayerPosition.LooseForward},
	{label: "Fly Half", val: PlayerPosition.FlyHalf},
	{label: "Scrum Half", val: PlayerPosition.ScrumHalf},
	{label: "Center", val: PlayerPosition.Center},
	{label: "Outside Back", val: PlayerPosition.OutsideBack},
];

// GROUP_STAGE = [1, 2, 3, 4, 5];
// Quarter Finals: Four Players - roundNumber = 6
// Semi-Finals: Five Players - roundNumber = 7
// Final & Bronze Final: Six Players - roundNumber = 8
export const checkRoundState = {
	isGroupStage: (roundNumber: number) => [1, 2, 3, 4, 5].includes(roundNumber),
	isQuarterFinals: (roundNumber: number) => roundNumber === 6,
	isSemiFinals: (roundNumber: number) => roundNumber === 7,
	isFinalAndBronzeFinal: (roundNumber: number) => roundNumber === 8,
};

export const getKcToken = () => {
	try {
		return localStorage.getItem("kc_token");
	} catch (e) {
		return null;
	}
};
