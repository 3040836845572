import React, {Fragment, lazy} from "react";
import {Route} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";

const LandingPage = lazy(retryFailLoad(() => import("views/pages/landing/landing.component")));

const Team = lazy(retryFailLoad(() => import("views/pages/team/team.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);

const MyLeagues = lazy(retryFailLoad(() => import("views/pages/my_leagues/my_leagues.component")));
const JoinLeagues = lazy(
	retryFailLoad(() => import("views/pages/join_leagues/join_leagues.component"))
);
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/create_league/create_league.component"))
);
const League = lazy(retryFailLoad(() => import("views/pages/league/league.component")));
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/league_settings/league_settings.component"))
);
const LeagueInvite = lazy(
	retryFailLoad(() => import("views/pages/league_invite/league_invite.component"))
);
const LeagueAbout = lazy(
	retryFailLoad(() => import("views/pages/league_about/league_about.component"))
);
const LeagueTable = lazy(
	retryFailLoad(() => import("views/pages/league_table/league_table.component"))
);
const Leaderboard = lazy(
	retryFailLoad(() => import("views/pages/leaderboard/leaderboard.component"))
);
const Account = lazy(retryFailLoad(() => import("views/pages/account/account.component")));
const BackdoorPage = lazy(retryFailLoad(() => import("views/pages/backdoor/backdoor.component")));
const StatsCentre = lazy(
	retryFailLoad(() => import("views/pages/stats_centre/stats_centre.component"))
);
const Matches = lazy(retryFailLoad(() => import("views/pages/matches/matches.component")));

export const RootRoutes = (
	<Fragment>
		<Route index element={<LandingPage />} />
		<Route element={<NotAuthOnlyRoute />}>
			{process.env.REACT_APP_SENTRY_ENV !== "production" ? (
				<Route path={"/backdoor"} element={<BackdoorPage />} />
			) : (
				""
			)}
		</Route>
		<Route element={<PrivateRoute />}>
			<Route path="/my-team" element={<Team />} />
			<Route path="/leagues" element={<MyLeagues />} />
			<Route path="/leagues/join" element={<JoinLeagues />}>
				<Route index path=":code" element={<JoinLeagues />} />
			</Route>
			<Route path="/leagues/create" element={<CreateLeague />} />
			<Route path="/league/:leagueId" element={<League />}>
				<Route path="invite" element={<LeagueInvite />} />
				<Route path="settings" element={<LeagueSettings />} />
				<Route path="about" element={<LeagueAbout />} />
				<Route path="table" element={<LeagueTable />} />
			</Route>
			<Route path="/leaderboard" element={<Leaderboard />} />
			<Route path="/account" element={<Account />} />
		</Route>
		<Route path="stats-centre" element={<StatsCentre />} />
		<Route path="matches" element={<Matches />} />
		<Route path="components" element={<ComponentsReview />} />
		<Route path="help/*" element={<Help />} />
		<Route path="*" element={<NotFound />} />
	</Fragment>
);

export default RootRoutes;
