import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {TUTORIAL_KEY} from "data/constants";
import type {ITeamStore} from "data/stores/team/team.store";

export interface IModalTutorialController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get step(): number;
	get showIndicators(): boolean;
	get totalSteps(): number;
	get buttonText(): string;
	close: () => void;
	previous: () => void;
	next: () => void;
}

@injectable()
export class ModalTutorialController implements IModalTutorialController {
	@observable _step: number = 0;
	_totalSteps: number = 6;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private readonly _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TUTORIAL;
	}

	get step() {
		return this._step;
	}

	get totalSteps() {
		return this._totalSteps;
	}

	get showIndicators() {
		return this._step !== 0;
	}

	get buttonText() {
		if (this._teamStore.isLineupEmpty) {
			return this.i18n.t("tutorial.start.get_started", "GET STARTED");
		}
		return this.i18n.t("tutorial.start.edit_team", "EDIT TEAM");
	}

	public close = () => {
		localStorage.setItem(TUTORIAL_KEY, "true");
		this._modalsStore.hideModal();
		this._step = 0;
	};

	public previous = () => {
		if (this._step > 1) {
			this._step--;
		}
	};

	public next = () => {
		if (this._step < this._totalSteps) {
			this._step++;
		} else {
			this.close();
			this._modalsStore.showModal(ModalType.TUTORIAL_POSITION);
		}
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
