import {inject, injectable} from "inversify";
import type {ILineup, ITeam, ITeamHistory} from "data/stores/team/team.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {Bindings} from "data/constants/bindings";
import {AxiosResponse} from "axios";
import {BoosterTypes} from "data/enums";

type TTeamResponse = IApiResponse<{team: ITeam}>;

type TTeamHistoryResponse = IApiResponse<{teamHistory: ITeam}>;

type TTeamHistoryByUserResponse = IApiResponse<{teamHistory: ITeamHistory}>;

interface IAutopickPayload {
	lineup?: ILineup;
	captainId?: number;
}

interface ITeamUpdatePayload {
	captainId: number;
	lineup: ILineup;
}

export interface IBoosterPayload {
	playerId: number;
	type: BoosterTypes;
}

export interface IBoosterRemovePayload {
	boosterId: number;
}

interface ICaptainUpdatePayload {
	newId: number;
}

interface IHistoryTeamPayload {
	roundId: number;
}

export interface IHistoryTeamByUserPayload {
	roundId: number;
	userId: number;
}

export interface ITeamApiProvider {
	team: () => Promise<AxiosResponse<TTeamResponse>>;
	historyTeam: (payload: IHistoryTeamPayload) => Promise<AxiosResponse<TTeamHistoryResponse>>;
	historyTeamByUser: (
		payload: IHistoryTeamByUserPayload
	) => Promise<AxiosResponse<TTeamHistoryByUserResponse>>;
	fetchOtherUserTeam: (userId: number) => Promise<AxiosResponse<TTeamResponse>>;
	autopick: (params: IAutopickPayload) => Promise<AxiosResponse<TTeamResponse>>;
	updateTeam: (params: ITeamUpdatePayload) => Promise<AxiosResponse<TTeamResponse>>;
	updateCaptain: (params: ICaptainUpdatePayload) => Promise<AxiosResponse<TTeamResponse>>;
	set_booster: (params: IBoosterPayload) => Promise<AxiosResponse<TTeamResponse>>;
	remove_booster: (payload: IBoosterRemovePayload) => Promise<AxiosResponse<TTeamResponse>>;
}

@injectable()
export class TeamApiProvider implements ITeamApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	team = () => this._http.get<TTeamResponse>(`fantasy/team/show-my`);

	historyTeam = (payload: IHistoryTeamPayload) =>
		this._http.get<TTeamHistoryResponse>(`fantasy/team-history/show-my/${payload.roundId}`);

	historyTeamByUser = (payload: IHistoryTeamByUserPayload) =>
		this._http.get<TTeamHistoryByUserResponse>(
			`fantasy/team-history/get-by-round-and-user/${payload.roundId}/${payload.userId}`
		);

	fetchOtherUserTeam = (userId: number) =>
		this._http.get<TTeamResponse>(`fantasy/team/get-by-user/${userId}`);

	autopick = (payload: IAutopickPayload) =>
		this._http.post<TTeamResponse>(`fantasy/team/autopick`, payload);

	updateTeam = (payload: ITeamUpdatePayload) =>
		this._http.post<TTeamResponse>(`fantasy/team/update`, payload);

	updateCaptain = (payload: ICaptainUpdatePayload) =>
		this._http.post<TTeamResponse>(`fantasy/team/change-captain`, payload);

	set_booster = (payload: IBoosterPayload) =>
		this._http.post<TTeamResponse>(`fantasy/team/booster`, payload);

	remove_booster = (payload: IBoosterRemovePayload) =>
		this._http.get<TTeamResponse>(`fantasy/team/booster/${payload.boosterId}`);
}
