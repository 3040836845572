import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface ILoginPayload {
	token: string;
}

export interface IBackdoorPayload {
	email: string;
}

type TLoginResponse = IApiResponse<{user: IUser}>;

export interface IAuthApiProvider {
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
	backdoor: (params: IBackdoorPayload) => Promise<AxiosResponse<TLoginResponse>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	login = (params: ILoginPayload) => this._http.post<TLoginResponse>(`auth/login/sso`, params);

	logout = () => this._http.post(`auth/logout`);

	backdoor = (params: IBackdoorPayload) =>
		this._http.post<TLoginResponse>(`auth/backdoor`, params);
}
