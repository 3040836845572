import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {ModalType} from "data/enums";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import Keycloak from "keycloak-js";

interface IController {
	keycloak: Keycloak;
}

export interface IAccountController extends ViewController<IController> {
	readonly i18n: ILocalizationStore;

	get selectedNotifications(): boolean;
	get isDisabled(): boolean;
	get buttonText(): string;

	toggleSelectedNotifications: () => void;
	updatePreferences: () => void;
	logout: () => void;
}

@injectable()
export class AccountController implements IAccountController {
	@observable private _isLoading: boolean = false;
	@observable private _isUpdated: boolean = false;
	@observable private _selectedNotifications: boolean = false;
	@observable _keycloak: Keycloak | null = null;

	get selectedNotifications() {
		return this._selectedNotifications;
	}

	get isDisabled() {
		if (this._isLoading) {
			return true;
		}
		return this.selectedNotifications === this._userStore.user?.isNotificationsEnabled;
	}

	get buttonText() {
		return this._isUpdated
			? this.i18n.t("account.page.updated")
			: this.i18n.t("account.page.update_preferences");
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) readonly _userStore: IUserStore,
		@inject(Bindings.ModalsStore) readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	init(params: IController) {
		this._keycloak = params.keycloak;
		this._selectedNotifications = Boolean(this._userStore.user?.isNotificationsEnabled);
	}

	dispose(): void {
		return;
	}

	public toggleSelectedNotifications = () => {
		this._isUpdated = false;
		this._selectedNotifications = !this.selectedNotifications;
	};

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._isLoading = false;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	private onSuccess = () => {
		this._isLoading = false;
		this._isUpdated = true;
	};

	public updatePreferences = () => {
		this._isLoading = true;
		this._userStore
			.update({
				isNotificationsEnabled: this.selectedNotifications,
			})
			.then(this.onSuccess)
			.catch(this.onError);
	};

	public logout = () => {
		this._userStore.logout().finally(() => {
			if (this._keycloak) {
				void this._keycloak.logout();
			}
		});
	};
}
