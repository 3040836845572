import React, {Fragment, PropsWithChildren, useEffect} from "react";
import {PagePreloader} from "views/components/preloader";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import type {ISessionController} from "views/components/session/session.controller";
import {useKeycloak} from "@react-keycloak/web";

export const Session: React.FC<PropsWithChildren> = observer(({children}) => {
	const {keycloak} = useKeycloak();
	const {isSessionChecked, checkUser} = useViewController<ISessionController>(
		Bindings.SessionController,
		{
			keycloak,
		}
	);

	useEffect(() => {
		checkUser();
	}, [keycloak.authenticated, checkUser]);

	return isSessionChecked ? <Fragment>{children}</Fragment> : <PagePreloader />;
});
