import selectedToPlay from "assets/img/icons/team/selectedToPlay.svg";
import benchIcon from "assets/img/icons/team/bench.svg";
import notSelected from "assets/img/icons/team/notSelected.svg";
import injuredIcon from "assets/img/icons/team/injured.svg";
import eliminatedIcon from "assets/img/icons/team/eliminated.svg";
import {PlayerStatus} from "data/enums";
import {get} from "lodash";

export const getPlayerStatusIcon = (status: PlayerStatus | undefined) => {
	if (!status) {
		return null;
	}
	const statusesIcons = {
		[PlayerStatus.Bench]: benchIcon,
		[PlayerStatus.Starting]: selectedToPlay,
		[PlayerStatus.NotSelected]: notSelected,
		[PlayerStatus.Injured]: injuredIcon,
		[PlayerStatus.Eliminated]: eliminatedIcon,
	};

	return get(statusesIcons, status, null);
};
