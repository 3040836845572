import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {BoosterTypes, ModalType} from "data/enums";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {get} from "lodash";
import type {ITransfersStore} from "data/stores/transfers/transfers.store";

interface IProps {
	isMobile: boolean;
}

export interface IBoostersControlPanelController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get budget(): number;
	get isSelectedBoosterForChoice(): boolean;
	get isSelectedBoosterPlayer(): boolean;
	get description(): string;

	onBoosterCancel: () => void;
	onBoosterConfirm: () => void;
}

@injectable()
export class BoostersControlPanelController implements IBoostersControlPanelController {
	@observable private _isMobile: boolean = false;

	get budget() {
		return this._transfersStore.isTransferMode
			? this._transfersStore.budget
			: this._teamStore.budget;
	}

	get isSelectedBoosterForChoice() {
		return Boolean(this._teamBuilderStore.boosterForChoice);
	}

	get isSelectedBoosterPlayer() {
		return Boolean(this._teamBuilderStore.selectedPlayerForBoost);
	}

	get description() {
		const descriptions = {
			[BoosterTypes.SUPER_KICKER]: {
				chose: this.i18n.t(
					"boosters.game_bar.choose_kicker",
					"Choose one of your players to be your Super Kicker.  They will earn double points."
				),
				confirm: this.i18n.t(
					"boosters.game_bar.confirm_kicker",
					"Confirm your Super Kicker booster or choose a different player."
				),
			},
			[BoosterTypes.DEFENSIVE_KING]: {
				chose: this.i18n.t(
					"boosters.game_bar.choose_defensive_king",
					"Choose one of your players to be your Defensinve King.  They will earn double points."
				),
				confirm: this.i18n.t(
					"boosters.game_bar.confirm_defensive_king",
					"Confirm your Defensive King booster or choose a different player."
				),
			},
		};

		const description = this._teamBuilderStore.boosterForChoice
			? get(descriptions, this._teamBuilderStore.boosterForChoice, null)
			: null;

		return get(description, this.isSelectedBoosterPlayer ? "confirm" : "chose", "");
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore
	) {
		makeAutoObservable(this);
	}

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	@action init(param: IProps) {
		this._isMobile = param.isMobile;
	}

	@action onBoosterCancel = () => {
		this._teamBuilderStore.boosterForChoice = null;
		this._teamBuilderStore.selectedPlayerForBoost = null;
	};

	@action onBoosterConfirm = () => {
		if (
			this._teamBuilderStore.selectedPlayerForBoost &&
			this._teamBuilderStore.boosterForChoice
		) {
			this._teamStore
				.setBooster({
					playerId: this._teamBuilderStore.selectedPlayerForBoost,
					type: this._teamBuilderStore.boosterForChoice,
				})
				.then(() => {
					this._modalsStore.showModal(ModalType.BOOSTERS_CONFIRM);
				})
				.catch(this.onError);
		}
	};
}
