import styled from "@emotion/styled";
import {Button, css} from "@mui/material";
import {NavLink} from "react-router-dom";

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
	font-family: "Webb Ellis Cup", sans-serif;
`;

const primaryButtonStyles = css`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 44px;
	width: 100%;
	font-size: 14px;
	font-style: normal;
	font-weight: 900;
	line-height: 16px;
	text-transform: uppercase;
	border-radius: 16px 12px 32px 21px;
	font-family: "Webb Ellis Cup", sans-serif;

	&:hover {
		opacity: 0.6;
	}

	&.primary {
		background: #2d3cff;

		&:hover {
			opacity: 1;
			background: #19194b;
		}
	}

	&.secondary {
		background: #19194b;

		&:hover {
			opacity: 1;
			background: #2d3cff;
		}
	}

	svg {
		margin-right: 5px;
	}
`;

export const ButtonBase = styled(Button)<{background?: string; textColor?: string}>`
	background: ${({background}) => background || "#2d3cff"};
	color: ${({textColor}) => textColor || "#ffffff"};
	&:hover {
		background: ${({background}) => background || "#2d3cff"};
		color: ${({textColor}) => textColor || "#ffffff"};
	}
	&:disabled {
		background: #d2d2d2 !important;
		color: #969696 !important;
	}
	${primaryButtonStyles}
`;

export const NavLinkButton = styled(NavLink)<{background?: string; textColor?: string}>`
	background: ${({background}) => background || "#2d3cff"};
	color: ${({textColor}) => textColor || "#ffffff"};
	${primaryButtonStyles}
`;
