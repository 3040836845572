import {DateTime, Duration} from "luxon";

export const getTimeRemaining = (endtime: string | undefined) => {
	if (!endtime) {
		return null;
	}

	const timeEnd = DateTime.fromISO(new Date(endtime).toISOString());
	const time = DateTime.local();

	const diffTime = timeEnd.diff(time);
	const remaining = diffTime.toObject();
	const [days, hours, minutes, seconds] = Duration.fromObject(remaining)
		.toFormat("dd:hh:mm:ss")
		.split(":");

	return {
		days,
		hours,
		minutes,
		seconds,
	};
};
