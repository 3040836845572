import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {useNavigate} from "react-router-dom";
import {type IWebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
	isMobile: boolean;
}

export interface IDesktopLandingController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get isAuthorized(): boolean;
	get isFromApp(): boolean;
	handelStart: (e: React.SyntheticEvent<HTMLAnchorElement, Event>) => void;
}

@injectable()
export class DesktopLandingController implements IDesktopLandingController {
	@observable private _isMobile: boolean = false;
	private _navigate!: IProps["navigate"];

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.WebViewGateStore) private _webViewGameStore: IWebViewGateStore
	) {
		makeAutoObservable(this);
	}

	get isFromApp() {
		return this._webViewGameStore.isFromApp;
	}

	public handelStart = (e: React.SyntheticEvent<HTMLAnchorElement, Event>) => {
		if (!this.isAuthorized) {
			e.preventDefault();
			this._modalsStore.showModal(ModalType.AUTHORIZATION);
		}
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(error),
		});
	};

	@action init({isMobile, navigate}: IProps) {
		this._isMobile = isMobile;
		this._navigate = navigate;

		reaction(
			() => this.isAuthorized,
			() => {
				if (this.isAuthorized && !this._isMobile) {
					this._navigate("/my-team");
				}
			},
			{
				fireImmediately: true,
			}
		);
	}
}
