import {useViewController} from "data/services/locator";
import React from "react";
import {PureButton} from "views/components/buttons/buttons.component";
import {ILanguageSelectorController} from "./language_selector.controller";
import {Bindings} from "data/constants/bindings";
import {Menu, MenuItem} from "@mui/material";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {LANGUAGES} from "data/constants";
import {get} from "lodash";
import {Language, Locale} from "data/enums";
import {ReactComponent as DropdownArrow} from "assets/img/icons/dropdownArrow.svg";

const LanguageButton = styled(PureButton)`
	color: #19194b;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 900;
	line-height: 16px;
	display: flex;
	align-items: center;
`;

interface IProps {
	className?: string;
}

export const LanguageSelector: React.FC<IProps> = observer(({className}) => {
	const {i18n, anchorEl, isMenuOpen, handleOpenMenu, handleCloseMenu, handleChangeLanguage} =
		useViewController<ILanguageSelectorController>(Bindings.LanguageSelectorController);

	return (
		<div className={className}>
			<LanguageButton className="button" onClick={handleOpenMenu}>
				{i18n.t(get(LANGUAGES, i18n.lang))}
				<DropdownArrow />
			</LanguageButton>
			<Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleCloseMenu}>
				<MenuItem onClick={handleChangeLanguage} data-locale={Locale.EN_US}>
					{i18n.t(get(LANGUAGES, Language.EN))}
				</MenuItem>
				<MenuItem onClick={handleChangeLanguage} data-locale={Locale.FR}>
					{i18n.t(get(LANGUAGES, Language.FR))}
				</MenuItem>
			</Menu>
		</div>
	);
});
