import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {TUTORIAL_KEY} from "data/constants";

export interface IModalPoolsCompleteController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;

	close: () => void;
}

@injectable()
export class ModalPoolsCompleteController implements IModalPoolsCompleteController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.POOLS_COMPLETE;
	}

	public close = () => {
		this._modalsStore.hideModal();
		if (localStorage.getItem(TUTORIAL_KEY) !== "true") {
			this._modalsStore.showModal(ModalType.TUTORIAL);
		}
	};
}
