import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import {
	Button,
	Box,
	Drawer,
	IconButton,
	AccordionDetails,
	List,
	ListItem,
	AccordionSummary,
	css,
	Accordion,
} from "@mui/material";
import {IMenuItemsController} from "./menu_items.controller";
import {useLocation} from "react-router";
import {useIsMobile} from "data/hooks";
import {NavLink} from "react-router-dom";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeIcon.svg";
import {ReactComponent as ArrowDropUpIcon} from "assets/img/menu/ArrowDropUp.svg";
import logoMobile from "assets/img/logoMobile.png";
import menuBg from "assets/img/menu/menuBg.png";
import {useKeycloak} from "@react-keycloak/web";
import {get} from "lodash";
import {LANGUAGES} from "data/constants";
import {PureButton} from "views/components/buttons/buttons.component";
import {Language, Locale} from "data/enums";

const primaryLinkStyles = css`
	position: relative;
	display: flex;
	align-items: center;
	padding: 20px 0 20px 25px;
	background: transparent;
	border: none;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 12px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
	text-transform: uppercase;
	border-bottom: 1px solid #d2d2d2;
	width: 100%;
	color: #19194b;
`;

const LinkButton = styled(Button)`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 25px;
	background: transparent;
	border: none;
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
	text-transform: uppercase;
	color: #19194b;

	&:before {
		content: "";
		position: absolute;
		left: -19px;
		width: 1px;
		height: 22px;
		background: #e8e8ed;
	}

	@media screen and (max-width: 640px) {
		font-size: 12px;
	}
`;

const LogoMobileImg = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 35px;
	margin-left: 3px;
	transform: translate(-50%, -50%);
`;

const RightBlock = styled.div<{isFromApp?: boolean}>`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 15px;
	width: 24px;
	height: 24px;
	flex: 1;

	a.active {
		background: transparent;
	}
`;

const MenuWrapper = styled.ul`
	a,
	button {
		${primaryLinkStyles}

		&::after {
			position: absolute;
			bottom: -10px;
			left: 25px;
			content: "";
			display: block;
			width: calc(100% - 19px);
			height: 10px;
			transform: translateX(-50%);
			transition: background-color 0.25s linear;
		}

		&:hover,
		&.active {
			&::after {
				width: 62px;
			}
		}
	}
`;

const StyledAccordionDetails = styled(AccordionDetails)`
	&.MuiAccordionDetails-root {
		padding: 0;
	}

	> * .MuiListItem-root a {
		padding: 13px 24px;
	}
`;

const StyledList = styled(List)`
	padding: 0;

	ul {
		& .MuiListItem-root {
			background: #eae9ff;

			a {
				padding: 13px 30px;
			}
		}
	}
`;

const StyledListItem = styled(ListItem)`
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
	color: #191a1a;
	padding: 0;

	a,
	button {
		width: 100%;
		padding: 13px 16px 13px 40px !important;
	}

	& .MuiAccordionSummary-root {
		background-color: #f3f3f9 !important;
	}

	&:has(.MuiAccordion-root) {
		padding: 0;
	}
`;

const StyledAccordionSummary = styled(AccordionSummary)`
	height: 48px;
	${primaryLinkStyles}

	.MuiAccordionSummary-expandIconWrapper {
		padding: 0;
		margin-right: 8px;
	}

	.MuiAccordionSummary-expandIconWrapper svg {
		width: 32px;
		height: 32px;
		color: #191a1a;
	}

	&.MuiAccordionSummary-root.Mui-expanded {
		min-height: 48px;
		color: #191a1a;
		background: #fff;
		svg {
			color: #191a1a;
		}
	}
	.MuiAccordionSummary-content.Mui-expanded {
		margin: 0;
	}
`;

const ButtonStyled = styled.button`
	${primaryLinkStyles}
`;

export const MobileMenu: React.FC = observer(() => {
	const {isMobile} = useIsMobile();
	const {keycloak} = useKeycloak();
	const {
		openMobileMenu,
		isMobileMenuOpen,
		closeMobileMenu,
		isFromApp,
		isAuthorized,
		logout,
		i18n,
		openTutorialModal,
		changeLanguage,
	} = useViewController<IMenuItemsController>(Bindings.MenuItemsController, {
		location: useLocation(),
		keycloak,
	});

	return isMobile ? (
		<RightBlock className={"mobile-block"} isFromApp={isFromApp}>
			<LinkButton onClick={openMobileMenu}>{i18n.t("navigation.more", "More")}</LinkButton>

			<Drawer
				anchor="left"
				open={isMobileMenuOpen}
				onClose={closeMobileMenu}
				sx={{".MuiDrawer-paper": {padding: 0, width: "100%"}}}>
				<Box
					sx={{
						width: "100%",
						height: "100%",
						background: `url(${menuBg}) no-repeat left bottom`,
					}}>
					<Box
						sx={{
							position: "relative",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							gap: "10px",
							padding: "10px",
						}}>
						<div />
						<LogoMobileImg src={logoMobile} alt={"World Cup France 2023"} />
						<IconButton onClick={closeMobileMenu}>
							<CloseIcon />
						</IconButton>
					</Box>
					<MenuWrapper>
						<Exist when={isAuthorized}>
							<li>
								<NavLink to="/" onClick={closeMobileMenu}>
									{i18n.t("mobile.menu.dashboard", "Dashboard")}
								</NavLink>
							</li>
							<li>
								<NavLink to="/my-team" onClick={closeMobileMenu}>
									{i18n.t("navigation.my_team", "My Team")}
								</NavLink>
							</li>

							<Fragment>
								<li>
									<Accordion
										sx={{
											m: 0,
											p: 0,
											borderRadius: 0,
											boxShadow: "none",
											width: "100%",
										}}
										disableGutters={true}>
										<StyledAccordionSummary
											sx={{m: 0, p: 0}}
											expandIcon={<ArrowDropUpIcon />}>
											Leagues
										</StyledAccordionSummary>
										<StyledAccordionDetails>
											<StyledList>
												<StyledListItem>
													<NavLink
														to={`/leagues`}
														onClick={closeMobileMenu}>
														{i18n.t(
															"navigation.my_leagues",
															"My Leagues"
														)}
													</NavLink>
												</StyledListItem>
												<StyledListItem>
													<NavLink
														to={`/leagues/create`}
														onClick={closeMobileMenu}>
														{i18n.t(
															"navigation.create_league",
															"Create a League"
														)}
													</NavLink>
												</StyledListItem>
												<StyledListItem>
													<NavLink
														to={`/leagues/join`}
														onClick={closeMobileMenu}>
														{i18n.t(
															"navigation.join_league",
															"Join a league"
														)}
													</NavLink>
												</StyledListItem>
											</StyledList>
										</StyledAccordionDetails>
									</Accordion>
								</li>
								<li>
									<NavLink to="/leaderboard" onClick={closeMobileMenu}>
										{i18n.t("navigation.leaderboard", "Leaderboard")}
									</NavLink>
								</li>
								<li>
									<NavLink to="/stats-centre" onClick={closeMobileMenu}>
										{i18n.t("navigation.stats_centre", "Stats Centre")}
									</NavLink>
								</li>
							</Fragment>
						</Exist>

						<li style={{display: "none"}}>
							<NavLink to="/prizes" onClick={closeMobileMenu}>
								{i18n.t("navigation.prize", "Prizes")}
							</NavLink>
						</li>

						<li>
							<NavLink to="/matches" onClick={closeMobileMenu}>
								{i18n.t("navigation.matches.title", "Matches")}
							</NavLink>
						</li>

						<li>
							<NavLink to="/help" onClick={closeMobileMenu}>
								{i18n.t("navigation.help", "Help")}
							</NavLink>
						</li>
						<Exist when={isAuthorized}>
							<li className="last">
								<ButtonStyled onClick={openTutorialModal}>
									{i18n.t("navigation.tutorial", "Tutorial")}
								</ButtonStyled>
							</li>
						</Exist>
						<Exist when={isAuthorized}>
							<li className="last">
								<ButtonStyled onClick={logout}>
									{i18n.t("navigation.log_out", "Log out")}
								</ButtonStyled>
							</li>
						</Exist>
						<li>
							<Accordion
								sx={{
									m: 0,
									p: 0,
									borderRadius: 0,
									boxShadow: "none",
									width: "100%",
								}}
								disableGutters={true}>
								<StyledAccordionSummary
									sx={{m: 0, p: 0}}
									expandIcon={<ArrowDropUpIcon />}>
									{i18n.t(
										`navigation.language.${i18n.lang}`,
										get(LANGUAGES, i18n.lang)
									)}
								</StyledAccordionSummary>
								<StyledAccordionDetails>
									<StyledList>
										<StyledListItem>
											<PureButton
												onClick={changeLanguage}
												data-locale={Locale.EN_US}>
												{i18n.t(
													`navigation.language.${Language.EN}`,
													get(LANGUAGES, Language.EN)
												)}
											</PureButton>
										</StyledListItem>
										<StyledListItem>
											<PureButton
												onClick={changeLanguage}
												data-locale={Locale.FR}>
												{i18n.t(
													`navigation.language.${Language.FR}`,
													get(LANGUAGES, Language.FR)
												)}
											</PureButton>
										</StyledListItem>
									</StyledList>
								</StyledAccordionDetails>
							</Accordion>
						</li>
					</MenuWrapper>
				</Box>
			</Drawer>
		</RightBlock>
	) : null;
});
