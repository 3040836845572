import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ISquad, ISquadsStore} from "data/stores/squads/squds.store";
import {Bindings} from "data/constants/bindings";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {SelectChangeEvent} from "@mui/material";
import {ChangeEvent} from "react";
import {ISelectOption} from "data/types/types";
import type {
	IPlayer,
	IPlayersStore,
	IPoolFilters,
	IPoolPlayer,
} from "data/stores/players/players.store";
import {MILLION} from "data/constants";
import {chain, get, orderBy} from "lodash";
import {PlayerStatus, SortOrder, UserStats, UserStatuses} from "data/enums";
import type {ITransfersStore} from "data/stores/transfers/transfers.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {hasEmptySpot} from "data/utils/lineup";

interface IProps {
	isMobile: boolean;
}

export interface IPlayersPoolController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get filters(): IPoolFilters;
	get isFiltersChanged(): boolean;
	get squads(): ISquad[];
	get positionsArray(): ISelectOption[];
	get expanded(): boolean;
	get statsOptions(): ISelectOption[];
	get statusesPlayerOptions(): ISelectOption[];
	get players(): IPoolPlayer[];
	get orderPrice(): SortOrder;
	get orderStatPercent(): SortOrder;
	get selectedStatOption(): string;
	get selectedPlayerStatus(): string;
	get tradeStarted(): boolean;
	get enabledFilters(): number;

	updateFilter: (e: SelectChangeEvent | SelectChangeEvent<string[]>) => void;
	updateSearch: (e: ChangeEvent<HTMLInputElement>) => void;
	resetSearch: () => void;
	setPosition: (e: SelectChangeEvent | SelectChangeEvent<string[]>) => void;
	toggleFilters: () => void;
	closeFilters: () => void;
	openFilters: () => void;
	resetFilters: () => void;
	sortPlayersStat: () => void;
	sortPlayersPrice: () => void;
	closePlayerPool: () => void;
	updateStatToShow: (e: SelectChangeEvent) => void;
	updatePlayerStatusToShow: (e: SelectChangeEvent) => void;
}

@injectable()
export class PlayersPoolController implements IPlayersPoolController {
	@observable private _selectedStatPoolDisposer?: ReturnType<typeof reaction>;
	private _statsOptions = [
		{val: UserStats.PercentSelected, label: "pool.stat.selected"},
		{val: UserStats.NextFixture, label: "pool.stat.next_fixture"},
		{val: UserStats.RoundPoints, label: "pool.stat.round_points"},
		{val: UserStats.TotalPoints, label: "pool.stat.total_points"},
	];

	private _statsOptionsPreSeason = [
		{val: UserStats.PercentSelected, label: "pool.stat.selected"},
		{val: UserStats.NextFixture, label: "pool.stat.next_fixture"},
	];

	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get squads(): ISquad[] {
		return orderBy(this._squadsStore.list, ["name"], ["asc"]);
	}

	get filters(): IPoolFilters {
		return this._teamBuilderStore.filters;
	}

	get isFiltersChanged() {
		return this._teamBuilderStore.isFiltersChanged;
	}

	get positionsArray(): ISelectOption[] {
		return this._teamStore.positionsArray;
	}

	get orderPrice(): SortOrder {
		return this._teamBuilderStore.orderPrice;
	}

	get orderStatPercent(): SortOrder {
		return this._teamBuilderStore.orderStatPercent;
	}

	get statsOptions() {
		if (this._roundsStore.isPreseason) {
			return this._statsOptionsPreSeason;
		}

		return this._statsOptions;
	}

	get statusesPlayerOptions() {
		return this._teamBuilderStore.statusesPlayerOptions;
	}

	get isTransferMode(): boolean {
		return this._transfersStore.isTransferMode;
	}

	get players(): IPoolPlayer[] {
		const teamIds = this._teamStore.lineupIDs;
		const maxPrice = this._teamStore.budget / MILLION;
		const players = this._playersStore.getFilteredPlayers(this.filters, maxPrice);

		return (
			chain(players)
				// Hide all Eliminated players
				.filter(
					(player) =>
						!(player.status === PlayerStatus.Eliminated && !teamIds.includes(player.id))
				)
				.filter((player) => {
					if (this.selectedPlayerStatus === "All") {
						return true;
					} else {
						return player.status === this.selectedPlayerStatus;
					}
				})
				.map(this.addExtraFieldsForPlayer)
				.sort(this.sortPlayerByStat)
				.sort((a: IPoolPlayer, b: IPoolPlayer) => {
					if (this.filters.sort === "price") {
						return this._teamBuilderStore.orderPrice === "asc"
							? a.cost - b.cost
							: b.cost - a.cost;
					}
					return 0;
				})
				.value()
		);
	}

	get expanded(): boolean {
		return this._teamBuilderStore.isPoolFiltersVisible;
	}

	get selectedStatOption() {
		return this._teamBuilderStore.selectedStatPoolOption;
	}

	get selectedPlayerStatus() {
		return this._teamBuilderStore.selectedPlayerStatus;
	}

	get tradeStarted() {
		return this._transfersStore.isTransferMode;
	}

	get enabledFilters() {
		let quantity = 1;

		if (this.filters.search !== "") {
			quantity++;
		}
		if (this.filters.squad !== "null") {
			quantity++;
		}
		if (this.filters.price !== "null") {
			quantity++;
		}
		if (this.filters.status !== "All") {
			quantity++;
		}
		if (!this.filters.position.find((item) => item === "null")) {
			quantity++;
		}

		return quantity;
	}

	private addExtraFieldsForPlayer = (player: IPlayer) => {
		const teamIds = this._teamStore.lineupIDs;
		const isPlayerCanBeTradeIn = this._transfersStore.isPlayerCanBeTradeIn(player);
		const isAvailablePlayerCountry = this.isTransferMode
			? isPlayerCanBeTradeIn
			: this._teamStore.getIsPlayerCountryAvailable(player);
		const isAvailablePlayerPosition = this.isTransferMode
			? hasEmptySpot(
					this._transfersStore.transferLineupWithoutUnpairedTradePlayers,
					player.position
			  )
			: this._teamStore.hasEmptySpot(this._teamStore.lineup, player.position);

		return {
			...player,
			isInTeam: teamIds.includes(player.id),
			displayPrice: this._playersStore.getPlayerDisplayPrice(player.cost),
			canAddToTeam: this._teamStore.canAddPlayerToTeam(player),
			addedInTeam: this._teamStore.getIsPlayerInTeam(player),
			isCapitan: this._teamStore.getIsPlayerCapitan(player),
			isPlayerCanBeTradeIn: isPlayerCanBeTradeIn,
			isAvailablePlayerCountry: isAvailablePlayerCountry,
			isAvailablePlayerPosition: isAvailablePlayerPosition,
			isPlayerPlayedInThisRound:
				this._transfersStore.getIsPlayerDisabledInCurrentRound(player),
		};
	};

	private nextFixtureSorting = (a: IPoolPlayer, b: IPoolPlayer) => {
		const isAscOrderStat = this._teamBuilderStore.orderStatPercent === "asc";
		return isAscOrderStat
			? (a.stats?.nextFixture || 0) - (b.stats?.nextFixture || 0)
			: (b.stats?.nextFixture || 0) - (a.stats?.nextFixture || 0);
	};

	private lastRoundPointsSorting = (a: IPoolPlayer, b: IPoolPlayer) => {
		const isAscOrderStat = this._teamBuilderStore.orderStatPercent === "asc";
		return isAscOrderStat
			? (a.stats?.lastRoundPoints || 0) - (b.stats?.lastRoundPoints || 0)
			: (b.stats?.lastRoundPoints || 0) - (a.stats?.lastRoundPoints || 0);
	};

	private totalPointsSorting = (a: IPoolPlayer, b: IPoolPlayer) => {
		const isAscOrderStat = this._teamBuilderStore.orderStatPercent === "asc";
		return isAscOrderStat
			? (a.stats?.totalPoints || 0) - (b.stats?.totalPoints || 0)
			: (b.stats?.totalPoints || 0) - (a.stats?.totalPoints || 0);
	};

	private sortPlayerByStat = (a: IPoolPlayer, b: IPoolPlayer) => {
		const currentRound = get(this._roundsStore, "currentRound.id", 0);
		const isAscOrderStat = this._teamBuilderStore.orderStatPercent === "asc";
		let sorting;

		if (this.selectedStatOption === UserStats.PercentSelected) {
			const aPercent = get(a.selected, currentRound, 0);
			const bPercent = get(b.selected, currentRound, 0);

			sorting = isAscOrderStat ? aPercent - bPercent : bPercent - aPercent;
		}

		if (this.selectedStatOption === UserStats.NextFixture) {
			sorting = this.nextFixtureSorting(a, b);
		}

		if (this.selectedStatOption === UserStats.RoundPoints) {
			sorting = this.lastRoundPointsSorting(a, b);
		}

		if (this.selectedStatOption === UserStats.TotalPoints) {
			sorting = this.totalPointsSorting(a, b);
		}

		return sorting || 0;
	};

	public updateFilter = (e: SelectChangeEvent | SelectChangeEvent<string[]>) => {
		this._teamBuilderStore.updateFilter(e);
	};

	public updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
		this._teamBuilderStore.updateSearch(e);
	};

	public resetSearch = () => {
		this._teamBuilderStore.resetSearch();
	};

	public setPosition = (e: SelectChangeEvent | SelectChangeEvent<string[]>) => {
		const value = e.target.value;

		if (value.length < 1) {
			return;
		}

		this._teamBuilderStore.updateFilter(e);
	};

	public toggleFilters = (): void => {
		this._teamBuilderStore.toggleFilters();
	};

	public closeFilters = () => {
		this._teamBuilderStore.closeFilters();
	};

	public openFilters = () => {
		this._teamBuilderStore.openFilters();
	};

	public resetFilters = () => {
		this._teamBuilderStore.resetFilters();
	};

	public sortPlayersStat = () => {
		this._teamBuilderStore.sortPlayersStat();
	};

	public sortPlayersPrice = () => {
		this._teamBuilderStore.sortPlayersPrice();
	};

	@action
	closePlayerPool = () => {
		this._teamBuilderStore.closePlayerPool();
	};

	@action
	updateStatToShow = (e: SelectChangeEvent) => {
		const {value} = e.target;
		this._teamBuilderStore.selectedStatPoolOption = value as UserStats;
	};

	@action
	updatePlayerStatusToShow = (e: SelectChangeEvent) => {
		const {value} = e.target;
		this._teamBuilderStore.selectedPlayerStatus = value as UserStatuses;
	};

	init(): void {
		this._selectedStatPoolDisposer = reaction(
			() => this._roundsStore.isPreseason,
			() => {
				if (!this._roundsStore.isPreseason) {
					this._teamBuilderStore.selectedStatPoolOption = UserStats.TotalPoints;
					this._teamBuilderStore.sortPlayersStat();
				}
			},
			{
				fireImmediately: true,
			}
		);
	}

	dispose(): void {
		this._selectedStatPoolDisposer?.();
	}
}
