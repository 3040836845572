import {action, makeAutoObservable, observable, runInAction, computed} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {PlayerPosition, PlayerStatus, TournamentStatus, UserStatuses} from "data/enums";
import {forEach, last, first} from "lodash";
import {SPECIAL_SYMBOLS, MILLION, IMAGES_URL} from "data/constants";
import {IRound, ITournament} from "data/stores/rounds/rounds.store";

export interface IPoolFilters {
	search: string;
	position: string[];
	squad: string;
	price: string;
	status: UserStatuses;
	sort: "price" | "stat";
}

export interface IPlayer {
	id: number;
	feedId: number;
	firstName: string;
	lastName: string;
	squadId: number;
	cost: number;
	position: PlayerPosition[];
	selected: Record<IRound["id"], number>;
	stats: {
		totalPoints: number | null;
		avgPoints: number | null;
		lastRoundPoints: number | null;
		positionRank: number | null;
		nextFixture: number | null;
		scores: Record<IRound["id"], number> | null;
	} | null;
	status: PlayerStatus;
	isLocked: boolean;
	pitchAvatar: string;
	profileAvatar: string;
}

export interface IPoolPlayer extends IPlayer {
	isInTeam: boolean;
	displayPrice: string;
	canAddToTeam: boolean;
	addedInTeam: boolean;
	isCapitan: boolean;
	isPlayerCanBeTradeIn?: boolean;
	isAvailablePlayerCountry?: boolean;
	isAvailablePlayerPosition?: boolean;
	isPlayerPlayedInThisRound?: boolean;
	isPlaying?: boolean;
}

export interface IPlayerOpponentPayload {
	tournaments?: ITournament[];
	squadId?: number;
}

export interface IPlayerOpponentData {
	tournamentId: number;
	isHomeGame: boolean;
	opponentSquadAbbr: string;
	tournamentStatus: TournamentStatus;
	opponentSquadName: string;
	date: string;
	homeScore: null | string;
	awayScore: null | string;
}

export interface IPlayersStore {
	get getIsLoading(): boolean;
	get list(): IPlayer[];
	getLineupPrice(ids: number[]): number;
	getPlayerById(playerId: number): IPlayer | undefined;
	getFilteredPlayers(filters: IPoolFilters, maxPrice: number): IPlayer[];
	getPlayerDisplayPrice(price: number): string;
	fetchPlayers(): Promise<void>;
	getOpponents(params: IPlayerOpponentPayload): IPlayerOpponentData[];
}

@injectable()
export class PlayersStore implements IPlayersStore {
	@observable private _isLoading: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IPlayer[] = [];

	get list() {
		return this._list;
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	getPlayerById(playerId: number): IPlayer | undefined {
		return this._list.find((player) => player.id === playerId);
	}

	getFilteredPlayers(filters: IPoolFilters, maxPrice: number): IPlayer[] {
		const {search, squad, position, price} = filters;
		const players = this.list;

		return players.filter((player) => {
			return (
				this.isInName(search, player) &&
				this.isInSquad(squad, player) &&
				this.isInPosition(position, player) &&
				this.isInPrice(price, player, maxPrice)
			);
		});
	}

	isInSquad(squad: string, player: IPlayer) {
		if (squad === "null") {
			return true;
		}
		return player.squadId === parseInt(squad, 0);
	}

	isInName(searchValue: string, player: IPlayer): boolean {
		if (!searchValue.length) {
			return true;
		}
		let fullName = `${player.firstName} ${player.lastName}`.toLowerCase();
		let enteredName = searchValue.toLowerCase();

		forEach(SPECIAL_SYMBOLS, (item, key) => {
			fullName = fullName.replace(key, item);
			enteredName = enteredName.replace(key, item);
		});

		return fullName.includes(enteredName);
	}

	isInPosition(position: string[], player: IPlayer) {
		if (position.includes("null")) {
			return true;
		}
		const playerPosition = first(player.position);
		return playerPosition ? position.includes(playerPosition) : false;
	}

	isInPrice(prices: string, player: IPlayer, maxPrice: number) {
		if (prices === "null") {
			return true;
		}
		const variants = prices === "afford" ? [0, maxPrice] : prices.split("-").map(Number);
		const [lowest, highest] = [first(variants) || 0, last(variants) || 0];
		const player_cost = player.cost / MILLION;

		return Boolean(lowest <= player_cost && player_cost <= highest);
	}

	@action
	async fetchPlayers() {
		const {data} = await this._jsonProvider.players();

		runInAction(() => {
			this._list = data.map((player) => ({
				...player,
				pitchAvatar: this.getPlayerPitchAvatar(player.feedId),
				profileAvatar: this.getPlayerProfileAvatar(player.feedId),
			}));
		});
	}

	private getPlayerPitchAvatar(feedId: number) {
		return `${IMAGES_URL}pitch/${feedId || ""}.png`;
	}

	private getPlayerProfileAvatar(feedId: number) {
		return `${IMAGES_URL}profile/${feedId || ""}.png`;
	}

	@computed
	getLineupPrice(ids: number[]) {
		return ids.reduce((sum, id) => {
			const player = this.getPlayerById(id);
			if (!player) {
				return sum;
			}
			return sum + player.cost;
		}, 0);
	}

	getPlayerDisplayPrice(price: number): string {
		/**
		 * Transform price to be always positive.
		 * The negative value will be displayed by up/down arrows
		 */
		return (Math.abs(price) / MILLION).toFixed(1);
	}

	getOpponents({squadId, tournaments}: IPlayerOpponentPayload) {
		if (!squadId || !tournaments) {
			return [];
		}

		const matches = tournaments.filter((game) => {
			return [game.awaySquadId, game.homeSquadId].includes(squadId);
		});

		return matches.map((game): IPlayerOpponentData => {
			const isHomeGame = game.homeSquadId === squadId;
			const opponentSquadAbbr = isHomeGame ? game.awaySquadAbbr : game.homeSquadAbbr;
			const opponentSquadName = isHomeGame ? game.awaySquadName : game.homeSquadName;

			return {
				tournamentId: game.id,
				isHomeGame,
				opponentSquadAbbr,
				tournamentStatus: game.status,
				opponentSquadName,
				date: game.date,
				homeScore: game.homeScore,
				awayScore: game.awayScore,
			};
		});
	}
}
