import React from "react";
import styled from "@emotion/styled";
import {useIsMobile} from "data/hooks";
import wrLogo from "assets/img/footer/footer-logo.svg";
import globalSprite from "assets/img/footer/global-sprite.png";
import logoFacebookRound from "assets/img/footer/logo-facebook-round.svg";
import blueShape from "assets/img/footer/blue-shape.svg";
import blueShapeMobile from "assets/img/footer/blue-shape-mobile.svg";

const Wrapper = styled.div`
	overflow: hidden;
`;

const BlueShapeBlock = styled.img`
	overflow: hidden;
	position: relative;
	display: block;
	left: 50%;
	max-width: none;
	min-width: 1680px;
	transform: translate(-50%, 0.1rem);
	width: calc(100% + 0.2rem);

	@media screen and (max-width: 900px) {
		width: calc(100% + 0.4rem);
		min-width: 0;
	}
`;

const ContentWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: #2d3cff;
	color: #ffffff;
	font-family: "Webb Ellis Cup", sans-serif;
`;

const LogoWrapper = styled.div`
	width: 100%;
	max-width: 1240px;
	margin: 20px auto;
	text-align: center;
	padding: 30px;

	img {
		width: 120px;
	}
`;

const FooterSocialList = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0;
	position: relative;
	padding: 40px;
	width: 100%;

	&:before {
		background-image: radial-gradient(circle at 50% 0, #fff, hsla(0, 0%, 100%, 0));
		border-radius: 1px;
		content: "";
		display: block;
		height: 1px;
		left: 50%;
		opacity: 0.2;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: 75%;
	}

	&:after {
		background-image: radial-gradient(circle at 50% 0, #fff, hsla(0, 0%, 100%, 0));
		border-radius: 1px;
		content: "";
		display: block;
		height: 1px;
		left: 50%;
		opacity: 0.2;
		position: absolute;
		bottom: 0;
		transform: translateX(-50%);
		width: 75%;
	}
`;

const SocialBlocks = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SocialIcon = styled.a`
	position: relative;
	margin: 0 10px;
	.tooltip {
		width: 6rem;
		display: inline-block;
		position: absolute;
		bottom: 150%;
		left: -2.1rem;
		line-height: 1;
		padding: 10px 0;
		transform-origin: bottom center;
		border-radius: 0.5rem;
		background: #2a3d83;
		color: #fff;
		text-align: center;
		transition: transform 0.15s ease;
		z-index: 20;
		transform: scale(0);
		font-size: 13px;
		font-weight: 700;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			border: 0.4rem solid transparent;
			border-top-color: #2a3d83;
		}
	}
	.social-icon {
		width: 18px;
		height: 18px;
		transition: opacity 0.15s ease;

		&.sprite-twitter-white-icon {
			background: url(${globalSprite}) -180px -513px no-repeat;
		}
		&.sprite-facebook-white-icon {
			background: url(${logoFacebookRound});
			width: 22px;
			height: 22px;
		}
		&.sprite-youtube-white-icon {
			background: url(${globalSprite}) -324px -513px no-repeat;
		}
		&.sprite-instagram-white-icon {
			background: url(${globalSprite}) -380px -493px no-repeat;
		}
		&.sprite-snapchat-white-icon {
			background: url(${globalSprite}) -470px -493px no-repeat;
		}
		&.sprite-tiktok-white-icon {
			background: url(${globalSprite}) -18px -513px no-repeat;
		}
	}
	&:hover {
		outline: 0;
		.tooltip {
			transform: scale(1);
		}
		.social-icon {
			opacity: 0.6;
		}
	}
`;

const FooterBottom = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 1240px;
	margin: 20px auto;
	padding: 20px 0 10px 0;

	@media screen and (max-width: 900px) {
		flex-flow: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	p {
		font-size: 11px;
		font-weight: 700;

		@media screen and (max-width: 900px) {
			padding-left: 10px;
			margin-bottom: 30px;
			width: 300px;
			text-align: center;
		}
	}

	ul {
		display: flex;
		li {
			margin: 0 10px;
			a {
				font-size: 11px;
				font-weight: 700;
			}
		}

		@media screen and (max-width: 900px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;

			li {
				margin: 5px 0;
			}
		}
	}
`;

export const Footer: React.FC = () => {
	const {isMobile} = useIsMobile();

	return (
		<Wrapper>
			<BlueShapeBlock src={isMobile ? blueShapeMobile : blueShape} alt={"Blue Shape"} />
			<ContentWrapper>
				<LogoWrapper>
					<a href="//www.worldrugby.org">
						<img src={wrLogo} alt={"world rugby logo"} />
					</a>
				</LogoWrapper>
				<FooterSocialList>
					<SocialBlocks>
						<SocialIcon
							href="https://twitter.com/worldrugby"
							target="_blank"
							rel="noreferrer">
							<div className={"social-icon sprite-twitter-white-icon"} />
							<span className="tooltip">Twitter</span>
						</SocialIcon>
						<SocialIcon
							href="https://www.facebook.com/worldrugby"
							target="_blank"
							rel="noreferrer">
							<div className={"social-icon sprite-facebook-white-icon"} />
							<span className="tooltip">Facebook</span>
						</SocialIcon>
						<SocialIcon
							href="http://youtube.com/worldrugby"
							target="_blank"
							rel="noreferrer">
							<div className={"social-icon sprite-youtube-white-icon"} />
							<span className="tooltip">YouTube</span>
						</SocialIcon>
						<SocialIcon
							href="http://instagram.com/worldrugby"
							target="_blank"
							rel="noreferrer">
							<div className={"social-icon sprite-instagram-white-icon"} />
							<span className="tooltip">Instagram</span>
						</SocialIcon>
					</SocialBlocks>
				</FooterSocialList>
				<FooterBottom>
					<p className="copy">
						Rugby World Cup Limited © 2007 - 2023. All Rights Reserved.
					</p>
					<ul>
						<li>
							<a
								href="https://www.rugbyworldcup.com/2023/terms-and-conditions"
								target="_blank"
								rel="noreferrer">
								Terms &amp; Conditions
							</a>
						</li>
						<li>
							<a
								href="https://www.rugbyworldcup.com/2023/cookies-policy"
								target="_blank"
								rel="noreferrer">
								Cookie Policy
							</a>
						</li>
						<li>
							<a
								href="https://www.rugbyworldcup.com/2023/privacy-policy"
								target="_blank"
								rel="noreferrer">
								Privacy Policy
							</a>
						</li>
						<li>
							<a
								href="https://www.rugbyworldcup.com/2023/contact-us"
								target="_blank"
								rel="noreferrer">
								Contact World Rugby
							</a>
						</li>
					</ul>
				</FooterBottom>
			</ContentWrapper>
		</Wrapper>
	);
};
