import styled from "@emotion/styled";

export const Aside = styled.div`
	max-width: 100%;
	width: 384px;
	&.with-title-gap {
		margin-top: 50px;
	}
	@media screen and (max-width: 900px) {
		&.with-mobile-padding {
			padding: 0 20px;
		}
	}
`;
