import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {AxiosResponse} from "axios";

export interface IGameBar {
	roundRank: number | null;
	overallRank: number | null;
	roundPoints: number | null;
	overallPoints: number | null;
}

interface IGameBarPayload {
	roundId?: number;
}

type IGameBarResponse = IApiResponse<IGameBar>;

export interface IGameBarProvider {
	fetchGameBar: (params: IGameBarPayload) => Promise<AxiosResponse<IGameBarResponse>>;
}

@injectable()
export class GameBarProvider implements IGameBarProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}
	fetchGameBar = (params: IGameBarPayload) =>
		this._http.get<IGameBarResponse>("ranking/gamebar", params);
}
