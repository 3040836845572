import React, {useCallback} from "react";
import {observer} from "mobx-react";
import {MenuItem, Modal, FormControl} from "@mui/material";
import {useViewController} from "data/services/locator";
import type {IModalRegisterController} from "views/components/modals/modal_register/modal_register.controller";
import {Bindings} from "data/constants/bindings";
import {ModalContent} from "views/components/modals/common";
import LogoIcon from "assets/img/logo.png";
import styled from "@emotion/styled";
import {Checkbox} from "views/components/form/checkbox.component";
import {ButtonBase} from "views/components/buttons/buttons.component";
import {useKeycloak} from "@react-keycloak/web";
import {Exist} from "views/components/exist/exist.component";
import {get, map} from "lodash";
import {Select, SelectFormControl} from "views/components/form/select.component";
import {Link} from "react-router-dom";
import {Preloader} from "views/components/preloader";
import {getKcToken} from "data/utils/helpers";

const StyledModalContent = styled(ModalContent)`
	gap: 16px;
`;

const Title = styled.h4`
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
`;

const Description = styled.div`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 15px 0;

	a {
		color: #3944ff;
	}
`;

const Logo = styled.img`
	width: 88px;
	margin-top: 30px;
`;

const ContentWrapper = styled.div`
	max-width: 400px;
`;

const StyledCheckbox = styled(Checkbox)`
	min-width: 19px;
	width: 19px;
	height: 19px;

	input:checked + svg + span {
		background: #2d3cff;
	}

	input:focus + svg + span {
		border-color: #2d3cff;
	}

	span {
		border: 2px solid #2d3cff;
	}
`;

const FormControlStyled = styled(FormControl)`
	align-items: center;
	flex-direction: initial;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	text-align: left;

	div {
		margin-left: 15px;

		a {
			color: #3944ff;
		}
	}
`;

const ButtonStyled = styled(ButtonBase)`
	margin-top: 10px;
	width: 300px;
`;

// const FormControlName = styled(FormControl)`
// 	margin-bottom: 10px;
// `;

export const ModalRegister: React.FC = observer(() => {
	const {keycloak} = useKeycloak();
	const ssoSupportingCountry = get(keycloak, "idTokenParsed.supportingCountry", null) as
		| string
		| null;

	const {
		isOpen,
		i18n,
		countries,
		selectedTerms,
		toggleSelectedTerms,
		sendRegisterData,
		supportedCountry,
		changeCountry,
		isDisabled,
		getIsLoading,
		// userName,
		// updateUserName,
	} = useViewController<IModalRegisterController>(Bindings.ModalRegisterController, {
		ssoSupportingCountry,
	});
	const kc_token = getKcToken();

	const handleRegister = useCallback(() => {
		if (kc_token) {
			sendRegisterData(kc_token);
		} else if (keycloak.token) {
			sendRegisterData(keycloak.token);
		}
	}, [keycloak, sendRegisterData, kc_token]);

	return (
		<Modal open={isOpen}>
			<StyledModalContent>
				<Logo src={LogoIcon} alt={"Logo in registration modal"} />
				<Title>{i18n.t("modal.registration.header", "Get Started")}</Title>
				<ContentWrapper>
					<Exist when={!ssoSupportingCountry || ssoSupportingCountry === ""}>
						{/*<FormControlName fullWidth>*/}
						{/*	<Input*/}
						{/*		label={i18n.t("modal.registration.userName.label", "Username")}*/}
						{/*		name="userName"*/}
						{/*		type="text"*/}
						{/*		placeholder={i18n.t(*/}
						{/*			"modal.registration.userName.placeholder",*/}
						{/*			"Fill your username"*/}
						{/*		)}*/}
						{/*		required={true}*/}
						{/*		value={userName}*/}
						{/*		onChange={updateUserName}*/}
						{/*		inputProps={{*/}
						{/*			minLength: 1,*/}
						{/*			maxLength: 40,*/}
						{/*		}}*/}
						{/*	/>*/}
						{/*</FormControlName>*/}
						<SelectFormControl>
							<Select
								name="squad"
								label={i18n.t(
									"modal.registration.country_supported.label",
									"Country Supported"
								)}
								value={supportedCountry}
								onChange={changeCountry}>
								{map(countries, (country) => (
									<MenuItem key={country.code} value={country.code}>
										{country.name}
									</MenuItem>
								))}
							</Select>
						</SelectFormControl>
					</Exist>
					<Description>
						{i18n.t(
							"modal.registration.description",
							"To be eligible to win prizes, you must accept this game’s "
						)}{" "}
						<Link to={"/help/terms-&-conditions"} target="_blank">
							{i18n.t("modal.registration.terms_link", "terms & conditions.")}
						</Link>
					</Description>

					<FormControlStyled>
						<StyledCheckbox checked={selectedTerms} onChange={toggleSelectedTerms} />{" "}
						<div>
							{i18n.t(
								"modal.registration.checkbox_label",
								"By selecting this box I agree to the"
							)}{" "}
							<Link to={"/help/terms-&-conditions"} target="_blank">
								{i18n.t(
									"modal.registration.checkbox_terms_link",
									"Terms & Conditions."
								)}
							</Link>
						</div>
					</FormControlStyled>
				</ContentWrapper>
				<Exist when={getIsLoading}>
					<Preloader />
				</Exist>
				<Exist when={!getIsLoading}>
					<ButtonStyled
						disabled={isDisabled}
						onClick={handleRegister}
						className="primary">
						{i18n.t("modal.registration.accept", "Accept")}
					</ButtonStyled>
				</Exist>
			</StyledModalContent>
		</Modal>
	);
});
