import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {makeAutoObservable, observable} from "mobx";
import {ModalType} from "data/enums";
import type {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface ILeaguesCTAController extends ViewController {
	get i18n(): ILocalizationStore;
	get searchValue(): string;
	onSearchClear: () => void;
	onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onSearchLeagueForJoinChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class LeaguesCTAController implements ILeaguesCTAController {
	@observable searchTimer: ReturnType<typeof setTimeout> | undefined;
	@observable private _searchValue: string = "";

	get searchValue() {
		return this._searchValue;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	public onSearchClear = () => {
		this._searchValue = "";
		this._leaguesStore
			.fetchLeaguesForJoin({
				search: this._searchValue,
			})
			.catch(this.onError);
	};

	public onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {name, value} = event.currentTarget;

		if (!name) {
			return;
		}

		this._searchValue = value;

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._leaguesStore
				.fetchMyLeagues({
					search: this._searchValue,
				})
				.catch(this.onError);
		}, 500);
	};

	public onSearchLeagueForJoinChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {name, value} = event.currentTarget;

		if (!name) {
			return;
		}
		this._searchValue = value;

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._leaguesStore
				.fetchLeaguesForJoin({
					search: this._searchValue,
				})
				.catch(this.onError);
		}, 500);
	};
}
