import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IPointsMessageController extends ViewController {
	readonly i18n: ILocalizationStore;
	get currentRound(): number | undefined;
}

@injectable()
export class PointsMessageController implements IPointsMessageController {
	get currentRound() {
		return this._roundsStore.currentRound?.id;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}
}
