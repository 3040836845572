import React from "react";
import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ViewController} from "data/types/structure";

interface IProps {
	isMobile: boolean;
	fieldRef: React.RefObject<HTMLImageElement> | null;
}

export interface IFieldImageController extends ViewController<IProps> {
	get isPitchScrolled(): boolean;
	scrollPitch: () => void;
}

@injectable()
export class FieldImageController implements IFieldImageController {
	@observable private _isMobile: boolean = false;
	@observable private _fieldRef: React.RefObject<HTMLImageElement> | null = null;
	@observable _isPitchScrolled: boolean = false;

	get isPitchScrolled() {
		return this._isPitchScrolled;
	}

	constructor() {
		makeAutoObservable(this);
	}

	public scrollPitch = () => {
		if (this._fieldRef) {
			this._fieldRef.current?.scrollIntoView({
				block: "end",
				behavior: "smooth",
			});
		}
	};

	private handleScroll = () => {
		if (!this._fieldRef || !this._fieldRef.current) {
			return;
		}
		const {bottom} = this._fieldRef.current.getBoundingClientRect();
		if (window.innerHeight >= bottom) {
			this._isPitchScrolled = true;
		} else {
			this._isPitchScrolled = false;
		}
	};

	init(param: IProps) {
		this._isMobile = param.isMobile;
		this._fieldRef = param.fieldRef;

		window.addEventListener("scroll", this.handleScroll);
	}

	dispose(): void {
		window.removeEventListener("scroll", this.handleScroll);
	}
}
