import React from "react";
import {observer} from "mobx-react";
import {Modal, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {PureButton, NavLinkButton} from "views/components/buttons/buttons.component";
import {ModalContent} from "views/components/modals/common";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeIcon.svg";
import type {IModalAuthorizationController} from "./modal_authorization.controller";
import logo from "assets/img/logo.png";
import {useKeycloak} from "@react-keycloak/web";

const StyledModalContent = styled(ModalContent)`
	padding: 20px 16px;
	gap: 13px;
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Icon = styled.div`
	width: 88px;
	margin-top: -10px;

	img {
		width: 100%;
		height: auto;
	}
`;

const Title = styled.h6`
	color: #19194b;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
`;

const Description = styled.div`
	color: #19194b;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

export const ModalAuthorization: React.FC = observer(() => {
	const {i18n, close, isOpen} = useViewController<IModalAuthorizationController>(
		Bindings.ModalAuthorizationController
	);
	const {keycloak} = useKeycloak();

	return (
		<Modal open={isOpen}>
			<StyledModalContent>
				<CloseBlock>
					<PureButton onClick={close}>
						<CloseIcon />
					</PureButton>
				</CloseBlock>
				<Icon>
					<img src={logo} alt={"World Cup France 2023"} />
				</Icon>
				<Title>{i18n.t("welcome.modal.header", "Welcome to RWC23 Fantasy")}</Title>
				<Description>
					{i18n.t(
						"welcome.modal.copy",
						"Log in or register to start building your World Cup Fantasy team"
					)}
				</Description>
				<Stack
					sx={{
						width: "100%",
						marginTop: "20px",
						flexDirection: {xs: "column", sm: "row"},
					}}
					gap="12px">
					<NavLinkButton
						to={keycloak.createLoginUrl()}
						onClick={close}
						className="secondary">
						{i18n.t("welcome.modal.login", "LOG IN")}
					</NavLinkButton>
					<NavLinkButton
						to={keycloak.createRegisterUrl()}
						onClick={close}
						className="primary">
						{i18n.t("welcome.modal.register", "REGISTER")}
					</NavLinkButton>
				</Stack>
			</StyledModalContent>
		</Modal>
	);
});
