import React, {useState, useEffect} from "react";
import {observer} from "mobx-react";
import {Box, Modal} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {PureButton, ButtonBase} from "views/components/buttons/buttons.component";
import {ModalContent} from "views/components/modals/common";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeIcon.svg";
import type {IModalPlayerController} from "./modal_player.controller";
import {getPlayerAvatar, getSquadAvatar} from "data/utils/helpers/avatars";
import {get, partial, map, isNumber, size} from "lodash";
import {Exist} from "views/components/exist/exist.component";
import playerProfileBg from "assets/img/playerProfileBg.svg";
import {DateTime} from "luxon";
import {ReactComponent as CaptainIcon} from "assets/img/icons/team/captain.svg";
import {getPlayerStatusIcon} from "data/utils/helpers/player_statuses";
import {ReactComponent as ArrowDown} from "assets/img/icons/rankDown.svg";
import {SHOW_PLAYER_AVATARS} from "data/constants";

const ModalContentStyled = styled(ModalContent)<{isCapitan: boolean}>`
	display: block;
	padding: 20px 0;
	overflow-x: hidden;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		width: 100%;
		height: 300px;
		left: 0;
		background: ${({isCapitan}) =>
			isCapitan
				? "linear-gradient(0deg, rgba(195,34,34,0) 0%, rgba(255,85,115,0.3491771708683473) 100%)"
				: "transparent"};
		z-index: -1;
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 20px;

	button {
		z-index: 1;
	}
`;

const PlayerName = styled.div`
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 900;
	line-height: 24px;
	text-transform: capitalize;
	margin-bottom: 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	@media screen and (max-width: 900px) {
		font-size: 16px;
	}
`;

const CountryName = styled.div`
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
	text-transform: uppercase;

	.small {
		font-size: 10px;
	}
`;

const CountryPlayerName = styled.div`
	color: #19194b;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
	margin-left: 10px;
`;

const PlayerCountry = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
`;

const SquadFlag = styled.img`
	width: 24px;
	border-radius: 0 0 24px 24px;
	z-index: 1;
`;

const OpponentSquadFlag = styled(SquadFlag)`
	margin-left: -6px;
	z-index: 0;
`;

const PlayerTshirt = styled.img`
	display: block;
	width: 178px;
	margin-bottom: -100px;
	margin-top: -30px;

	@media screen and (max-width: 900px) {
		margin-right: -16px;
	}
`;

const PlayerTshirtStyled = styled(PlayerTshirt)`
	width: 120px;
	margin-bottom: 0;
`;

const PlayerInfo = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0 16px;
	z-index: 1;
`;

const Description = styled.div`
	text-align: left;
	overflow: hidden;
`;

const Stat = styled.div`
	color: #19194b;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
	margin-bottom: 5px;
`;

const PlayerPosition = styled(Stat)`
	margin-bottom: 15px;

	svg {
		margin-right: 5px;
		vertical-align: middle;
	}
`;

const PlayerPrice = styled(Stat)``;

const PlayerStatsWrapper = styled.div`
	position: relative;
	width: 100%;
	background: #2d3cff;
	padding: 20px;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: -68px;
		width: 100%;
		height: 71px;
		background: url(${playerProfileBg}) no-repeat;
		background-size: cover;
	}

	h6 {
		font-family: "Webb Ellis Cup", sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 900;
		line-height: 16px;
		text-transform: uppercase;
		color: #ffffff;
	}
`;

const Fixture = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	border-radius: 10px;
	background: #ffffff;
	padding: 12px;
	margin: 16px 0;
	text-align: left;
`;

const CompleteFixture = styled(Fixture)`
	justify-content: space-between;
	cursor: pointer;
`;

const FlagsBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;

const Date = styled.div`
	color: #19194b;
	font-family: "Webb Ellis Cup", sans-serif;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
`;

const Buttons = styled.div`
	display: flex;
	gap: 20px;
	width: 100%;
	margin: 100px 0 10px;
	padding: 0 20px;

	@media screen and (max-width: 900px) {
		margin: 60px 0 10px;

		button {
			font-size: 10px;
			height: 32px;
		}
	}
`;

const CaptainButton = styled(ButtonBase)`
	&:hover {
		opacity: 1;
		background: #d75f73;
	}

	&:disabled {
		background: #ff5573 !important;
		color: #ffffff !important;
	}
`;

const RemoveButton = styled(ButtonBase)`
	&:hover {
		opacity: 1;
		background: #be322d;
	}
`;

const PlayerStatus = styled.img`
	margin-right: 5px;
	vertical-align: middle;
`;

const WrapperNav = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

const NavButton = styled.button<{isActive?: boolean}>`
	background: ${({isActive}) => (isActive ? "#2D3CFF" : "#FFFFFF")};
	border: none;
	flex: 1;
	cursor: pointer;
	font-size: 14px;
	font-style: normal;
	font-weight: 900;
	line-height: 16px;
	text-transform: uppercase;
	color: ${({isActive}) => (isActive ? "#FFFFFF" : "#2D3CFF")};
	text-align: center;
	border-radius: 10px 10px 0 0;
	height: 40px;
	margin-top: 10px;
	z-index: 1;
`;

const StatsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const StatsBlock = styled.div`
	display: flex;
	width: 100%;
	max-width: 175px;
	padding: 8px;
	flex-direction: column;
	align-items: center;
	gap: 6px;
	flex-shrink: 0;
	border-radius: 10px;
	background: #fff;

	@media screen and (max-width: 900px) {
		max-width: 95px;
	}

	div {
		color: #19194b;
		text-align: center;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		text-transform: uppercase;
		font-family: "Webb Ellis Cup", sans-serif;

		@media screen and (max-width: 900px) {
			font-size: 10px;
		}
	}
`;

const ScoredPointsInTournament = styled.div<{isOpen: boolean}>`
	display: flex;
	height: 38px;
	padding: 8px 6px 8px 8px;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	background: #19194b;
	color: #ffffff;
	text-align: center;
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
	text-transform: uppercase;

	div {
		font-size: 14px;
	}

	svg {
		transform: rotate(${({isOpen}) => (isOpen ? "180deg" : "0")});
	}
`;

const ScoringMetrics = styled.div`
	background: #19194b;
	color: #ffffff;
	padding: 20px;
	margin-top: -25px;
	border-radius: 0 0 10px 10px;
`;

const Scoring = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ffffff;
	color: #ffffff;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 10px 0;
`;

enum PlayerStats {
	Overview = "overview",
	Stats = "stats",
	Fixtures = "fixtures",
}

const getStat = (stat: number | undefined | null) => {
	return isNumber(stat) ? stat : "-";
};

export const Overview: React.FC = observer(() => {
	const {i18n, player, nextFixture, selected} = useViewController<IModalPlayerController>(
		Bindings.ModalPlayerController
	);

	return (
		<PlayerStatsWrapper>
			<StatsWrapper>
				<StatsBlock>
					<div>{i18n.t("profile.overview.total_points", "Total points")}</div>
					<div>{getStat(player?.stats?.totalPoints)}</div>
				</StatsBlock>
				<StatsBlock>
					<div>{i18n.t("profile.overview.average", "AVG PTS")}</div>
					<div>{getStat(player?.stats?.avgPoints)}</div>
				</StatsBlock>
				<StatsBlock>
					<div>{i18n.t("profile.overview.last_round", "LAST RND RTS")}</div>
					<div>{getStat(player?.stats?.lastRoundPoints)}</div>
				</StatsBlock>
			</StatsWrapper>
			<StatsWrapper>
				<StatsBlock>
					<div>{i18n.t("profile.overview.selected", "% SELECTED")}</div>
					<div>{selected}%</div>
				</StatsBlock>
				<StatsBlock>
					<div>{i18n.t("profile.overview.rank", "POS RANK")}</div>
					<div>{getStat(player?.stats?.positionRank)}</div>
				</StatsBlock>
				<StatsBlock>
					<div>{i18n.t("profile.overview.next_fixture", "NEXT FIXTURE")}</div>
					<div>{nextFixture?.abbreviation ? `v ${nextFixture?.abbreviation}` : "-"}</div>
				</StatsBlock>
			</StatsWrapper>
		</PlayerStatsWrapper>
	);
});

export const Stats: React.FC = observer(() => {
	const {i18n, playerStats} = useViewController<IModalPlayerController>(
		Bindings.ModalPlayerController
	);

	return (
		<PlayerStatsWrapper>
			<StatsWrapper>
				<StatsBlock>
					<div>{i18n.t("profile.stat.games_played")}</div>
					<div>{getStat(playerStats?.gamesPlayed)}</div>
				</StatsBlock>
				<StatsBlock>
					<div>{i18n.t("profile.stat.tries")}</div>
					<div>{getStat(playerStats?.tries)}</div>
				</StatsBlock>
				<StatsBlock>
					<div>{i18n.t("profile.stat.assists")}</div>
					<div>{getStat(playerStats?.assists)}</div>
				</StatsBlock>
			</StatsWrapper>
			<StatsWrapper>
				<StatsBlock>
					<div>{i18n.t("profile.stat.conversions")}</div>
					<div>{getStat(playerStats?.conversions)}</div>
				</StatsBlock>
				<StatsBlock>
					<div>{i18n.t("profile.stat.turnovers")}</div>
					<div>{getStat(playerStats?.turnovers)}</div>
				</StatsBlock>
				<StatsBlock>
					<div>{i18n.t("profile.stat.tackles")}</div>
					<div>{getStat(playerStats?.tackles)}</div>
				</StatsBlock>
			</StatsWrapper>
			<StatsWrapper>
				<StatsBlock>
					<div>{i18n.t("profile.stat.carries")}</div>
					<div>{getStat(playerStats?.metresGained)}</div>
				</StatsBlock>
				<StatsBlock>
					<div>{i18n.t("profile.stat.linebreak")}</div>
					<div>{getStat(playerStats?.linebreaks)}</div>
				</StatsBlock>
				<StatsBlock>
					<div>{i18n.t("profile.stat.offloads")}</div>
					<div>{getStat(playerStats?.offloads)}</div>
				</StatsBlock>
			</StatsWrapper>
		</PlayerStatsWrapper>
	);
});

export const Fixtures: React.FC = observer(() => {
	const {
		i18n,
		squad,
		opponents,
		completedAndPlayingFixtures,
		fetchPlayerTournamentStats,
		getTournamentStats,
		player,
	} = useViewController<IModalPlayerController>(Bindings.ModalPlayerController);

	useEffect(() => {
		fetchPlayerTournamentStats();
	}, [fetchPlayerTournamentStats]);

	const countryFlag = getSquadAvatar(squad?.abbreviation);

	return (
		<PlayerStatsWrapper>
			{completedAndPlayingFixtures.map((fixture, key) => {
				const stats = getTournamentStats(fixture.tournamentId);
				const scoringMetrics = stats?.scoringMetrics;

				if (
					!["prop", "hooker"].includes(player?.position[0] || "") &&
					scoringMetrics?.scrums
				) {
					// Hide scrums if player not have prop or hooker position
					scoringMetrics.scrums = 0;
				}
				const [isShowScoringMetrics, setIsShowScoringMetrics] = useState<boolean>(false);

				return (
					<div key={key}>
						<CompleteFixture
							role={"button"}
							onClick={partial(setIsShowScoringMetrics, !isShowScoringMetrics)}>
							<Box sx={{display: "flex"}}>
								<FlagsBlock>
									<SquadFlag src={countryFlag} alt={squad?.abbreviation} />
									<OpponentSquadFlag
										src={getSquadAvatar(fixture.opponentSquadAbbr)}
										alt={fixture.opponentSquadAbbr}
									/>
								</FlagsBlock>
								<div>
									<CountryName>
										<span className="small">v</span> {fixture.opponentSquadName}
									</CountryName>
									{fixture.homeScore} - {fixture.awayScore}
								</div>
							</Box>

							<ScoredPointsInTournament isOpen={isShowScoringMetrics}>
								<div>
									<div>{stats?.points || 0}</div>
									<div>{i18n.t("profile.fixture.pts")}</div>
								</div>
								<Exist when={size(scoringMetrics)}>
									<ArrowDown />
								</Exist>
							</ScoredPointsInTournament>
						</CompleteFixture>
						<Exist when={isShowScoringMetrics && size(scoringMetrics)}>
							<ScoringMetrics>
								{map(scoringMetrics, (scoring, name) =>
									scoring ? (
										<Scoring key={name}>
											<div>{i18n.t(`scoring.metric.${name}`)}</div>{" "}
											<div>
												{scoring > 0 ? "+" : ""}
												{scoring}
											</div>
										</Scoring>
									) : (
										""
									)
								)}
							</ScoringMetrics>
						</Exist>
					</div>
				);
			})}
			{opponents.map((opponent, key) => (
				<Fixture key={key}>
					<FlagsBlock>
						<SquadFlag src={countryFlag} alt={squad?.abbreviation} />
						<OpponentSquadFlag
							src={getSquadAvatar(opponent.opponentSquadAbbr)}
							alt={opponent.opponentSquadAbbr}
						/>
					</FlagsBlock>
					<div>
						<CountryName>
							<span className="small">v</span> {opponent.opponentSquadName}
						</CountryName>

						<Date>
							{DateTime.fromISO(opponent.date)
								.setLocale(i18n.locale)
								.toFormat("HH:mm")}{" "}
							{i18n.t("profile.fixture.time", "Your time")} -{" "}
							{DateTime.fromISO(opponent.date)
								.setLocale(i18n.locale)
								.toFormat("EEEE d MMMM y")}
						</Date>
					</div>
				</Fixture>
			))}
		</PlayerStatsWrapper>
	);
});

export const ModalPlayer: React.FC = observer(() => {
	const {
		i18n,
		close,
		isOpen,
		player,
		squad,
		removePlayerFromPool,
		addPlayerToPool,
		changeCaptain,
		isCapitan,
		isTeamComplete,
		selectUserForTransfer,
		selected,
		showCaptainButton,
		checkIsPlayerBlocked,
	} = useViewController<IModalPlayerController>(Bindings.ModalPlayerController);

	const playerAvatar = getPlayerAvatar(squad?.abbreviation);
	const countryFlag = getSquadAvatar(squad?.abbreviation);
	const playerStatusIcon = getPlayerStatusIcon(player?.status);
	const isPlayerBlocked = checkIsPlayerBlocked(player);

	const [openedPlayerStats, setIsOpenedPlayerStats] = useState<PlayerStats>(PlayerStats.Overview);

	const isOverviewOpened = openedPlayerStats === PlayerStats.Overview;
	const isStatsOpened = openedPlayerStats === PlayerStats.Stats;
	const isFixturesOpened = openedPlayerStats === PlayerStats.Fixtures;

	return player ? (
		<Modal open={isOpen}>
			<ModalContentStyled isCapitan={isCapitan}>
				<CloseBlock>
					<PureButton onClick={close}>
						<CloseIcon />
					</PureButton>
				</CloseBlock>
				<PlayerInfo>
					<Description>
						<PlayerCountry>
							<SquadFlag src={countryFlag} alt={squad?.abbreviation} />
							<CountryPlayerName>{i18n.t(squad?.key ?? "")}</CountryPlayerName>
						</PlayerCountry>
						<PlayerName>
							{player.firstName.charAt(0)}. {player.lastName}
						</PlayerName>
						<PlayerPosition>
							<Exist when={isCapitan}>
								<CaptainIcon />
							</Exist>
							<Exist when={playerStatusIcon}>
								<PlayerStatus
									src={playerStatusIcon as string}
									alt={player.status}
								/>
							</Exist>
							{i18n.t(player.position[0])}
						</PlayerPosition>
						<PlayerPrice>
							{i18n.t("profile.info.value", "PRICE")}:{" "}
							{get(player, "displayPrice", "0.0")}
						</PlayerPrice>
						<Stat>
							{i18n.t("profile.info.selected", "% SELECTED")}: {selected.toFixed(1)}%
						</Stat>
					</Description>

					<Exist when={SHOW_PLAYER_AVATARS}>
						<PlayerTshirt src={player.profileAvatar} alt={player.firstName} />
					</Exist>
					<Exist when={!SHOW_PLAYER_AVATARS}>
						<PlayerTshirtStyled src={playerAvatar} alt={player?.firstName} />
					</Exist>
				</PlayerInfo>
				<WrapperNav>
					<NavButton
						isActive={isOverviewOpened}
						onClick={partial(setIsOpenedPlayerStats, PlayerStats.Overview)}>
						{i18n.t("profile.overview.header")}
					</NavButton>
					<NavButton
						isActive={isStatsOpened}
						onClick={partial(setIsOpenedPlayerStats, PlayerStats.Stats)}>
						{i18n.t("profile.stats.header")}
					</NavButton>
					<NavButton
						isActive={isFixturesOpened}
						onClick={partial(setIsOpenedPlayerStats, PlayerStats.Fixtures)}>
						{i18n.t("profile.fixtures.header")}
					</NavButton>
				</WrapperNav>

				<Exist when={isOverviewOpened}>
					<Overview />
				</Exist>

				<Exist when={isStatsOpened}>
					<Stats />
				</Exist>

				<Exist when={isFixturesOpened}>
					<Fixtures />
				</Exist>

				<Box sx={{padding: "0 10px", width: "100%"}}>
					<Buttons>
						<Exist when={showCaptainButton}>
							<CaptainButton
								onClick={partial(changeCaptain, player)}
								background={"#19194B"}
								disabled={isCapitan}>
								{isCapitan
									? i18n.t("profile.button.it_is_captain", "CAPTAIN")
									: i18n.t("profile.button.captain", "MAKE CAPTAIN")}
							</CaptainButton>
						</Exist>
						<Exist when={!isTeamComplete}>
							<Exist when={player?.addedInTeam}>
								<RemoveButton
									onClick={partial(removePlayerFromPool, player)}
									background={"#FF3000"}>
									{i18n.t("profile.button.remove", "REMOVE")}
								</RemoveButton>
							</Exist>
							<Exist when={player?.canAddToTeam}>
								<ButtonBase onClick={partial(addPlayerToPool, player)}>
									{i18n.t("profile.button.add", "Add player")}
								</ButtonBase>
							</Exist>
						</Exist>
						<Exist when={isTeamComplete && !isPlayerBlocked}>
							<Exist when={player?.addedInTeam}>
								<RemoveButton
									onClick={partial(
										selectUserForTransfer,
										player.id,
										player.position[0]
									)}
									background={"#FF3000"}>
									{i18n.t("profile.button.transfer", "TRANSFER OUT")}
								</RemoveButton>
							</Exist>
						</Exist>
					</Buttons>
				</Box>
			</ModalContentStyled>
		</Modal>
	) : null;
});
