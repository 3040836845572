import {action, makeAutoObservable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {type ITeamStore} from "data/stores/team/team.store";

export interface IModalClearTeamConfirmationController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;

	onClearTeam: () => void;
	close: () => void;
}

@injectable()
export class ModalClearTeamConfirmationController implements IModalClearTeamConfirmationController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.CLEAR_TEAM_CONFIRMATION;
	}

	private clearBoostersData = () => {
		runInAction(() => {
			this._teamBuilderStore.selectedPlayerForBoost = null;
			this._teamBuilderStore.boosterForChoice = null;
		});
	};

	@action onClearTeam = () => {
		this._teamStore.clearTeam();
		this.clearBoostersData();
		this.close();
	};

	public close = () => {
		this._modalsStore.hideModal();
	};
}
