import {makeAutoObservable, action, observable, autorun} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {BoosterTypes, ModalType, PlayerPosition, TournamentStatus, UserStats} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {positionCategories} from "data/constants";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {ISelectOption} from "data/types/types";
import {SelectChangeEvent} from "@mui/material";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITransfersStore} from "data/stores/transfers/transfers.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

interface IPlayerPositionWithKey {
	key: number;
	position: PlayerPosition;
	positionCategory: string;
}

interface IPlayersCategoryPosition {
	A: IPlayerPositionWithKey[];
	B: IPlayerPositionWithKey[];
	C: IPlayerPositionWithKey[];
	D: IPlayerPositionWithKey[];
	E: IPlayerPositionWithKey[];
	F: IPlayerPositionWithKey[];
}

export interface ILineupController extends ViewController {
	readonly i18n: ILocalizationStore;
	get playersCategoryPosition(): IPlayersCategoryPosition;
	get statsOptions(): ISelectOption[];
	get selectedStatOption(): string;
	get isSelectedBoosterForChoice(): boolean;
	get isTeamFull(): boolean;
	get isShowBoosterButton(): boolean;
	get isBoosterButtonDisabled(): boolean;
	get checkIsHaveBoosterSelected(): boolean;
	get isShowHistoryTeam(): boolean;

	updateStatToShow: (e: SelectChangeEvent) => void;
	openBoostersModal: () => void;
}

@injectable()
export class LineupController implements ILineupController {
	@observable private _selectedStatDisposer?: ReturnType<typeof autorun>;

	private _playersCategoryPosition: IPlayersCategoryPosition = {
		A: [
			{
				key: 0,
				position: PlayerPosition.Prop,
				positionCategory: this.i18n.t(
					positionCategories.frontRow.key,
					positionCategories.frontRow.value
				),
			},
			{
				key: 0,
				position: PlayerPosition.Hooker,
				positionCategory: this.i18n.t(
					positionCategories.hooker.key,
					positionCategories.hooker.value
				),
			},
			{
				key: 1,
				position: PlayerPosition.Prop,
				positionCategory: this.i18n.t(
					positionCategories.frontRow.key,
					positionCategories.frontRow.value
				),
			},
		],
		B: [
			{
				key: 0,
				position: PlayerPosition.Lock,
				positionCategory: this.i18n.t(
					positionCategories.secondRow.key,
					positionCategories.secondRow.value
				),
			},
			{
				key: 1,
				position: PlayerPosition.Lock,
				positionCategory: this.i18n.t(
					positionCategories.secondRow.key,
					positionCategories.secondRow.value
				),
			},
		],
		C: [
			{
				key: 0,
				position: PlayerPosition.LooseForward,
				positionCategory: this.i18n.t(
					positionCategories.flanker.key,
					positionCategories.flanker.value
				),
			},
			{
				key: 1,
				position: PlayerPosition.LooseForward,
				positionCategory: this.i18n.t(
					positionCategories.numberEight.key,
					positionCategories.numberEight.value
				),
			},
			{
				key: 2,
				position: PlayerPosition.LooseForward,
				positionCategory: this.i18n.t(
					positionCategories.flanker.key,
					positionCategories.flanker.value
				),
			},
		],
		D: [
			{
				key: 0,
				position: PlayerPosition.ScrumHalf,
				positionCategory: this.i18n.t(
					positionCategories.scrumHalf.key,
					positionCategories.scrumHalf.value
				),
			},
			{
				key: 0,
				position: PlayerPosition.FlyHalf,
				positionCategory: this.i18n.t(
					positionCategories.flyHalf.key,
					positionCategories.flyHalf.value
				),
			},
		],
		E: [
			{
				key: 0,
				position: PlayerPosition.OutsideBack,
				positionCategory: this.i18n.t(
					positionCategories.winger.key,
					positionCategories.winger.value
				),
			},
			{
				key: 0,
				position: PlayerPosition.Center,
				positionCategory: this.i18n.t(
					positionCategories.center.key,
					positionCategories.center.value
				),
			},
			{
				key: 1,
				position: PlayerPosition.Center,
				positionCategory: this.i18n.t(
					positionCategories.center.key,
					positionCategories.center.value
				),
			},
			{
				key: 1,
				position: PlayerPosition.OutsideBack,
				positionCategory: this.i18n.t(
					positionCategories.winger.key,
					positionCategories.winger.value
				),
			},
		],
		F: [
			{
				key: 2,
				position: PlayerPosition.OutsideBack,
				positionCategory: this.i18n.t(
					positionCategories.fullBack.key,
					positionCategories.fullBack.value
				),
			},
		],
	};

	get playersCategoryPosition(): IPlayersCategoryPosition {
		return this._playersCategoryPosition;
	}

	get statsOptions() {
		if (this._roundsStore.isPreseason) {
			return this._teamBuilderStore.statsOptionsPreseason;
		}
		return this._teamBuilderStore.statsOptions;
	}

	get selectedStatOption() {
		return this._teamBuilderStore.selectedStatOption;
	}

	get isSelectedBoosterForChoice() {
		return Boolean(this._teamBuilderStore.boosterForChoice);
	}

	get isTeamFull() {
		return this._teamStore.lineupPlayers.length === this._teamStore.lineupIDs.length;
	}

	get checkIsHaveBoosterSelected() {
		const activeRound = this._roundsStore.currentRound;
		const types = [
			BoosterTypes.TRIPLE_CAPTAIN,
			BoosterTypes.DEFENSIVE_KING,
			BoosterTypes.SUPER_KICKER,
		];
		return Boolean(
			types.find((type) =>
				Boolean(
					this._teamStore.team.boosters.find(
						(booster) => booster.type === type && activeRound?.id === booster.roundId
					)
				)
			)?.length
		);
	}

	get isShowBoosterButton() {
		if (this.isBoosterButtonDisabled) {
			return false;
		}
		return (
			!this.isSelectedBoosterForChoice && this.isTeamFull && !this._teamStore.isTeamChanged
		);
	}

	get isBoosterButtonDisabled() {
		return this._transfersStore.isTransferMode;
	}

	get selectedRound() {
		return (
			this._roundsStore.getRoundById(this._teamBuilderStore.selectedRoundId) ||
			this._roundsStore.currentRound
		);
	}

	get hasMatchStarted() {
		return this.selectedRound?.tournaments.find(
			({status}) => status !== TournamentStatus.Scheduled
		);
	}

	get isShowHistoryTeam() {
		return this._teamStore.isShowHistoryTeam;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action
	updateStatToShow = (e: SelectChangeEvent) => {
		const {value} = e.target;
		this._teamBuilderStore.selectedStatOption = value;
	};

	openBoostersModal = () => {
		this._modalsStore.showModal(ModalType.BOOSTERS, null);
	};

	init(): void {
		this._selectedStatDisposer = autorun(() => {
			if (!this._teamStore.isTeamScoring) {
				this._teamBuilderStore.selectedStatOption = UserStats.Price;
				return;
			}
			if (!this.hasMatchStarted) {
				this._teamBuilderStore.selectedStatOption = UserStats.NextFixture;
				return;
			}
			this._teamBuilderStore.selectedStatOption = UserStats.RoundPoints;
		});
	}

	dispose() {
		this._selectedStatDisposer?.();
	}
}
