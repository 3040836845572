import React from "react";
import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Locale} from "data/enums";
import type {IHttpClientService} from "data/services/http";

export interface ILanguageSelectorController extends ViewController {
	get i18n(): ILocalizationStore;
	get anchorEl(): null | HTMLElement;
	get isMenuOpen(): boolean;

	handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
	handleCloseMenu: () => void;
	handleChangeLanguage: (event: React.SyntheticEvent<HTMLLIElement>) => void;
}

@injectable()
export class LanguageSelectorController implements ILanguageSelectorController {
	@observable _anchorEl: null | HTMLElement = null;

	get anchorEl() {
		return this._anchorEl;
	}

	get isMenuOpen() {
		return Boolean(this._anchorEl);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ApiHTTPClient) private _apiHTTPClient: IHttpClientService
	) {
		makeAutoObservable(this);
	}

	handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		this._anchorEl = event.currentTarget;
	};

	handleCloseMenu = () => {
		this._anchorEl = null;
	};

	@action init() {
		return;
	}

	@action
	handleChangeLanguage = async (event: React.SyntheticEvent<HTMLLIElement>) => {
		const locale = event.currentTarget.dataset.locale as Locale;

		await this.i18n
			.switchLocale({
				locale,
			})
			.then(() => this._apiHTTPClient.setLocale(this.i18n.lang));

		this._anchorEl = null;
	};
}
