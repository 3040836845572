import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ISquadsStore} from "data/stores/squads/squds.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {TUTORIAL_KEY} from "data/constants";
import type {ITransfersStore} from "data/stores/transfers/transfers.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {IStatsPlayersStore} from "data/stores/stats_players/stats_players.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {IGameBarStore} from "data/stores/game_bar/game_bar.store";
import Cookies from "js-cookie";

interface IProps {
	isMobile: boolean;
}

export interface ITeamController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get isPlayerPoolVisible(): boolean;
	get isOpenBoosters(): boolean;
	get isOpenTrades(): boolean;
	get isBlocking(): boolean;

	openPlayerPool: () => void;
	closePlayerPool: () => void;
	handleConfirmLeavePage: () => void;
}

@injectable()
export class TeamController implements ITeamController {
	@observable private _isMobile = false;
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.StatsPlayersStore) private _statsPlayersStore: IStatsPlayersStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore,
		@inject(Bindings.GameBarStore) private readonly _gameBarStore: IGameBarStore
	) {
		makeAutoObservable(this);
	}

	get isPlayerPoolVisible() {
		return this._teamBuilderStore.isPlayerPoolVisible;
	}

	get isOpenBoosters() {
		return Boolean(this._teamBuilderStore.boosterForChoice);
	}

	get isOpenTrades() {
		return this._transfersStore.isTransferMode;
	}

	get isBlocking() {
		return this._transfersStore.isTransferMode
			? this._transfersStore.isTeamChanged
			: this._teamStore.isBlockingNav;
	}

	init(params: IProps) {
		this._isMobile = params.isMobile;

		void Promise.all([
			this._squadsStore.fetchSquads(),
			this._playersStore.fetchPlayers(),
			this._statsPlayersStore.fetchPlayersStats(),
			this._roundsStore.fetchRounds(),
			this._teamStore.fetchTeam(),
			this._gameBarStore.fetchGameBar(),
		])
			.catch(this.onError)
			.finally(() => {
				if (
					!Cookies.get("pools_complete_round8_showed") &&
					this._roundsStore.completedRounds.find((round) => round.number === 8)
				) {
					this._modalsStore.showModal(ModalType.POOLS_COMPLETE);
					Cookies.set("pools_complete_round8_showed", "true");
				} else if (
					localStorage.getItem(TUTORIAL_KEY) !== "true" &&
					this._modalsStore.modal !== ModalType.POOLS_COMPLETE
				) {
					this._modalsStore.showModal(ModalType.TUTORIAL);
				}
			});
	}

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	@action
	public openPlayerPool = () => {
		this._teamBuilderStore.openPlayerPool();
	};

	private clearBoostersData = () => {
		runInAction(() => {
			this._teamBuilderStore.selectedPlayerForBoost = null;
			this._teamBuilderStore.boosterForChoice = null;
		});
	};

	@action private onClearTeam = () => {
		this._teamStore.clearTeam();
		this.clearBoostersData();
	};

	@action private transfersCancel = () => {
		this._transfersStore.setTransferMode(false);
		this._transfersStore.resetLocalTransfers();
	};

	@action
	public handleConfirmLeavePage = () => {
		if (this._transfersStore.isTransferMode) {
			this.transfersCancel();
		} else {
			this.onClearTeam();
		}
	};

	@action
	public closePlayerPool = () => {
		this._teamBuilderStore.closePlayerPool();
	};
}
