import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type ITransfersStore} from "data/stores/transfers/transfers.store";
import {chain, identity} from "lodash";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IRemovePlayersController extends ViewController {
	get i18n(): ILocalizationStore;
	get isTransferMode(): boolean;
	get isDisabled(): boolean;
	get isRoundScoring(): boolean;

	removePlayers: () => void;
}

@injectable()
export class RemovePlayersController implements IRemovePlayersController {
	get isTransferMode() {
		return this._transfersStore.isTransferMode;
	}

	get isDisabled() {
		return chain(this._transfersStore.lineupPlayersIDs).filter(identity).isEmpty().value();
	}

	get isRoundScoring() {
		return this._roundsStore.isCurrentRoundScoring;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	removePlayers = () => {
		this._transfersStore.removeAllPlayers();
	};
}
