import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {IPoolPlayer} from "data/stores/players/players.store";
import type {ITransfersStore} from "data/stores/transfers/transfers.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";

interface IModalData {
	player: IPoolPlayer;
}

export interface IModalTransferBoosterController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	close: () => void;
	confirm: () => void;
}

@injectable()
export class ModalTransferBoosterController implements IModalTransferBoosterController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TransfersStore) private readonly _transfersStore: ITransfersStore,
		@inject(Bindings.TeamBuilderStore) private readonly _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	protected get modalData(): IModalData | null {
		return this._modalsStore.modalContent as IModalData;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TRANSFER_BOOSTER;
	}

	public close = () => {
		this._modalsStore.hideModal();
	};

	public confirm = () => {
		if (!this.modalData) {
			return;
		}

		if (!this._transfersStore.isTransferMode) {
			this._transfersStore.setTransferMode(true);
		}

		const {
			player: {id, position},
		} = this.modalData;
		this._transfersStore.transferOutPlayer(id);
		this._teamBuilderStore.setPositionWithoutOpenPlayerPool(position[0]);
		this._modalsStore.hideModal();
	};
}
