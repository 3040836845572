import React from "react";
import styled from "@emotion/styled";
import {Container} from "views/components/common";
import logo from "assets/img/logo.svg";
import {DesktopMenu} from "views/components/menu_items/desktop_menu.component";
import {useIsMobile} from "data/hooks";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as ArrowForward} from "assets/img/icons/arrowForwardBold.svg";
import {MobileMenu} from "views/components/menu_items/mobile_menu.component";
import {useKeycloak} from "@react-keycloak/web";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {LanguageSelector} from "views/components/language_selector/language_selector.component";
import {ReactComponent as HomeIcon} from "assets/img/icons/home.svg";
import {Link, NavLink} from "react-router-dom";
import {IHeaderController} from "views/components/header/header.controller";
import {css} from "@mui/material";
import {observer} from "mobx-react";
import {SubNavigationMenu} from "views/components/sub_navigation_menu/sub_navigation_menu";

const Wrapper = styled.div<{isPlayerPoolVisible: boolean}>`
	background: #ffffff;
	font-family: "Webb Ellis Cup", sans-serif;

	@media screen and (max-width: 640px) {
		position: sticky;
		top: 0;
		z-index: ${({isPlayerPoolVisible}) => (isPlayerPoolVisible ? 1 : 2)};
	}
`;

const UserPanel = styled.div`
	width: 100%;
	background: #fafafa;
	@media screen and (max-width: 900px) {
		display: none;
	}
`;

const ContainerStyled = styled(Container)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
`;

const RightSide = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	height: 100%;
`;

const MenuWrapper = styled(Container)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 88px;

	@media screen and (max-width: 900px) {
		justify-content: flex-end;
		height: 60px;
		flex-direction: inherit;
		gap: 17px;
		padding: 0 16px;
	}
`;

const linkStyles = css`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 900;
	text-transform: uppercase;

	svg {
		margin-left: 2px;
		margin-right: 5px;
	}

	@media screen and (max-width: 900px) {
		font-size: 10px;

		&.mobile-section {
			flex: 1;
			justify-content: flex-start;
		}
	}
`;

const InternalLink = styled(Link)`
	${linkStyles}
`;

const ExternalLink = styled.a`
	${linkStyles}
`;

export const Header: React.FC = observer(() => {
	const {i18n, isAuthorized, isPlayerPoolVisible} = useViewController<IHeaderController>(
		Bindings.HeaderController
	);
	const {isMobile} = useIsMobile();
	const {keycloak} = useKeycloak();

	if (!isAuthorized) {
		return null;
	}

	const homeLink = "https://www.rugbyworldcup.com/";

	return (
		<Wrapper isPlayerPoolVisible={isPlayerPoolVisible}>
			<UserPanel>
				<ContainerStyled>
					<ExternalLink href={homeLink} target={"_blank"}>
						<ArrowForward />
						rugbyworldcup.com
					</ExternalLink>
					<RightSide>
						<LanguageSelector />
						<Exist when={isAuthorized}>
							<InternalLink to="/account">
								{i18n.t("account.page.header", "MY ACCOUNT")}
							</InternalLink>
						</Exist>
						<Exist when={!isAuthorized}>
							<ExternalLink href={keycloak.createLoginUrl()}>LOGIN</ExternalLink>
						</Exist>
					</RightSide>
				</ContainerStyled>
			</UserPanel>
			<MenuWrapper>
				<Exist when={isMobile}>
					<InternalLink to="/" className="mobile-section">
						<HomeIcon />
					</InternalLink>
				</Exist>

				<Exist when={!isMobile}>
					<Link to="/">
						<img src={logo} alt={"World Cup France 2023"} />
					</Link>
				</Exist>

				<Exist when={isMobile}>
					<SubNavigationMenu>
						<li>
							<NavLink to="/my-team">
								{i18n.t("navigation.my_team", "My Team")}
							</NavLink>
						</li>
						<Exist when={isAuthorized}>
							<li>
								<NavLink to="/leagues">
									{i18n.t("navigation.my_leagues", "My Leagues")}
								</NavLink>
							</li>
						</Exist>
					</SubNavigationMenu>
				</Exist>

				<MobileMenu />
				<Exist when={!isMobile}>
					<DesktopMenu />
				</Exist>
			</MenuWrapper>
		</Wrapper>
	);
});
