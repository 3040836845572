import styled from "@emotion/styled";

export const Container = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 0 20px;
	margin: 0 auto;
	gap: 20px;
	position: relative;
	z-index: 1;
	@media screen and (max-width: 900px) {
		flex-direction: column;
	}
`;
