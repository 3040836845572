import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {SuccessModalContent} from "data/types/modals";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface IModalSuccessController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;

	get modalContent(): SuccessModalContent;

	close: () => void;
}

@injectable()
export class ModalSuccessController implements IModalSuccessController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.SUCCESS;
	}

	get modalContent(): SuccessModalContent {
		return this._modalsStore.modalContent as SuccessModalContent;
	}

	public close = () => {
		this._modalsStore.hideModal();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
