import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, PlayerPosition} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import Keycloak from "keycloak-js";
import type {
	IPlayer,
	IPlayersStore,
	IPoolPlayer,
	IPlayerOpponentData,
} from "data/stores/players/players.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squds.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITransfersStore} from "data/stores/transfers/transfers.store";
import {every, get} from "lodash";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {
	IStatsPlayersStore,
	IPlayerStat,
	IPlayerTournamentStats,
} from "data/stores/stats_players/stats_players.store";

interface IModalData {
	player: IPoolPlayer;
}

export interface IModalPlayerController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	get player(): IPoolPlayer | undefined;
	get playerStats(): IPlayerStat | undefined;
	get squad(): ISquad | undefined;
	get opponents(): IPlayerOpponentData[];
	get completedAndPlayingFixtures(): IPlayerOpponentData[];
	get isCapitan(): boolean;
	get isTeamComplete(): boolean;
	get tradeStarted(): boolean;
	get selected(): number;
	get showCaptainButton(): boolean;
	get nextFixture(): ISquad | undefined;

	close: () => void;
	addPlayerToPool: (player: IPoolPlayer) => void;
	removePlayerFromPool: (player: IPlayer) => void;
	changeCaptain: (player: IPlayer) => void;
	selectUserForTransfer: (playerId: number, position: PlayerPosition) => void;
	checkIsPlayerBoosted: (playerId: number | undefined) => boolean;
	checkIsPlayerBlocked: (player: IPlayer | undefined) => boolean;
	fetchPlayerTournamentStats: () => void;
	getTournamentStats: (tournamentId: number) => IPlayerTournamentStats | undefined;
}

@injectable()
export class ModalPlayerController implements IModalPlayerController {
	@observable _keycloak: Keycloak | null = null;
	_isCapitan: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.StatsPlayersStore) private _statsPlayersStore: IStatsPlayersStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.PLAYER_PROFILE;
	}

	get player(): IPoolPlayer | undefined {
		if (!this.modalData?.player) {
			return;
		}
		return this.modalData?.player;
	}

	get playerStats(): IPlayerStat | undefined {
		return this._statsPlayersStore.getPlayerStatsByPlayerId(this.player?.id);
	}

	get squad(): ISquad | undefined {
		return this._squadsStore.getSquadById(this.player?.squadId);
	}

	get opponents() {
		return (
			this._playersStore
				.getOpponents({
					tournaments: this._roundsStore.getAllScheduledTournaments,
					squadId: this.player?.squadId,
				})
				// Get only 3 last
				.filter((items, key) => key <= 2)
		);
	}

	get completedAndPlayingFixtures() {
		return this._playersStore.getOpponents({
			tournaments: this._roundsStore.getAllCompletedAndPlayingTournaments,
			squadId: this.player?.squadId,
		});
	}

	get isCapitan() {
		return this._isCapitan ? this._isCapitan : Boolean(this.player?.isCapitan);
	}

	get isTeamComplete() {
		return Boolean(this._teamStore.isComplete);
	}

	get tradeStarted() {
		return this._transfersStore.isTransferMode;
	}

	get selected() {
		if (!this.player || !this._roundsStore.currentRound) {
			return 0;
		}
		return get(this.player.selected, this._roundsStore.currentRound.id, 0);
	}

	get playerBooster() {
		const actualRoundId = this._roundsStore.currentRound?.id;
		return this._teamStore.team.boosters.find(
			(booster) => booster.roundId === actualRoundId && booster.playerId === this.player?.id
		);
	}

	get isCaptainLocked() {
		const captain = this._teamStore.team.captainId;
		const player = this._playersStore.getPlayerById(captain);
		if (!player) {
			return true;
		}
		return this._transfersStore.getIsPlayerDisabledInCurrentRound(player);
	}

	get showCaptainButton() {
		return every([
			this.player?.addedInTeam,
			!this.checkIsPlayerBoosted(this.player?.id),
			!this.checkIsPlayerBlocked(this.player),
			!this.isCaptainLocked,
		]);
	}

	get nextFixture() {
		const nextTournament = this._roundsStore.getAllScheduledTournaments.find(
			(tournament) => tournament.id === this.player?.stats?.nextFixture
		);
		if (nextTournament) {
			const playerSquad =
				nextTournament.homeSquadId !== this.player?.squadId
					? nextTournament.homeSquadId
					: nextTournament.awaySquadId;
			return this._squadsStore.getSquadById(playerSquad);
		}
		return undefined;
	}

	protected get modalData(): IModalData | null {
		return this._modalsStore.modalContent as IModalData;
	}

	dispose(): void {
		return;
	}

	public close = () => {
		this._modalsStore.hideModal();
		this._isCapitan = false;
	};

	public addPlayerToPool = (player: IPoolPlayer) => {
		this.close();
		this._teamStore.addPlayerInLineup(player);
	};

	public removePlayerFromPool = (player: IPlayer) => {
		this.close();
		this._teamStore.removePlayerFromPool(player);
	};

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	@action
	public changeCaptain = (player: IPlayer) => {
		if (this.isTeamComplete) {
			this._teamStore
				.updateCaptain(player.id)
				.then(() => {
					this._isCapitan = true;
				})
				.catch(this.onError);
		} else {
			this._teamStore.setCaptain(player.id);
			this._isCapitan = true;
		}
	};

	@action
	selectUserForTransfer = (playerId: number, position: PlayerPosition) => {
		if (this.playerBooster) {
			this._modalsStore.showModal(ModalType.TRANSFER_BOOSTER, {player: this.player});
			return;
		}
		if (!this._transfersStore.isTransferMode) {
			this._transfersStore.setTransferMode(true);
		}
		this._transfersStore.transferOutPlayer(playerId);
		this._teamBuilderStore.setPositionWithoutOpenPlayerPool(position);
		this.close();
	};

	checkIsPlayerBoosted = (playerId: number | undefined) => {
		const actualRoundId = this._roundsStore.currentRound?.id;
		return Boolean(
			this._teamStore.team.boosters.find(
				(booster) => booster.roundId === actualRoundId && booster.playerId === playerId
			)
		);
	};

	checkIsPlayerBlocked = (player: IPlayer | undefined) => {
		return player ? this._transfersStore.getIsPlayerDisabledInCurrentRound(player) : false;
	};

	fetchPlayerTournamentStats = () => {
		if (this.player) {
			this._statsPlayersStore.fetchPlayerTournamentStats(this.player.id).catch(this.onError);
		}
	};

	getTournamentStats = (tournamentId: number) => {
		return this._statsPlayersStore.getPlayerTournamentStatsByTournamentId(tournamentId);
	};
}
