import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IRound, IRoundsStore, ITournament} from "data/stores/rounds/rounds.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {RequestState} from "data/enums";
import {isEqual} from "lodash";

export interface IMatchesController extends ViewController {
	readonly i18n: ILocalizationStore;
	get expanded(): number | null;
	get rounds(): IRound[];
	get isLoading(): boolean;
	getMatches: (id: number) => ITournament[];
	handleChange: (id: number) => void;
}

@injectable()
export class MatchesController implements IMatchesController {
	@observable _requestState = RequestState.PENDING;
	private _expanded: number | null = null;

	get expanded() {
		return this._expanded;
	}

	get rounds() {
		return this._roundsStore.list;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action getMatches = (id: number) => {
		return this._roundsStore.getRoundById(id)?.tournaments ?? [];
	};

	@action handleChange = (id: number) => {
		if (id === this.expanded) {
			this._expanded = null;
			return;
		}
		this._expanded = id;
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
		this._expanded = this._roundsStore.currentRound?.id ?? null;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
	};

	init() {
		this._roundsStore.fetchRounds().then(this.onSuccess).catch(this.onError);
	}
}
