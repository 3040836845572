import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {ITransfersStore} from "data/stores/transfers/transfers.store";
import {IAxiosApiErrorGeneral} from "data/types/modals";
import {ModalType} from "data/enums";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import Cookies from "js-cookie";

interface IProps {
	isMobile: boolean;
}

export interface ITradesControlPanelController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get budget(): number;
	get isTransferBudgetNegative(): boolean;
	get isTeamComplete(): boolean;
	get isTradesReadyToSend(): boolean;
	get description(): string;
	get tradeStarted(): boolean;
	get isOpenBoosters(): boolean;
	get isHideMessageOnMobile(): boolean;
	get showPointsMessage(): boolean;
	get showTransfersMessage(): boolean;

	startTransfers: () => void;
	transfersCancel: () => void;
	transfersConfirm: () => void;
	closePointsMessage: () => void;
}

@injectable()
export class TradesControlPanelController implements ITradesControlPanelController {
	@observable private _isMobile: boolean = false;
	@observable private _showPointsMessage: boolean = true;

	get budget() {
		return this._transfersStore.isTransferMode
			? this._transfersStore.budget
			: this._teamStore.budget;
	}

	get isTransferBudgetNegative() {
		return this._transfersStore.isTransferMode && this._transfersStore.isBudgetNegative;
	}

	get isTeamComplete() {
		return Boolean(this._teamStore.isComplete);
	}

	get isTradesReadyToSend() {
		const isTransferDontFinished = Boolean(
			this._transfersStore.localTransfers.find((trade) => !trade.in)
		);
		return (
			!isTransferDontFinished &&
			this._transfersStore.localTransfers.length > 0 &&
			!this.isTransferBudgetNegative
		);
	}

	get description() {
		if (this._transfersStore.localTransfers.length === 0) {
			return this.i18n.t("transfers.copy.remove player");
		}
		if (this._transfersStore.localTransfers.length > 0 && this.isTradesReadyToSend) {
			return this.i18n.t("transfers.copy.valid");
		}
		return this.i18n.t(
			"transfers.copy.remove_reset",
			"Select a player to transfer out or reset your transfers"
		);
	}

	get tradeStarted() {
		return this._transfersStore.isTransferMode;
	}

	get isOpenBoosters() {
		return Boolean(this._teamBuilderStore.boosterForChoice);
	}

	get isHideMessageOnMobile() {
		return this._teamBuilderStore.isPlayerPoolVisible && this._isMobile;
	}

	get showPointsMessage() {
		const hasSeenMessage = Cookies.get("points_message") === "true";
		return !hasSeenMessage && this._showPointsMessage && this._roundsStore.isMatchLive;
	}

	get showTransfersMessage() {
		if (this.isTransferBudgetNegative) {
			return false;
		}
		return !this._teamBuilderStore.isPlayerPoolVisible || !this._isMobile;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.TransfersStore) private _transfersStore: ITransfersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	@action init(param: IProps) {
		this._isMobile = param.isMobile;
	}

	@action startTransfers = () => {
		this._transfersStore.setTransferMode(true);
	};

	@action transfersCancel = () => {
		if (this._isMobile) {
			this._teamBuilderStore.closePlayerPool();
		}
		this._transfersStore.setTransferMode(false);
		this._transfersStore.resetLocalTransfers();
	};

	@action transfersConfirm = () => {
		this._transfersStore.makeTransfers().catch(this.onError);
	};

	@action closePointsMessage = () => {
		if (this._roundsStore.nextScheduledTournament) {
			const expires = new Date(this._roundsStore.nextScheduledTournament.date);
			Cookies.set("points_message", "true", {expires});
		}
		this._showPointsMessage = false;
	};
}
