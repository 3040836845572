import React from "react";
import {observer} from "mobx-react";
import {Modal, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeIcon.svg";
import {CloseButton, ModalCloseBlock, ModalContent} from "views/components/modals/common";
import {IModalSuccessController} from "views/components/modals/modal_success/modal_success.controller";

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #208662;
	}
`;

export const ModalSuccess: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalSuccessController>(
		Bindings.ModalSuccessController
	);
	return (
		<Modal open={isOpen}>
			<ModalContent>
				<ModalCloseBlock>
					<CloseButton onClick={close}>
						<CloseIcon />
					</CloseButton>
				</ModalCloseBlock>
				<Icon>
					<CheckCircleIcon />
				</Icon>

				<Typography>{i18n.t(modalContent?.title ?? "modal.success.title", "-")}</Typography>
				<Typography>
					{i18n.t(modalContent?.message ?? "modal.success.text", "-")}
				</Typography>
			</ModalContent>
		</Modal>
	);
});
